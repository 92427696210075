import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  createStyles,
  makeStyles,
  Theme,
  Drawer as MUIDrawer,
  ListItem,
  List,
  ListItemText,
  Collapse,
  Hidden,
  Link,
  Divider,
  Box,
  Typography
} from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { observer } from 'mobx-react-lite'

import { useStore } from '../../stores/StoreContext'
import { getNavItems } from '../../utils/getNavItems'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

interface INavItemsOpenState {
  [key: string]: boolean
}

interface INavItem {
  id: string
  title: string
  clickHandler?: () => unknown
}

/** observer-Component */
const Drawer: React.FC = () => {
  const { uiStore } = useStore()
  const { drawerWidth, drawerOpen, toggleDrawerOpen, drawerOpenBreakpoint = 'md' } = uiStore
  const classes = useStyles({ drawerWidth, drawerOpenBreakpoint })

  const hiddenPropsDesktop = {
    xsUp: drawerOpenBreakpoint === 'xs',
    smUp: drawerOpenBreakpoint === 'sm',
    mdUp: drawerOpenBreakpoint === 'md',
    lgUp: drawerOpenBreakpoint === 'lg'
  }

  const hiddenPropsMobile = {
    xsDown: drawerOpenBreakpoint === 'sm',
    smDown: drawerOpenBreakpoint === 'md',
    mdDown: drawerOpenBreakpoint === 'lg',
    lgDown: drawerOpenBreakpoint === 'xl'
  }

  return (
    <nav className={classes.drawer}>
      <Hidden {...hiddenPropsDesktop}>
        <MUIDrawer
          variant='temporary'
          anchor='left'
          open={drawerOpen}
          onClose={toggleDrawerOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}>
          <DrawerContents />
        </MUIDrawer>
      </Hidden>
      <Hidden {...hiddenPropsMobile}>
        <MUIDrawer
          variant='permanent'
          anchor='left'
          open
          classes={{
            paper: classes.drawerPaper
          }}>
          <DrawerContents />
        </MUIDrawer>
      </Hidden>
    </nav>
  )
}

export default observer(Drawer)

const DrawerContents: React.FC = observer(() => {
  const { uiStore, userStore, cmsStore } = useStore()
  const { drawerWidth, drawerOpenBreakpoint } = uiStore
  const classes = useStyles({ drawerWidth, drawerOpenBreakpoint })
  const [navItems, setNavItems] = useState(getNavItems(userStore.user))

  const initialOpenState: INavItemsOpenState = {}
  navItems.forEach((navItem: INavItem) => (initialOpenState[navItem.id] = true))
  const [itemsOpen, setItemsOpen] = React.useState<INavItemsOpenState>(initialOpenState)

  /* CMS nav items are read from configuration which needs to be loaded first. */
  useEffect(() => {
    const init = async () => {
      await cmsStore.receiveConfiguration()
      setNavItems(getNavItems(userStore.user))
    }
    init()
  }, [])

  function toggleItemOpen(id: string) {
    const newState = itemsOpen[id] ? !itemsOpen[id] : true
    setItemsOpen({ ...itemsOpen, [id]: newState })
  }

  return (
    <>
      <Box className={classes.drawerHeader}>
        <Link
          component={RouterLink}
          to={'/'}
          className={classes.homeButtonRoot}
          onClick={uiStore.toggleDrawerOpen}>
          {/* <IconButton aria-label="Home" className={classes.homeButton}> */}
          <HomeIcon fontSize='large' />
          <Typography variant='body1' className={classes.drawerHeaderTitle}>
            Home
          </Typography>
          {/* </IconButton> */}
        </Link>
      </Box>
      <Divider />
      <List>
        {navItems.map((itemMain) => {
          const { id: idMain, title: titleMain } = itemMain
          const hasChildren = !!itemMain.items?.length

          function toggleMainOpen() {
            toggleItemOpen(idMain)
          }

          return (
            <React.Fragment key={idMain}>
              <ListItem button onClick={toggleMainOpen}>
                <ListItemText primary={titleMain} />
                {itemsOpen[idMain] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {hasChildren && (
                <Collapse in={itemsOpen[idMain]}>
                  <List component='div' disablePadding>
                    {itemMain.items &&
                      itemMain.items.map((itemSec: INavItem) => {
                        const { id, title, clickHandler } = itemSec

                        return (
                          <ListItem key={id} className={classes.nested}>
                            {!clickHandler && (
                              <Link
                                component={RouterLink}
                                variant='body1'
                                to={`/${id}`}
                                onClick={uiStore.toggleDrawerOpen}>
                                <ListItemText secondary={title} />
                              </Link>
                            )}
                            {clickHandler && (
                              <ListItemText
                                className={classes.drawerListItem}
                                secondary={title}
                                onClick={() => {
                                  uiStore.toggleDrawerOpen()
                                  clickHandler()
                                }}
                              />
                            )}
                          </ListItem>
                        )
                      })}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          )
        })}
      </List>
    </>
  )
})

interface IStyleProps {
  drawerWidth: number | undefined
  drawerOpenBreakpoint: Breakpoint
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: (props) => ({
      [theme.breakpoints.up(props.drawerOpenBreakpoint)]: {
        width: (props: IStyleProps) => props.drawerWidth,
        flexShrink: 0
      }
    }),
    drawerListItem: {
      cursor: 'pointer'
    },
    drawerPaper: {
      width: (props: IStyleProps) => props.drawerWidth
    },
    drawerHeader: {
      ...theme.mixins.toolbar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 1)
    },
    drawerHeaderTitle: {
      marginLeft: theme.spacing(1)
    },
    homeButtonRoot: {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      flex: '0 0 auto',
      fontSize: theme.typography.pxToRem(24),
      '&:hover': { color: theme.palette.secondary.light }
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  })
)

DrawerContents.displayName = 'DrawerContents'
