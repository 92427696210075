import React from 'react'
import { observer } from 'mobx-react-lite'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import { IRadioGroupElement } from '../../models/settingsInterfaces'
import SimpleInputBase from './SimpleInputBase'

interface IRadioGroupElementProps {
  element: IRadioGroupElement
  fieldKey: string
  baseId?: string
  values?: string
  updateData?: (key: string, data: any) => void
}

/** observer-Component */
/**
 * @param element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used for building the ids of text fields.
 * @param baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param values - The data that can be edited in this input
 * @param updateData - 'OnChange' callback for updating data. Will be passed
 * the fieldKey and the value of the input
 */
const RadioGroupElement: React.FC<IRadioGroupElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: IRadioGroupElementProps) => {
  const { options } = element
  const selected = values || element.defaultValue || 'Deutschland'

  if (!baseId) {
    baseId = fieldKey
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (updateData) {
      updateData(fieldKey, event.target.value)
    }
  }

  return (
    <>
      <SimpleInputBase
        fullWidth={element.fullWidth || false}
        firstRowTitle={element.title}
        firstRowId={`input_${baseId}`}
        toolTip={element.toolTip}
        firstRowChildren={
          <FormControl>
            <RadioGroup
              defaultValue={element.defaultValue}
              aria-describedby={`description_${baseId}`}
              name='optionSelect'
              id={`${fieldKey}-optionSelect`}
              value={selected}
              onChange={handleChange}>
              {options.map((option) => (
                <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
              ))}
            </RadioGroup>

            {element.description && (
              <FormHelperText id={`description_${baseId}`}>{element.description}</FormHelperText>
            )}
          </FormControl>
        }
      />
    </>
  )
}

export default observer(RadioGroupElement)
