import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'

import { useStore } from '../../stores/StoreContext'

/** observer-Component */
const Toast: React.FC = () => {
  const { uiStore } = useStore()
  const duration = 3000

  function handleClose() {
    uiStore.setSnackbarSettings({ snackbarOpen: false })
  }

  return (
    <Snackbar
      open={uiStore.snackbarSettings.snackbarOpen}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert
        variant='filled'
        color={uiStore.snackbarSettings.snackbarType || 'info'}
        action={
          <IconButton aria-label='close' color='inherit' size='small' onClick={handleClose}>
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }>
        {uiStore.snackbarSettings.snackbarMsg}
      </Alert>
    </Snackbar>
  )
}

export default observer(Toast)
