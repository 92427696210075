import React from 'react'
import { createStyles, makeStyles, Box, Container, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { usePageStyles } from '../../styles/usePageStyles'

const ErrorFallbackApp: React.FC = () => {
  const classesPage = usePageStyles()
  const classes = useStyles()

  return (
    <Box className={classesPage.appContainer}>
      <Container component='main' maxWidth='md' className={clsx(classesPage.main, classes.mt_40vh)}>
        <Typography gutterBottom variant='h3' component='h1'>
          Etwas ist schief gelaufen...
        </Typography>
        <Typography gutterBottom variant='body1' component='p'>
          Bitte versuchen Sie, die Seite neu zu laden.
        </Typography>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles(
  createStyles({
    mt_40vh: {
      marginTop: '40vh'
    }
  })
)

export default ErrorFallbackApp
