import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  MenuItem,
  Typography,
  CircularProgress
} from '@material-ui/core'

import SelectBtn from '../inputElements/SelectBtn'
import TextElement from '../inputElements/TextElement'
import AddPagePopup from './AddPagePopup'
import {
  IAtomTemplate,
  IModulePage,
  IPage,
  ISubModulePage,
  ISubModuleTemplate
} from '../../models/cmsInterfaces'
import FileElementFileManager from '../inputElements/FileElementFileManager'
import { FileLibraryListItem } from '../../mediaLibrary/types'
import { IFeedback, IPagesListByTemplateId } from '../../stores/CMSStore'
import CMSSubModuleAccordionPanel from './CMSSubModuleAccordionPanel'
import RichtextElement from '../inputElements/RichtextElement'

interface ICMSSubModuleProps {
  pageModule: IModulePage
  pageSubModule: ISubModulePage
  templateSubModule: ISubModuleTemplate | undefined
  pageSubModuleIndex: number
  pagesListByTemplateId: IPagesListByTemplateId
  getPagesByTemplateId: (id: string) => Promise<void>
  onDeleteSubModule: (
    templateSubModule: ISubModuleTemplate,
    pageSubModule: ISubModulePage,
    pageModule: IModulePage
  ) => void
  onChangeSubModulePosUpOrDown: (
    pageModule: IModulePage,
    pageSubModuleIndex: number,
    direction: 'up' | 'down'
  ) => IFeedback
  displayError: (error: string) => void
  subModuleHasChanges?: (pageModule: IModulePage, pageSubModule: ISubModulePage) => boolean

  // Props passed down to CMSSubModuleAccordionPanel component
  expanded: boolean
  panelDisabled?: boolean
  deleteAllowed: boolean
  moveAllowed: boolean
  onSubPanelClick: () => void

  // Props passed down to Atoms via getElements()
  checkIfFileExists: (filename: string) => boolean
  createPage: (templateId: string, pageName: string) => Promise<IPage>
  onFileSelectRequest: (
    onSelect: (item: FileLibraryListItem) => void,
    acceptedTypes: string
  ) => void
  onFileSelect: (atom: IAtomTemplate, value: string, pageSubModule: ISubModulePage) => void
  onSelectFollowUp: (atom: IAtomTemplate, page: IPage, pageSubModule: ISubModulePage) => void
  onTextInput: (atom: IAtomTemplate, value: string, pageSubModule: ISubModulePage) => void
}

/** observer-Component */
const CMSSubModule: React.FC<ICMSSubModuleProps> = ({
  pageModule,
  pageSubModule,
  templateSubModule,
  pageSubModuleIndex,
  pagesListByTemplateId,
  getPagesByTemplateId,
  onDeleteSubModule,
  onChangeSubModulePosUpOrDown,
  displayError,
  subModuleHasChanges,

  expanded,
  panelDisabled = false,
  deleteAllowed,
  moveAllowed,
  onSubPanelClick,

  checkIfFileExists,
  createPage,
  onFileSelectRequest,
  onFileSelect,
  onSelectFollowUp,
  onTextInput
}: ICMSSubModuleProps) => {
  const classes = useStyles()
  const hasChanges = subModuleHasChanges ? subModuleHasChanges(pageModule, pageSubModule) : false

  let subModuleName = 'Submodul'
  if (templateSubModule) {
    subModuleName = (templateSubModule as ISubModuleTemplate).conf.meta.cms.name || 'Submodul'
  }

  useEffect(() => {
    const loadPages = async () => {
      // If this submodule contains a follow-up content selector, load the contents (if they aren't loaded yet)
      if (templateSubModule) {
        templateSubModule.conf.atoms.forEach(async (atom: IAtomTemplate) => {
          if (
            atom.global.type === 'linkIntern' &&
            atom.cms.typeConf?.allPagesFromOneTemplateId &&
            !pagesListByTemplateId?.[atom.cms.typeConf.allPagesFromOneTemplateId]
          ) {
            await getPagesByTemplateId(atom.cms.typeConf.allPagesFromOneTemplateId)
          }
        })
      }
    }

    loadPages()
  }, [])

  function getAtomContent(atom: IAtomTemplate): any {
    let value = ''
    if (pageSubModule.atoms) {
      for (let i = 0; i < pageSubModule.atoms.length; i++) {
        if (pageSubModule.atoms[i].global.id === atom.global.id) {
          value = pageSubModule.atoms[i].val
        }
      }
    }

    return value
  }

  function handleSubModulePositionChangeUpOrDown(
    pageSubModuleIndex: number,
    direction: 'up' | 'down'
  ) {
    const feedback: IFeedback = onChangeSubModulePosUpOrDown(
      pageModule,
      pageSubModuleIndex,
      direction
    )

    if (!feedback.success) {
      displayError(feedback.error)
    }
  }

  function handleSubModuleDelete() {
    onDeleteSubModule(templateSubModule as ISubModuleTemplate, pageSubModule, pageModule)
  }

  /** Create Input Element depending on element type */
  function getElement(atom: IAtomTemplate, key: string) {
    // Update functions that get passed down to Submodules
    function updateDataFile(_key: string, data: any) {
      onFileSelect(atom, data, pageSubModule)
    }
    function updateDataText(_key: string, data: any) {
      onTextInput(atom, data, pageSubModule)
    }

    switch (atom.global.type) {
      case 'text':
        return (
          <TextElement
            element={{
              type: 'text',
              title: atom.cms.label
            }}
            fieldKey={key}
            values={{
              question: getAtomContent(atom)
            }}
            saveObj={false}
            updateData={updateDataText}
            InputProps={{
              inputProps: {
                maxLength: 2000
              }
            }}
          />
        )
      case 'textarea':
        return (
          <RichtextElement
            element={{
              type: 'text',
              title: atom.cms.label
            }}
            fieldKey={key}
            values={{
              question: getAtomContent(atom)
            }}
            updateData={updateDataText}
            saveObj={false}
          />
        )
      case 'image':
      case 'linkImage':
        return (
          <FileElementFileManager
            element={{
              type: 'upload',
              subtype: 'img',
              title: atom.cms.label
            }}
            fieldKey={key}
            values={getAtomContent(atom)}
            updateData={updateDataFile}
            onFileSelectRequest={onFileSelectRequest}
            checkIfFileExists={checkIfFileExists}
          />
        )
      case 'file':
        return (
          <FileElementFileManager
            element={{
              type: 'upload',
              subtype: 'all',
              title: atom.cms.label
            }}
            fieldKey={key}
            values={getAtomContent(atom)}
            updateData={updateDataFile}
            onFileSelectRequest={onFileSelectRequest}
            checkIfFileExists={checkIfFileExists}
          />
        )
      case 'videoupload':
        return (
          <FileElementFileManager
            element={{
              type: 'upload',
              subtype: 'video',
              title: atom.cms.label
            }}
            fieldKey={key}
            values={getAtomContent(atom)}
            updateData={updateDataFile}
            onFileSelectRequest={onFileSelectRequest}
            checkIfFileExists={checkIfFileExists}
          />
        )
      case 'linkIntern':
        if (
          atom.cms.typeConf?.allPagesFromOneTemplateId &&
          pagesListByTemplateId?.[atom.cms.typeConf.allPagesFromOneTemplateId]
        ) {
          if (pagesListByTemplateId[atom.cms.typeConf.allPagesFromOneTemplateId].loaded) {
            return (
              <SelectBtn
                element={{
                  title: atom.cms.label
                }}
                fieldKey={key}
                values={getAtomContent(atom).nameIntern || ''}>
                {/* Pass available follow-up pages as render props */}
                {({ handleClose }) => {
                  if (atom.cms.typeConf && pagesListByTemplateId) {
                    const menuItems = pagesListByTemplateId[
                      atom.cms.typeConf.allPagesFromOneTemplateId
                    ].list.map((page: IPage) => (
                      <MenuItem
                        key={page._id}
                        onClick={() => {
                          handleClose()
                          onSelectFollowUp(atom, page, pageSubModule)
                        }}>
                        {page.meta.nameIntern}
                      </MenuItem>
                    ))

                    // This last option allows to add a new page
                    menuItems.push(
                      <AddPagePopup
                        templateId={atom.cms.typeConf.allPagesFromOneTemplateId}
                        key='popuptrigger'
                        triggerCallback={handleClose}
                        onCreatePage={async (templateId: string, pageName: string) => {
                          const newPage: IPage = await createPage(templateId, pageName)
                          await getPagesByTemplateId(templateId)
                          onSelectFollowUp(atom, newPage, pageSubModule)
                        }}
                      />
                    )
                    return menuItems
                  } else {
                    return <MenuItem>Keine Inhalte vorhanden</MenuItem>
                  }
                }}
              </SelectBtn>
            )
          } else {
            return (
              <Box display='flex' alignItems='center' py={3}>
                <Typography className={classes.mr_2}>
                  <b>Folgeinhalte werden geladen</b>
                </Typography>
                <CircularProgress size={30} />
              </Box>
            )
          }
        } else {
          return <p>Keine Folgeseiten verfügbar</p>
        }
      default:
        return <p key={key}>{atom.cms.label}</p>
    }
  }

  return (
    <CMSSubModuleAccordionPanel
      id={pageSubModule.meta.global.uid || pageSubModuleIndex + ''}
      title={subModuleName}
      moveAllowed={moveAllowed}
      deleteAllowed={deleteAllowed}
      pageSubModuleIndex={pageSubModuleIndex}
      onSubModulePositionChangeUpOrDown={handleSubModulePositionChangeUpOrDown}
      onPanelClick={onSubPanelClick}
      onDeleteSubModule={handleSubModuleDelete}
      expanded={expanded}
      panelDisabled={panelDisabled}
      hasChanges={hasChanges}>
      <>
        {templateSubModule ? (
          <>
            {templateSubModule.conf.atoms.map((atom: IAtomTemplate, atomidx: number) => (
              <Box
                className={classes.cmsAtomContainer}
                key={`${pageSubModule.meta.global.uid || templateSubModule.conf.meta.global.id}_${
                  atom.global.id || atomidx
                }`}>
                {getElement(
                  atom,
                  `${pageSubModule.meta.global.uid || templateSubModule.conf.meta.global.id}_${
                    atom.global.id || atomidx
                  }`
                )}
              </Box>
            ))}
          </>
        ) : (
          <Box className={classes.cmsSubmoduleContainer}>
            <Typography>Fehler beim Laden des Submoduls</Typography>
          </Box>
        )}
      </>
    </CMSSubModuleAccordionPanel>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cmsAtomContainer: {
      '&:not(:last-child)': {
        paddingBottom: theme.spacing(4)
      }
    },
    cmsSubmoduleContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      '&:not(:last-child)': {
        borderBottom: `2px solid ${theme.palette.primary.dark}`
      },
      '&:only-child': {
        borderBottom: 'none'
      }
    },
    mr_2: {
      marginRight: theme.spacing(2)
    }
  })
)

export default observer(CMSSubModule)
