import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'

interface IHouseGraphicFallbackProps {}

const HouseGraphicFallback: React.FC<IHouseGraphicFallbackProps> = ({}) => {
  const classes = useStyles()

  return (
    <>
      <Typography variant='body1' className={classes.paragraph}>
        Das Platzieren von Inhalten in der Graphik wird im Moment nur von der Desktop-Version
        unterstützt.
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        Öffnen Sie diese Anwendung auf einem Desktop Rechner oder Laptop um die Graphik anzuzeigen.
      </Typography>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraph: {
      marginBottom: theme.spacing(2)
    }
  })
)

export default HouseGraphicFallback
