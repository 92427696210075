import React, { useState } from 'react'
import { createStyles, makeStyles, Theme, Fab, Menu, MenuItem } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import { TQuestionType } from '../../models/questionsInterfaces'
import { IInputElement } from '../../models/settingsInterfaces'
import { useUtilStyles } from '../../styles/useUtilStyles'

interface IAddQuestionFloatingBtnProps {
  questionELements: IInputElement[]
  onAddQuestion: (type: TQuestionType) => void
}

const AddQuestionFloatingBtn: React.FC<IAddQuestionFloatingBtnProps> = ({
  questionELements,
  onAddQuestion
}: IAddQuestionFloatingBtnProps) => {
  const classes = useStyles()
  const classesUtil = useUtilStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  function handleOpenClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleItemClick(type: TQuestionType) {
    handleClose()
    onAddQuestion(type)
  }

  return (
    <>
      <Fab
        color='primary'
        className={classes.addQuestionBtnFloating}
        onClick={handleOpenClick}
        aria-hidden>
        <AddIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        aria-hidden>
        {questionELements.map((element: IInputElement) => (
          <MenuItem
            key={`menuBtn_${element.id}`}
            onClick={() => handleItemClick(element.type as TQuestionType)}>
            <span className={classesUtil.btnStyleText}>
              {(element.type as string).toUpperCase()}
            </span>
            &nbsp; Frage hinzufügen
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addQuestionBtnFloating: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    }
  })
)

export default AddQuestionFloatingBtn
