import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { withStyles } from '@material-ui/core'

export const Accordion = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    // border: `1px solid ${theme.custom.defaultBorderColor}`,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary
    }
  },
  expanded: {}
}))(MuiAccordion)

export const AccordionSummary = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.divider}`,
    // border: `1px solid ${theme.custom.defaultBorderColor}`,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.common.white,
      opacity: 'inherit'
    }
  },
  content: {
    flexDirection: 'column',
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
}))(MuiAccordionSummary)

export const AccordionDetails = withStyles((theme) => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
    // borderBottom: `1px solid ${theme.custom.defaultBorderColor}`,
    // [theme.breakpoints.down('xs')]: {
    //   padding: theme.spacing(1),
    // },
  }
}))(MuiAccordionDetails)

// Dark Version
export const AccordionDark = withStyles((theme) => ({
  root: {
    border: `2px solid ${theme.palette.primary.dark}`,
    // borderBottomLeftRadius: 0,
    '&.Mui-disabled': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary
    }
  },
  expanded: {}
}))(Accordion)

export const AccordionSummaryDark = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderBottom: 'none',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.main,
      opacity: 'inherit'
    }
  }
}))(AccordionSummary)

export const AccordionDetailsDark = withStyles({
  root: {
    padding: 0,
    borderBottom: 'none',
    '&$rounded': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
      // '&:last-child': {
      //   borderBottomLeftRadius: 0,
      //   borderBottomRightRadius: 0,
      // },
    },
    rounded: {}
  }
})(AccordionDetails)

// Version for CMS Submodules
export const AccordionNested = withStyles((theme) => ({
  root: {
    margin: 0,
    border: 'none',
    '&:not(:last-child)': {
      borderBottom: `2px solid ${theme.palette.primary.dark}`
    },
    '&:last-child': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px'
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary
    },
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
}))(Accordion)

export const AccordionSummaryNested = withStyles((theme) => ({
  root: {
    minHeight: '60px',
    color: theme.palette.common.white,
    '&$expanded': {
      minHeight: '60px'
    }
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
}))(AccordionSummary)

export const AccordionDetailsNested = withStyles({
  root: {
    borderBottom: 'none'
  }
})(AccordionDetails)
