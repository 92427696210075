import React, { ReactElement } from 'react'
import { Slide, useScrollTrigger } from '@material-ui/core'

interface IHideOnScrollProps {
  children: ReactElement<any, any>
}

const HideOnScroll = ({ children }: IHideOnScrollProps) => {
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

export default HideOnScroll
