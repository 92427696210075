import React from 'react'
import { createStyles, makeStyles, Theme, Button, Paper, Typography, Box } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

interface ICMSContentCardProps {
  pageTitle: string
  pageId: string
  onDelete: (pageId: string) => void
}

const CMSContentCard: React.FC<ICMSContentCardProps> = ({
  pageTitle,
  pageId,
  onDelete
}: ICMSContentCardProps) => {
  const classes = useStyles()

  function handleDelete() {
    onDelete(pageId)
  }

  return (
    <Paper className={classes.cmsContentCard}>
      <Box className={classes.innerContainer}>
        <Box display='flex' pr={1} flexGrow={1} alignItems='center'>
          <Typography variant='h5' component='h3'>
            {pageTitle || 'Namen vergeben!'}
          </Typography>
        </Box>

        <Box className={classes.buttonsContainer}>
          <Button
            variant='contained'
            color='primary'
            className={classes.mr_1}
            component={RouterLink}
            to={`/cmsPage/${pageId}`}>
            Bearbeiten
          </Button>
          <Button variant='contained' color='secondary' onClick={handleDelete}>
            Löschen
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cmsContentCard: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      backgroundColor: theme.custom.defaultBackgroundColor,
      overflowWrap: 'break-word'
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center'
      }
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 0,
      paddingTop: theme.spacing(1),
      paddingLeft: 0,
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(1)
      }
    },
    mr_1: {
      marginRight: theme.spacing(1)
    }
  })
)

export default CMSContentCard
