import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  createStyles,
  makeStyles,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core'

import { IBooleanInputElement } from '../../models/settingsInterfaces'
import SimpleInputBase from './SimpleInputBase'

interface IBooleanElementProps {
  element: IBooleanInputElement
  fieldKey: string
  baseId?: string
  values?: string | boolean
  updateData?: (key: string, data: any) => void
}

const getInitialValue = (values: string | boolean | undefined): boolean =>
  values !== undefined ? (typeof values === 'boolean' ? values : values === 'true') : false

/** observer-Component */
/**
 * @param element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used for building the ids of text fields.
 * @param baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param values - The data that can be edited in this input
 * @param updateData - 'OnChange' callback for updating data. Will be passed
 * the fieldKey and the value of the input
 */
const BooleanElement: React.FC<IBooleanElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: IBooleanElementProps) => {
  const classes = useStyles()
  const checked = getInitialValue(values)

  if (!baseId) {
    baseId = fieldKey
  }

  function toggleChecked() {
    if (updateData) {
      updateData(fieldKey, !!!checked)
    }
  }

  return (
    <>
      <SimpleInputBase
        fullWidth={element.fullWidth || false}
        firstRowTitle={element.title}
        firstRowId={`input_${baseId}`}
        toolTip={element.toolTip}
        firstRowChildren={
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  id={`input_${baseId}`}
                  classes={{ root: classes.checkboxRoot }}
                  checked={checked}
                  onChange={toggleChecked}
                  name='checked'
                  color='primary'
                  aria-describedby={`description_${baseId}`}
                />
              }
              label={element.caption || ''}
            />
            {element.description && (
              <FormHelperText id={`description_${baseId}`}>{element.description}</FormHelperText>
            )}
          </FormControl>
        }
      />
    </>
  )
}

const useStyles = makeStyles(
  createStyles({
    checkboxRoot: {
      paddingTop: 0,
      paddingBottom: 0
    }
  })
)

export default observer(BooleanElement)
