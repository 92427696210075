import * as React from 'react'
import styled from 'styled-components'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import HouseGraphicButton from '../HouseGraphicButton'
import ReactResizeDetector from 'react-resize-detector'
import { observer } from 'mobx-react-lite'
import { IEHContent, IShowInGraphicOptionalLayer } from '../../../../models/ehInterfaces'
import { IConfigView } from '../../HouseGraphicConfig'

export interface IProductForBtn {
  id: string
  missingCmsPage?: boolean
  specialSVGElementId: string
  icon: string
  specialProp: IEHContent['specialProp']
  title: string
  x: string
  y: string
}

interface IInteractiveGraphicProps {
  configView: IConfigView
  svgPath: string
  contents?: IEHContent[]
  contentHasCmsPage: (id: string) => boolean
  showInGraphicAllOptionalLayers: IShowInGraphicOptionalLayer[]
  showInGraphic?: { [key: string]: 'y' | 'n' }
  clickOnCanvasFunction: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    zoom: number,
    viewportWidth: number,
    viewportHeight: number
  ) => void
  clickOnCanvasEnabled: boolean
  openAllHoverAreas: boolean
  colorPrimary?: string
  houseGraphicMinHeight?: number
  btnCanvasViewBoxSize?: number
}

/** observer-Component */
const InteractiveGraphic: React.FunctionComponent<IInteractiveGraphicProps> = ({
  configView,
  svgPath,
  contents,
  contentHasCmsPage,
  showInGraphicAllOptionalLayers,
  showInGraphic,
  clickOnCanvasFunction,
  clickOnCanvasEnabled,
  openAllHoverAreas,
  colorPrimary,
  btnCanvasViewBoxSize
}: IInteractiveGraphicProps) => {
  let zoomFactor: number = 1
  let marginLeftMinus: number = 0
  let marginTopMinus: number = 0
  const heightCorrection = 0
  const viewportWidth = 2124
  const viewportHeight = 1353
  const heightCorrectionPlusHeight = viewportHeight + heightCorrection

  const [specialSVGElements, setSpecialSVGElements] = React.useState([
    { id: 'Bad', view: false, justFunObject: false },
    { id: 'Wohnzimmer', view: false, justFunObject: false },
    { id: 'Schlafzimmer', view: false, justFunObject: false },
    { id: 'Dachs', view: false, justFunObject: true },
    { id: 'Dino', view: false, justFunObject: true }
  ])

  const [buttonOffsetPercent, setButtonOffsetPercent] = React.useState<{ x: number; y: number }>({
    x: 0,
    y: 0
  })

  const Canvas = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const Graphic = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const Complete = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const OverGraphic = React.useRef() as React.MutableRefObject<HTMLInputElement>

  const products = contents?.map((content: IEHContent) => {
    const y = content.graphic.position.percent.y + '%'
    const x = content.graphic.position.percent.x + '%'
    const hasValidCmsPage = contentHasCmsPage(content.id)

    const productCalculated: IProductForBtn = {
      id: content.id,
      missingCmsPage: !hasValidCmsPage,
      specialSVGElementId: content.graphic.showSpecialSVGElementIdOnHoverIcon,
      icon:
        content.graphic && content.graphic.icon && content.graphic.icon.name
          ? content.graphic.icon.name
          : '',
      specialProp: content.specialProp,
      title: content.title,
      x,
      y
    }
    return productCalculated
  })

  const clickOnCanvas = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!!clickOnCanvasFunction) {
      onResize()

      clickOnCanvasFunction(event, zoomFactor, viewportWidth, viewportHeight)
    }
  }

  function onResize() {
    if (Canvas.current) {
      const canvasWidth = Canvas.current.offsetWidth
      const canvasHeight = Canvas.current.offsetHeight

      const zoomFactorCalcHorizontal = canvasWidth / 800
      const zoomFactorCalcVertical = canvasHeight / 680

      if (zoomFactorCalcVertical < zoomFactorCalcHorizontal) {
        zoomFactor = zoomFactorCalcVertical
      } else {
        zoomFactor = zoomFactorCalcHorizontal
      }

      setButtonOffsetPercent({
        x: 0,
        y: ((btnCanvasViewBoxSize || 42) / viewportHeight) * 100
      })

      marginLeftMinus = (viewportWidth - canvasWidth) / 2
      marginTopMinus = (viewportHeight - canvasHeight + 65) / 2

      if (Complete.current) {
        Complete.current.style.transform = 'scale(' + zoomFactor + ')'
        Complete.current.style.marginLeft = '-' + marginLeftMinus + 'px'
        Complete.current.style.marginTop = '-' + marginTopMinus + 'px'
      }
    }
  }

  const toggleVisibleById = (viewBool: boolean, id: string) => {
    if (id !== 'none') {
      const specialSVGElementsChange = [...specialSVGElements]
      for (const element of specialSVGElementsChange) {
        if (element.id === id) {
          element.view = viewBool
        }
      }
      setSpecialSVGElements(specialSVGElementsChange)
    }
  }

  return (
    <StyledScrollArea ref={Canvas} id='housegraphicCanvasOuterDiv' houseGraphicMinHeight={600}>
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />

      <StyledCompleteCanvas
        ref={Complete}
        id='housegraphicCanvasWithNegativeMargins'
        viewportWidth={viewportWidth}
        viewportHeight={viewportHeight}>
        <StyledInteractiveGraphicCanvas
          data-ref='StyledInteractiveGraphicCanvas'
          ref={Graphic}
          viewportWidth={viewportWidth}
          viewportHeight={viewportHeight}>
          {!!specialSVGElements && (
            <StyledContainer style={{ backgroundColor: '#000' }} data-ref='StyledContainer'>
              <SvgLoader width='100%' path={svgPath} viewBox='0 0 2124 1353'>
                {!!specialSVGElements &&
                  specialSVGElements.map((item) => (
                    <div key={`wewef${item.id}`}>
                      <SvgProxy
                        onMouseOver={() => toggleVisibleById(true, item.id)}
                        onMouseOut={() => toggleVisibleById(false, item.id)}
                        selector={`#${item.id}hover`}
                        display='block'
                      />
                      <SvgProxy
                        selector={`#${item.id}`}
                        display={
                          (openAllHoverAreas && !item.justFunObject) || item.view === true
                            ? 'block'
                            : 'none'
                        }
                      />
                    </div>
                  ))}
                {!!showInGraphicAllOptionalLayers &&
                  showInGraphicAllOptionalLayers.length > 0 &&
                  showInGraphicAllOptionalLayers.map((item, index) => (
                    <SvgProxy
                      key={`prop_${index}`}
                      selector={`#${item.id}`}
                      display={showInGraphic?.[item.name] === 'y' ? 'block' : 'none'}
                      // display={item.view === true ? 'block' : 'none'}
                    />
                  ))}
                {!!colorPrimary && <SvgProxy selector='.primaryColorFill' fill={colorPrimary} />}
              </SvgLoader>
            </StyledContainer>
          )}
        </StyledInteractiveGraphicCanvas>

        <StyledOverInteractiveGraphicCanvas
          clickEnabled={clickOnCanvasEnabled}
          data-ref='StyledOverInteractiveGraphicCanvas'
          heightCorrection={heightCorrection}
          heightCorrectionPlusHeight={heightCorrectionPlusHeight}
          ref={OverGraphic}
          onClick={(event) => {
            clickOnCanvas(event)
          }}>
          {products?.map((item) => (
            <ButtonCanvas
              id={`ButtonCanvas${item.id}`}
              zoom={1}
              key={item.id}
              marginLeft={item.x}
              marginTop={`${parseFloat(item.y) - buttonOffsetPercent.y}%`}
              onMouseOver={() => toggleVisibleById(true, item.specialSVGElementId)}
              onMouseOut={() => toggleVisibleById(false, item.specialSVGElementId)}>
              <ButtonInnerCanvas>
                <HouseGraphicButton
                  product={item}
                  configView={configView}
                  btnCanvasViewBoxSize={btnCanvasViewBoxSize}
                />
              </ButtonInnerCanvas>
            </ButtonCanvas>
          ))}
        </StyledOverInteractiveGraphicCanvas>
      </StyledCompleteCanvas>
    </StyledScrollArea>
  )
}

export default observer(InteractiveGraphic)

interface IStyledScrollAreaProps {
  houseGraphicMinHeight?: number
}

const StyledScrollArea = styled.div.attrs<IStyledScrollAreaProps>(({ houseGraphicMinHeight }) => ({
  style: {
    minHeight: houseGraphicMinHeight
  }
}))<IStyledScrollAreaProps>`
  display: block;
  position: absolute;
  margin-top: 0px;
  margin-left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
  overflow-y: hidden;
`

const StyledContainer = styled.div`
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0) !important;
  & > svg {
    border-radius: 10px;
  }
`

interface IButtonCanvasProps {
  zoom: number
  marginTop: number | string
  marginLeft: number | string
}

const ButtonCanvas = styled.div<IButtonCanvasProps>`
  /*
  margin-top: calc(${(props) => (props.marginTop ? props.marginTop : '50')}% - 21px);
  margin-left: calc(${(props) => (props.marginLeft ? props.marginLeft : '50')}% - 21px);
  */
  top: ${(props) => (props.marginTop ? props.marginTop : '50%')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '50%')};
  width: auto; /* space for mouse over label: 220px */
  height: 42px;
  display: table;
  position: absolute;
  pointer-events: auto;
  transform: scale(${(props) => props.zoom}) !important;
`

const ButtonInnerCanvas = styled.div`
  pointer-events: auto;
`

interface IStyledCompleteCanvasProps {
  viewportWidth: number
  viewportHeight: number
}

const StyledCompleteCanvas = styled.div<IStyledCompleteCanvasProps>`
  display: block;
  position: absolute;
  margin-top: 0px;
  margin-left: 0px;

  width: ${(props) => props.viewportWidth}px;
  height: ${(props) => props.viewportHeight}px;
  -webkit-box-sizing: border-box;
  /*background-color: rgba(0, 0, 0, 0);*/
`

interface IStyledInteractiveGraphicCanvasProps {
  viewportWidth: number
  viewportHeight: number
}

const StyledInteractiveGraphicCanvas = styled.div<IStyledInteractiveGraphicCanvasProps>`
  display: block;
  position: absolute;
  margin-top: 0px;
  margin-left: 0px;

  width: ${(props) => props.viewportWidth}px;
  height: ${(props) => props.viewportHeight}px;
  -webkit-box-sizing: border-box;
  /*background-color: rgba(0, 0, 0, 0);*/
`

interface IStyledOverInteractiveGraphicCanvasProps {
  clickEnabled: boolean
  heightCorrection: number
  heightCorrectionPlusHeight: number
}

const StyledOverInteractiveGraphicCanvas = styled.div<IStyledOverInteractiveGraphicCanvasProps>`
  display: block;
  position: absolute;
  pointer-events: ${(props) => (props.clickEnabled ? 'inherit' : 'none')};
  margin-top: -${(props) => props.heightCorrection}px;
  margin-left: 0px;
  width: 2124px;
  height: ${(props) => props.heightCorrectionPlusHeight}px;
  -webkit-box-sizing: border-box;
  /*background-color: rgba(100, 100, 100, 0.5);*/
`
