import React from 'react'
import { observer } from 'mobx-react-lite'

import SettingsAccordion from '../components/organisms/SettingsAccordion'
import SettingsPage from '../components/SettingsPage'
import { useStore } from '../stores/StoreContext'
import { useReactToChanges } from '../utils/customHooks'

const CISettings: React.FC = observer(() => {
  const settingsId = 'cisettings'
  const { formStore, uiStore } = useStore()

  const hasChanges = formStore.settingHasChanges(formStore.CISettingsData)

  useReactToChanges(hasChanges)

  function categoryHasChanges(categoryId: string) {
    return formStore.categoryHasChanges(categoryId, formStore.CISettingsData)
  }

  return (
    <>
      <SettingsPage
        settings={formStore.CISettings}
        settingsLoaded={formStore.CISettingsLoaded}
        data={formStore.CISettingsData}
        dataLoaded={formStore.CISettingsDataLoaded}
        setDataFunc={formStore.setCIData}
        updateDataFnc={formStore.updateCIData}
        receiveDataFunc={formStore.receiveCISettingsData}
        saveObj={false} // Makes TextElement not wrap data in an 'question' object
      >
        {({ settings, getElement }) => (
          <SettingsAccordion
            expandedPanels={uiStore.expandedPanels}
            setExpandedPanel={uiStore.setExpandedPanel}
            settingsId={settingsId}
            settings={settings}
            getElement={getElement}
            categoryHasChanges={categoryHasChanges}
          />
        )}
      </SettingsPage>
    </>
  )
})

export default CISettings
