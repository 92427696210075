import React from 'react'
import { observer } from 'mobx-react-lite'

import { useStore } from '../stores/StoreContext'
import SettingsAccordion from '../components/organisms/SettingsAccordion'
import SettingsPage from '../components/SettingsPage'
import { useReactToChanges } from '../utils/customHooks'

const PVEmail: React.FC = observer(() => {
  const settingsId = 'pvemailsettings'
  const { uiStore, formStore } = useStore()
  const hasChanges = formStore.settingHasChanges(formStore.PVEmailSettingsData)

  useReactToChanges(hasChanges)

  function categoryHasChanges(categoryId: string) {
    return formStore.categoryHasChanges(categoryId, formStore.PVEmailSettingsData)
  }

  return (
    <SettingsPage
      settings={formStore.PVEmailSettings}
      settingsLoaded={formStore.PVEmailSettingsLoaded}
      data={formStore.PVEmailSettingsData}
      dataLoaded={formStore.PVEmailSettingsDataLoaded}
      setDataFunc={formStore.setPVEmailData}
      updateDataFnc={formStore.updatePVEmailData}
      receiveDataFunc={formStore.receivePVEmailSettingsData}>
      {({ settings, getElement }) => (
        <SettingsAccordion
          expandedPanels={uiStore.expandedPanels}
          setExpandedPanel={uiStore.setExpandedPanel}
          settingsId={settingsId}
          settings={settings}
          getElement={getElement}
          categoryHasChanges={categoryHasChanges}
        />
      )}
    </SettingsPage>
  )
})

export default PVEmail
