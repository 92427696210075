import { observer } from 'mobx-react-lite'
import React from 'react'
import { ITextWithLinkElement } from '../../models/settingsInterfaces'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

interface ITextWithLink {
  description: string
  linkText?: string
  url?: string
}

interface ITextWithLinkElementProps {
  element: ITextWithLinkElement
  fieldKey: string
  baseId?: string
  values?: any
  updateData?: (key: string, data: any) => void
}

const TextWithLinkElement: React.FC<ITextWithLinkElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: ITextWithLinkElementProps) => {
  const value: ITextWithLink = values || { description: '', linkText: '', url: '' }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    let newValues: ITextWithLink = value
    newValues = { ...value, [`${event.target.name}`]: event.target.value }

    if (updateData) {
      updateData(fieldKey, newValues)
    }
  }

  return (
    <Grid container item xs={12} spacing={1}>
      {/* ---- description ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-mr`}>
          {element.title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          type='text'
          id={`${fieldKey}_description`}
          name='description'
          value={value.description}
          fullWidth
          multiline
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: 'input',
            inputProps: {
              maxLength: 2000
            }
          }}
        />
      </Grid>

      {/* ---- linkText ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-mr`}>
          {'Link-Text'}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          type='text'
          id={`${fieldKey}_linkText`}
          name='linkText'
          value={!!value.linkText ? value.linkText : ''}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: 'input',
            inputProps: {
              maxLength: 2000
            }
          }}
        />
      </Grid>

      {/* ---- URL ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-mr`}>
          {'URL'}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          type='text'
          id={`${fieldKey}_url`}
          name='url'
          value={!!value.url ? value.url : ''}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: 'input',
            inputProps: {
              maxLength: 2000
            }
          }}
        />
      </Grid>
    </Grid>
  )
}

export default observer(TextWithLinkElement)
