import React from 'react'
import {
  createStyles,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import { useStore } from '../../stores/StoreContext'
import { IConfirmDialogSettings } from '../../stores/UIStore'
import { observer } from 'mobx-react-lite'

interface IConfirmDialogProps extends IConfirmDialogSettings {
  open: boolean
}

/** observer-Component */
const ConfirmDialog: React.FC = () => {
  const { uiStore } = useStore()

  function handleConfirm() {
    if (uiStore.confirmDialogConfig?.onConfirm) {
      uiStore.confirmDialogConfig.onConfirm()
    }
    uiStore.setConfirmDialogConfig(undefined)
    uiStore.setConfirmDialogOpen(false)
  }

  function handleCancel() {
    if (uiStore.confirmDialogConfig?.onCancel) {
      uiStore.confirmDialogConfig.onCancel()
    }
    uiStore.setConfirmDialogConfig(undefined)
    uiStore.setConfirmDialogOpen(false)
  }

  function handleClose() {
    uiStore.setConfirmDialogConfig(undefined)
    uiStore.setConfirmDialogOpen(false)
  }

  return (
    <ConfirmDialogDumb
      open={uiStore.confirmDialogOpen}
      title={uiStore.confirmDialogConfig?.title}
      description={uiStore.confirmDialogConfig?.description}
      confirmBtnText={uiStore.confirmDialogConfig?.confirmBtnText}
      cancelBtnText={uiStore.confirmDialogConfig?.cancelBtnText}
      extraDanger={uiStore.confirmDialogConfig?.extraDanger}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onClose={handleClose}
    />
  )
}

const ConfirmDialogDumb: React.FC<IConfirmDialogProps> = ({
  open,
  title = 'Bitte bestätigen',
  description,
  confirmBtnText = 'Bestätigen',
  cancelBtnText = 'Abbrechen',
  extraDanger = false,
  onConfirm,
  onCancel,
  onClose
}: IConfirmDialogProps) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      // TransitionComponent={Transition}
      onClose={onClose}
      aria-labelledby='confirm-dialog-title'
      aria-describedby='confirm-dialog-description'>
      <DialogTitle id='confirm-dialog-title'> {title}</DialogTitle>
      {description && (
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <>
            {extraDanger && (
              <Box mr={2} mt={0.5}>
                <WarningIcon fontSize='large' color='error' />
              </Box>
            )}

            {description}
          </>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          {cancelBtnText}
        </Button>
        <Button onClick={onConfirm} color='primary'>
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(
  createStyles({
    dialogContentRoot: {
      display: 'flex',
      alignItems: 'center'
    }
  })
)

export default observer(ConfirmDialog)
