import {
  createStyles,
  makeStyles,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { IQuestion, TPresentation, TSubType } from '../../../models/questionsInterfaces'

import { useUtilStyles } from '../../../styles/useUtilStyles'

interface IButtonsRowProps {
  question: IQuestion
  changeSubType: (event: React.MouseEvent<HTMLElement>, newSubType: TSubType) => void
  changePresentation: (event: React.MouseEvent<HTMLElement>, newSubType: TPresentation) => void
}

/** observer-Component */
const ButtonsRow: React.FC<IButtonsRowProps> = ({
  question,
  changeSubType,
  changePresentation
}: IButtonsRowProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const utilclasses = useUtilStyles()
  const classes = useStyles({ isDesktop })

  return (
    <>
      <Grid item xs={4} sm={4}>
        <ToggleButtonGroup
          value={question.selectSubType}
          exclusive
          orientation={isDesktop ? 'horizontal' : 'vertical'}
          aria-label='Einstellung für Mehrfachauswahl'
          onChange={changeSubType}
          classes={{ root: classes.btnGroupRoot }}>
          <ToggleButton value='single' aria-label='Mehrfachauswahl nicht erlauben'>
            <Tooltip title='Mehrfachauswahl nicht erlauben'>
              <Typography className={classes.toggleBtnLabel}>Single</Typography>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value='multi' aria-label='Mehrfachauswahl erlauben'>
            <Tooltip title='Mehrfachauswahl erlauben'>
              <Typography className={classes.toggleBtnLabel}>Multi</Typography>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid container item xs={8} sm={8} className={utilclasses.floatRight_sm_up}>
        <ToggleButtonGroup
          value={question.presentation}
          exclusive
          orientation={isDesktop ? 'horizontal' : 'vertical'}
          aria-label='Art der Präsentation'
          onChange={changePresentation}
          classes={{ root: classes.btnGroupRoot }}>
          <ToggleButton value='singleline' aria-label='Eine Auswahlmöglichkeit pro Zeile'>
            <Tooltip title='Eine Auswahlmöglichkeit pro Zeile'>
              <Typography className={classes.toggleBtnLabel}>Single Line</Typography>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value='cloud' aria-label='Auswahlmöglichkeiten als Button-Wolke darstellen'>
            <Tooltip title='Auswahlmöglichkeiten als Button-Wolke darstellen'>
              <Typography className={classes.toggleBtnLabel}>Cloud</Typography>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value='slider' aria-label='Auswahlmöglichkeiten als Slider darstellen'>
            <Tooltip title='Auswahlmöglichkeiten als Slider darstellen'>
              <Typography className={classes.toggleBtnLabel}>Slider</Typography>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value='checkbox' aria-label='Auswahlmöglichkeiten als Checkbox darstellen'>
            <Tooltip title='Auswahlmöglichkeiten als Checkbox darstellen'>
              <Typography className={classes.toggleBtnLabel}>Check box</Typography>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </>
  )
}

interface IStyleProps {
  isDesktop: boolean
}

const useStyles = makeStyles(
  createStyles({
    btnGroupRoot: {
      flexGrow: (props: IStyleProps) => (props.isDesktop ? 'initial' : 1),
      width: (props: IStyleProps) => (props.isDesktop ? 'initial' : '100%')
    },
    toggleBtnLabel: {
      fontSize: '0.875rem'
    }
  })
)

export default observer(ButtonsRow)
