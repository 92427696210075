const cmsStrings: { [id: string]: { [key: string]: string } } = {
  ENERGIEHAUSSTANDARD: {
    plural: 'Produkte',
    longPlural: 'Produkseiten'
  },
  MOREPAGE: {
    plural: 'Produkt Folgeinhalte',
    longPlural: 'Folgeseiten für Produkte',
    description:
      'Was ist ein Folgeinhalt? Dies ist ein Inhalt, der per Button aus einem z.B. Produktinhalt angewählt wird. Der Folgeinhalt erscheint z.B. bei der Tablet-Ansicht rechts neben dem Produkt-Inhalt.' +
      '\n' +
      'Bei Energiespartipps gibt es ebenfalls diese Verkettung, der eigentliche Tipp ist der Energiespartipp Folgeinhalt. Energiespartipps-Übersichtseite steuert diese an.' +
      '\n' +
      'Legen Sie x Produkte an und eine Energiespartipps Übersichtsseite an.' +
      '\n' +
      'Wenn Sie beispielsweise ein Produkt bearbeiten, dann können Sie dort Produktfolgeinhalte zur Verlinkung anwählen. Diese Folgeinhalte sollten Sie vorher angelegt haben. Sie können aber auch während der Erstellung eines Produkts einen leeren Folgeinhalt anlegen. Das gleiche gilt auch bei Energiespartipps.'
  },
  VIDEOPAGE: {
    plural: 'Video Folgeinhalte',
    longPlural: 'Folgeseiten für Videos',
    description:
      'Was ist ein Folgeinhalt? Dies ist ein Inhalt, der per Button aus einem z.B. Produktinhalt angewählt wird. Der Folgeinhalt erscheint z.B. bei der Tablet-Ansicht rechts neben dem Produkt-Inhalt.' +
      '\n' +
      'Bei Energiespartipps gibt es ebenfalls diese Verkettung, der eigentliche Tipp ist der Energiespartipp Folgeinhalt. Energiespartipps-Übersichtseite steuert diese an.' +
      '\n' +
      'Legen Sie x Produkte an und eine Energiespartipps Übersichtsseite an.' +
      '\n' +
      'Wenn Sie beispielsweise ein Produkt bearbeiten, dann können Sie dort Produktfolgeinhalte zur Verlinkung anwählen. Diese Folgeinhalte sollten Sie vorher angelegt haben. Sie können aber auch während der Erstellung eines Produkts einen leeren Folgeinhalt anlegen. Das gleiche gilt auch bei Energiespartipps.'
  },
  TIPP: {
    plural: 'Energiespartipps',
    longPlural: 'Übersichtsseiten für Energiespartipps'
  },
  MOREPAGETIPPS: {
    plural: 'Energiespartipps Folgeinhalte',
    longPlural: 'Folgeseiten für Energiespartipps',
    description:
      'Was ist ein Folgeinhalt? Dies ist ein Inhalt, der per Button aus einem z.B. Produktinhalt angewählt wird. Der Folgeinhalt erscheint z.B. bei der Tablet-Ansicht rechts neben dem Produkt-Inhalt.' +
      '\n' +
      'Bei Energiespartipps gibt es ebenfalls diese Verkettung, der eigentliche Tipp ist der Energiespartipp Folgeinhalt. Energiespartipps-Übersichtseite steuert diese an.' +
      '\n' +
      'Legen Sie x Produkte an und eine Energiespartipps Übersichtsseite an.' +
      '\n' +
      'Wenn Sie beispielsweise ein Produkt bearbeiten, dann können Sie dort Produktfolgeinhalte zur Verlinkung anwählen. Diese Folgeinhalte sollten Sie vorher angelegt haben. Sie können aber auch während der Erstellung eines Produkts einen leeren Folgeinhalt anlegen. Das gleiche gilt auch bei Energiespartipps.'
  }
}

export default cmsStrings
