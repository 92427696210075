import * as React from 'react'
import GraphicCanvas from './lib/GraphicCanvas'
import { observer } from 'mobx-react-lite'
import { IEHContents, IShowInGraphicOptionalLayer } from '../../../models/ehInterfaces'
import { IConfigView } from '../HouseGraphicConfig'

interface IHouseGraphicParentProps {
  configView: IConfigView
  contentHasCmsPage: (id: string) => boolean
  svgPath: string
  contentsData: IEHContents | undefined
  showInGraphicAllOptionalLayers: IShowInGraphicOptionalLayer[]
  openAppHoverAreas: boolean
  houseGraphicMinHeight: number
  btnCanvasViewBoxSize: number
  setGraphicsPositionInContent: (contentId: string, xpos: number, ypos: number) => void
}

/** observer-Component */
export const HouseGraphicParent: React.FunctionComponent<IHouseGraphicParentProps> = ({
  configView,
  contentHasCmsPage,
  svgPath,
  contentsData,
  showInGraphicAllOptionalLayers,
  openAppHoverAreas,
  houseGraphicMinHeight,
  btnCanvasViewBoxSize,
  setGraphicsPositionInContent
}: IHouseGraphicParentProps) => {
  let clickOnCanvasEnabled: boolean = false
  if (configView.placeImagePointId !== '') {
    clickOnCanvasEnabled = true
  }

  function clickOnCanvasFunction(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    zoom: number,
    viewportWidth: number,
    viewportHeight: number
  ) {
    if (configView.placeImagePointId !== '') {
      const nativeevent = event.nativeEvent
      const percentX = (nativeevent.offsetX / viewportWidth) * 100
      const percentY = (nativeevent.offsetY / viewportHeight) * 100

      setGraphicsPositionInContent(configView.placeImagePointId, percentX, percentY)
    }
  }

  return (
    <GraphicCanvas
      svgPath={svgPath}
      contentHasCmsPage={contentHasCmsPage}
      openAllHoverAreas={openAppHoverAreas}
      contents={contentsData?.contents}
      clickOnCanvasEnabled={clickOnCanvasEnabled}
      showInGraphicAllOptionalLayers={showInGraphicAllOptionalLayers}
      showInGraphic={contentsData?.meta?.showInGraphic}
      configView={configView}
      clickOnCanvasFunction={clickOnCanvasFunction}
      houseGraphicMinHeight={houseGraphicMinHeight}
      btnCanvasViewBoxSize={btnCanvasViewBoxSize}
    />
  )
}

export default observer(HouseGraphicParent)
