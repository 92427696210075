import * as React from 'react'
import styled from 'styled-components'
import { SvgLoader } from 'react-svgmt'
import buttonIcon from './assets/ButtonIcon.svg'
import buttonIconEditMode from './assets/ButtonIconEditMode.svg'
import buttonIconAlert from './assets/ButtonIconAlert.svg'
import { IConfigView } from '../HouseGraphicConfig'
import { IProductForBtn } from './lib/GraphicCanvas'

interface IHouseGraphicButtonProps {
  configView: IConfigView
  product: IProductForBtn
  btnCanvasViewBoxSize?: number
}

export const HouseGraphicButton: React.FunctionComponent<IHouseGraphicButtonProps> = ({
  configView,
  product,
  btnCanvasViewBoxSize
}: IHouseGraphicButtonProps) => (
  <>
    <StyledImagePoint>
      <SvgLoader
        path={
          configView.placeImagePointId === product.id
            ? buttonIconEditMode
            : product.missingCmsPage && product.missingCmsPage === true
            ? buttonIconAlert
            : buttonIcon
        }
        viewBox={`0 0 ${btnCanvasViewBoxSize || 42} ${btnCanvasViewBoxSize || 42}`}
      />
    </StyledImagePoint>
  </>
)

export default HouseGraphicButton

interface IStyledImagePointProps {
  btnCanvasViewBoxSize?: number
}

export const StyledImagePoint = styled.div<IStyledImagePointProps>`
  /*display: inline-flex;*/

  /*border-radius: 25px;*/
  height: ${(props) => props.btnCanvasViewBoxSize || 42}px;
  width: ${(props) => props.btnCanvasViewBoxSize || 42}px;

  position: absolute;
  display: table;
`
