import { observer } from 'mobx-react-lite'
import React, { ReactElement, ReactNode } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useStore } from '../../stores/StoreContext'
import BasicFallback from '../fallbacks/BasicFallback'
import CenteredSpinner from '../fallbacks/CenteredSpinner'

export type TSupportRouteProps = RouteProps & {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

const SupportRoute: React.FC<TSupportRouteProps> = ({
  children,
  ...restProps
}: TSupportRouteProps) => {
  const { userStore } = useStore()

  return userStore.isLoading ? (
    <CenteredSpinner />
  ) : !userStore.isLoading && !userStore.isLoggedIn ? (
    <Redirect to='/login' />
  ) : userStore.isLoggedIn && !userStore.user?.isSupport ? (
    <BasicFallback
      title='Hier geht es nicht weiter...'
      message='Dieser Inhalt ist nur für Benutzer mit Support Credentials verfügbar.'
    />
  ) : (
    <Route {...restProps}>{children}</Route>
  )
}

export default observer(SupportRoute)
