import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const CMSPagesSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <>
      <Skeleton variant='rect' height={60} className={classes.my_1} />
      <Skeleton variant='rect' height={60} className={classes.my_1} />
      <Skeleton variant='rect' height={60} className={classes.my_1} />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    my_1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
)

export default CMSPagesSkeleton
