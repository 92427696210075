import cuid from 'cuid'
import _ from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import {
  IModulePage,
  IModuleTemplate,
  ISubModulePage,
  ISubModuleTemplate,
  IPage,
  IAtomPage,
  IAtomTemplate,
  ITemplate,
  ICmsTemplatesConfig
} from '../models/cmsInterfaces'
import Api from '../utils/api'
import { Logger } from '../utils/log'

import ApplicationStore from './ApplicationStore'

export interface IFeedback {
  success: boolean
  errorCode: string
  error: string
}

export interface IPagesListByTemplateId {
  [key: string]: { loaded: boolean; list: IPage[] }
}

class CMSStore {
  private rootStore: ApplicationStore
  public pages: IPage[] | undefined = undefined
  public pagesListByTemplateId: IPagesListByTemplateId = {}
  public pagesLoaded: boolean = false
  public page: IPage | undefined = undefined
  public pageInitial: IPage | undefined = undefined
  public pageLoaded: boolean = false
  public template: ITemplate | undefined = undefined
  public configReady = false
  public templatesConfig: ICmsTemplatesConfig | undefined = undefined
  public templatesConfigLoaded: boolean = false
  public templateChoosenForAdding: ITemplate | undefined = undefined
  private templatesConfVersion: number | undefined = undefined

  public constructor(rootStore: ApplicationStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public setTemplateChoosenForAdding = (template: ITemplate) => {
    this.templateChoosenForAdding = template
  }

  /* --------- template configuration --------- */
  /** Fetch configuration file with all template data */
  public receiveConfiguration = async () => {
    if (!this.configReady) {
      try {
        const res = await Api.get('/cms/config')

        runInAction(() => {
          this.templatesConfig = res.data
          this.templatesConfVersion = parseFloat(res.data.cmsTemplatesConfVersion)
          this.templateChoosenForAdding = res.data.cmsTemplatesConf[0]
          this.templatesConfigLoaded = true
          this.configReady = true
        })
      } catch (error) {
        runInAction(() => {
          this.templatesConfigLoaded = true
        })
      }
    }
  }

  /** Fetch template config by version number */
  private getDBTemplatesConf = async (templatesConfVersion: number) => {
    try {
      const res = await Api.get(`/cms/template/conf-version/${templatesConfVersion}`)
      return res.data
    } catch (error) {
      return undefined
    }
  }

  /* ------ Computeds ------ */
  public get cmsPageHasChanges() {
    return !_.isEqual(this.page, this.pageInitial)
  }

  public moduleHasChanges = (pageModule: IModulePage): boolean => {
    if (!this.page || !this.pageInitial) {
      return false
    }

    const moduleInitial = this.pageInitial.modules.find(
      (module) => module.meta.global.uid === pageModule.meta.global.uid
    )

    return moduleInitial ? !_.isEqual(moduleInitial, pageModule) : false
  }

  public subModuleHasChanges = (
    pageModule: IModulePage,
    pageSubModule: ISubModulePage
  ): boolean => {
    if (!this.page || !this.pageInitial) {
      return false
    }

    const moduleInitial = this.pageInitial.modules.find(
      (module) => module.meta.global.uid === pageModule.meta.global.uid
    )

    if (!moduleInitial) {
      return false
    }

    const subModuleInitial = moduleInitial.subModules.find(
      (subModule) => subModule.meta.global.uid === pageSubModule.meta.global.uid
    )
    return subModuleInitial ? !_.isEqual(subModuleInitial, pageSubModule) : false
  }

  /* --------- page and template getters --------- */
  public getPages = async () => {
    try {
      const res = await Api.get('/cms/pages')
      runInAction(() => {
        this.pages = res.data
        this.pagesLoaded = true
      })
      Logger.log('received Pages', res.data)
    } catch (error) {
      this.rootStore.uiStore.setSnackbarError('Seiten konnten nicht geladen werden.')
      this.pagesLoaded = true
    }
  }

  /** Fetch pages per template id. This is used to let the user choose a
   *  follow-up content ('Folgeinhalt') for a page/product. */
  public getPagesByTemplateId = async (id: string) => {
    try {
      this.pagesListByTemplateId[id] = {
        loaded: false,
        list: []
      }

      const res = await Api.get(`/cms/pages-list-by-template-id/${id}`)
      runInAction(() => {
        this.pagesListByTemplateId[id] = {
          loaded: true,
          list: res.data
        }
      })
      Logger.log('received Pages with id' + id, res.data)
    } catch (error) {
      this.pagesListByTemplateId[id] = {
        loaded: true,
        list: []
      }
    }
  }

  /** Fetch a page together with the related template configuration */
  public getPageWithTemplateConfig = async (pageId: string) => {
    try {
      runInAction(() => {
        this.pageLoaded = false
      })
      const res = await Api.get(`/cms/page-with-template/${pageId}`)

      runInAction(() => {
        if (!res.data?.page?.hasUids) {
          this.addUidToModules(res.data.page)
        }
        this.page = res.data.page
        this.pageInitial = res.data.page
        this.template = this.getTemplateForCurrentPage(res.data.template, res.data.page)
        this.pageLoaded = true
      })
      Logger.log('received page and template', res.data)
    } catch (error) {
      runInAction(() => {
        this.pageLoaded = true
        this.rootStore.uiStore.setSnackbarError('Fehler beim Laden der Seite.')
      })
    }
  }

  /** Get template configuration for a page */
  private getTemplateForCurrentPage = (
    templateConfig: ICmsTemplatesConfig,
    cmsPage: IPage
  ): ITemplate | undefined => {
    if (templateConfig.cmsTemplatesConf.length < 1) {
      return undefined
    } else {
      return templateConfig.cmsTemplatesConf.find(
        (template: ITemplate) => template?.meta?.global?.id === cmsPage.templateId
      )
    }
  }

  public getTemplateById = (templateId: string): ITemplate | undefined =>
    !this.templatesConfig?.cmsTemplatesConf || this.templatesConfig.cmsTemplatesConf.length === 0
      ? undefined
      : this.templatesConfig.cmsTemplatesConf.find(
          (template: ITemplate) => template.meta.global.id === templateId
        )

  public getTemplateByIdName = (templateId: string): ITemplate | undefined =>
    !this.templatesConfig?.cmsTemplatesConf || this.templatesConfig.cmsTemplatesConf.length === 0
      ? undefined
      : this.templatesConfig.cmsTemplatesConf.find(
          (template: ITemplate) => template.meta.global.id === templateId
        )

  /* --------- creating and updating pages --------- */
  public createPage = async (templateId: string, name?: string) => {
    try {
      if (this.templatesConfVersion) {
        let template
        template = await this.getDBTemplatesConf(this.templatesConfVersion)
        if (!template) {
          if (this.templatesConfig) {
            template = await this.createDBTemplate({
              cmsTemplatesConfVersion: this.templatesConfVersion,
              cmsTemplatesConf: this.templatesConfig.cmsTemplatesConf
            })
          } else {
            throw new Error('Could not create page. No configuration loaded.')
          }
        }

        const res = await Api.postJson(
          '/cms/page',
          this.getInitObject(template._id, templateId, name)
        )
        Logger.log('Created page', res.data)
        return res.data
      } else {
        throw new Error(
          `There is no template configuration version number available. 
		  Load a configuration file first (e.g. using receiveConfiguration).`
        )
      }
    } catch (error) {
      Logger.log(error)
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarError('Fehler beim Erstellen der Seite.')
      })
    }
  }

  public deletePage = async (pageId: string) => {
    try {
      runInAction(() => {
        this.rootStore.uiStore.setSavingDisabled(true)
      })

      const res = await Api.delete(`/cms/page/${pageId}`)

      Logger.log('Deleted page', res.data)
      runInAction(() => {
        this.rootStore.uiStore.setSavingDisabled(false)
      })
    } catch (error) {
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarError('Fehler beim Löschen der Seite.')
        this.rootStore.uiStore.setSavingDisabled(false)
      })
    }
  }

  /** Send page data to DB */
  public updatePage = async () => {
    try {
      if (!this.page) {
        throw new Error('No page set.')
      } else {
        runInAction(() => {
          this.rootStore.uiStore.setSavingDisabled(true)
        })

        const res = await Api.putJson(`/cms/page/${this.page._id}`, toJS(this.page))
        Logger.log('Updated page', res.data)
      }
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarSuccess('Die Änderungen wurden gespeichert.')
        this.rootStore.uiStore.setSavingDisabled(false)
      })
    } catch (error) {
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarError('Fehler beim Speichern.')
        this.rootStore.uiStore.setSavingDisabled(false)
      })
    }
  }

  public addModuleToPage = (module: IModuleTemplate): IFeedback => {
    Logger.log('adding module', toJS(module))
    const feedback = { success: true, errorCode: '', error: '' }

    if (this.page) {
      // Check if add is allowed
      const counter = this.countTemplateModulesInPage(module, this.page)
      if (module.meta.cms.maxPerPage >= 0 && counter >= module.meta.cms.maxPerPage) {
        feedback.success = false
        feedback.errorCode = 'notMoreAllowed'
        feedback.error = 'Kein weiteres erlaubt'
      } else {
        // Add module to page
        this.page.modules.push({
          meta: {
            global: { ...module.meta.global, uid: cuid() },
            page: module.meta.page
          },
          subModules: []
        })

        this.addDefaultSubmodules(module, this.page.modules[this.page.modules.length - 1])
      }
    } else {
      feedback.success = false
      feedback.errorCode = 'noPageSet'
      feedback.error = 'Hinzufügen nicht möglich'
      console.error('Could not add module to page. No page loaded in store.')
    }

    return feedback
  }

  public deleteModule = (page: IPage, pageModuleUid: string): IFeedback => {
    const feedback = { success: true, errorCode: '', error: '' }

    const index = page.modules.findIndex(
      (module) => module.meta.global.uid && module.meta.global.uid === pageModuleUid
    )

    if (index > -1) {
      page.modules.splice(index, 1)
    } else {
      feedback.success = false
      feedback.errorCode = 'notFound'
      feedback.error = 'Entfernen nicht möglich'
      console.error(
        "Could not delete submodule. Submodule doesn't exist or doesn't have a uid field."
      )
    }

    return feedback
  }

  public addSubModule = (
    templateModule: IModuleTemplate,
    pageModule: IModulePage,
    newSubModule: ISubModuleTemplate
  ): IFeedback => {
    const feedback = { success: true, errorCode: '', error: '' }

    const addAllowed = this.checkIfSubAddAllowed(templateModule, pageModule, newSubModule)

    if (!addAllowed) {
      feedback.success = false
      feedback.errorCode = 'notMoreAllowed'
      feedback.error = 'Kein weiteres erlaubt'
    } else {
      if (newSubModule.conf.meta.page) {
        pageModule.subModules.push({
          meta: {
            global: { ...newSubModule.conf.meta.global, uid: cuid() },
            page: newSubModule.conf.meta.page
          },
          atoms: []
        })
      } else {
        pageModule.subModules.push({
          meta: { global: { ...newSubModule.conf.meta.global, uid: cuid() } },
          atoms: []
        })
      }

      this.initAtomInputsInSubmodule(
        newSubModule,
        pageModule.subModules[pageModule.subModules.length - 1]
      )
    }

    return feedback
  }

  public deleteSubModule = (
    pageModule: IModulePage,
    templateSubModule: ISubModuleTemplate,
    pageSubModuleUid: string
  ): IFeedback => {
    const feedback: IFeedback = { success: true, errorCode: '', error: '' }

    if (!this.checkIfSubDeleteAllowed(templateSubModule, pageModule)) {
      feedback.success = false
      feedback.errorCode = 'notMoreAllowed'
      feedback.error = 'Kein Entfernen erlaubt'
    }

    if (feedback.success) {
      const index = pageModule.subModules.findIndex(
        (subModule) => subModule.meta.global.uid && subModule.meta.global.uid === pageSubModuleUid
      )

      if (index > -1) {
        pageModule.subModules.splice(index, 1)
      } else {
        feedback.success = false
        feedback.errorCode = 'notFound'
        feedback.error = 'Entfernen nicht möglich'
        console.error(
          "Could not delete submodule. Submodule doesn't exist or doesn't have a uid field."
        )
      }
    }

    return feedback
  }

  /** Change a submodule's position , if the position and template permits it */
  public changeSubModulePos = (
    pageModule: IModulePage,
    subModuleId: string,
    oldIndex: number,
    newIndex: number
  ): IFeedback => {
    const feedback = { success: true, errorCode: '', error: '' }
    const pageSubModule = pageModule.subModules.find(
      (item: ISubModulePage) => item.meta.global.uid === subModuleId
    )

    if (!pageSubModule) {
      console.error(
        `Could not change position. Submodule with id ${subModuleId} not found in page module.`
      )
      feedback.success = false
      feedback.errorCode = 'notAllowed'
      feedback.error = 'Fehler beim Ändern der Position'
    } else {
      const result = this.moveSubModule(pageModule, oldIndex, newIndex)

      if (!result) {
        feedback.success = false
        feedback.errorCode = 'notAllowed'
        feedback.error = 'Positionsänderung nicht erlaubt'
      }
    }

    return feedback
  }

  /** Change a submodule's position one step up or down, if the position and template permits it */
  public changeSubModulePosUpOrDown = (
    pageModule: IModulePage,
    pageSubModuleIndex: number,
    direction: 'up' | 'down'
  ): IFeedback => {
    const feedback = { success: true, errorCode: '', error: '' }

    // Check if a position change is allowed based on current position
    let newIndex = -1
    let doIt = true
    if (direction === 'up') {
      if (pageSubModuleIndex === 0) {
        doIt = false
        feedback.success = false
        feedback.errorCode = 'notAllowed'
        feedback.error = 'Positionsänderung nicht erlaubt'
      } else {
        newIndex = pageSubModuleIndex - 1
      }
    } else if (direction === 'down') {
      if (pageSubModuleIndex === pageModule.subModules.length - 1) {
        doIt = false
        feedback.success = false
        feedback.errorCode = 'notAllowed'
        feedback.error = 'Positionsänderung nicht erlaubt'
      } else {
        newIndex = pageSubModuleIndex + 1
      }
    }

    // Check if a position change is allowed based on template and if it is,
    // swap submodule with its neighbour.
    if (doIt) {
      const result = this.moveSubModule(pageModule, pageSubModuleIndex, newIndex)

      if (!result) {
        feedback.success = false
        feedback.errorCode = 'notAllowed'
        feedback.error = 'Positionsänderung nicht erlaubt'
      }
    }

    return feedback
  }

  private moveSubModule = (
    pageModule: IModulePage,
    oldIndex: number,
    newIndex: number
  ): boolean => {
    if (
      this.checkIfSubChangePosAllowed(
        pageModule.subModules[oldIndex],
        pageModule.subModules[newIndex]
      )
    ) {
      const element = pageModule.subModules[oldIndex]
      pageModule.subModules.splice(oldIndex, 1)
      pageModule.subModules.splice(newIndex, 0, element)
      return true
    } else {
      return false
    }
  }

  private addDefaultSubmodules = (templateModule: IModuleTemplate, pageModule: IModulePage) => {
    templateModule.subModules.forEach((submodule: ISubModuleTemplate) => {
      const min = submodule.conf?.meta?.cms?.minPerModule || 0
      let counter = this.countTemplateSubModulesInPageModule(submodule, pageModule)

      while (counter < min) {
        this.addSubModule(templateModule, pageModule, submodule)
        counter++
      }
    })
  }

  public setAtomInputInPageSubmodule = (
    templateAtom: IAtomTemplate,
    pageSubModule: ISubModulePage,
    value: any,
    valueType: string
  ): void => {
    if (!pageSubModule.atoms) {
      pageSubModule.atoms = []
    }

    let foundIdx = pageSubModule.atoms.findIndex(
      (atom: IAtomPage) => atom.global.id === templateAtom.global.id
    )

    if (foundIdx === -1) {
      let pageAtomInit: IAtomPage

      if (templateAtom.page) {
        pageAtomInit = {
          global: templateAtom.global,
          page: templateAtom.page,
          val: undefined
        }
      } else {
        pageAtomInit = {
          global: templateAtom.global,
          val: undefined
        }
      }

      pageSubModule.atoms.push(pageAtomInit)
      foundIdx = pageSubModule.atoms.length - 1
    }

    if (valueType === 'string' || valueType === 'unknown') {
      // This is in old App, deletes atom after add
      //   if (value === '' && !templateAtom.global.saveEmptyIfEmpty) {
      //     pageSubModule.atoms.splice(foundIdx, 1)
      //   } else {
      //     pageSubModule.atoms[foundIdx].val = value
      //   }
      pageSubModule.atoms[foundIdx].val = value
    } else if (valueType === 'object') {
      pageSubModule.atoms[foundIdx].val = value
    }
  }

  private initAtomInputsInSubmodule(subModule: ISubModuleTemplate, pageSubModule: ISubModulePage) {
    for (let i = 0; i < subModule.conf.atoms.length; i++) {
      //add empty atoms if wanted, works for string input until now
      this.setAtomInputInPageSubmodule(subModule.conf.atoms[i], pageSubModule, '', 'unknown')
    }
  }

  public setCurrentPageName = (newName: string) => {
    if (this.page) {
      this.page.meta.nameIntern = newName
    } else {
      console.error('Could not set name. No page loaded in store.')
    }
  }

  public setCurrentPageEmail = (newEmail: string) => {
    if (this.page) {
      this.page.meta.email = newEmail
    } else {
      console.error('Could not set email. No page loaded in store.')
    }
  }

  /* ------------------ helpers ------------------------ */
  public checkIfSubAddAllowed = (
    templateModule: IModuleTemplate,
    pageModule: IModulePage,
    newSubModule: ISubModuleTemplate
  ): boolean => {
    let max: number | undefined = undefined
    if (
      newSubModule.conf?.meta?.cms?.maxPerModule &&
      newSubModule.conf.meta.cms.maxPerModule > -1
    ) {
      max = newSubModule.conf.meta.cms.maxPerModule
    }

    if (
      templateModule.meta?.cms?.subModules?.maxPerModule &&
      templateModule.meta.cms.subModules.maxPerModule > -1
    ) {
      //module conf overrides submodule conf for maxPerModule
      max = templateModule.meta.cms.subModules.maxPerModule
    }

    // Count instances of submodule on page
    if (max && max > -1) {
      const counter = this.countTemplateSubModulesInPageModule(newSubModule, pageModule)

      if (counter >= max) {
        return false
      }
    }

    return true
  }

  /** Checks if a submodule can be deleted */
  public checkIfSubDeleteAllowed = (
    templateSubModule: ISubModuleTemplate,
    pageModule: IModulePage
  ): boolean => {
    let min = 0
    let counter = 0
    if (templateSubModule.conf?.meta?.cms?.minPerModule) {
      min = templateSubModule.conf.meta.cms.minPerModule
      counter = this.countTemplateSubModulesInPageModule(templateSubModule, pageModule)
    }
    if (min > 0 && min <= counter) {
      return false
    }
    return true
  }

  /** Some submodules are fixed in position by their template. Check if this is
   * one of them. */
  public checkIfSubmodulePositionFixed = (pageSubModule: ISubModulePage): boolean => {
    const templateSubModule = this.findTemplateSubModuleById(pageSubModule.meta.global.id)

    if (!templateSubModule) {
      console.error(`Could not find template for id ${pageSubModule.meta.global.id}.`)
    }

    if (templateSubModule && templateSubModule.conf?.meta?.cms?.noPosChangeWithOtherSubModules) {
      return templateSubModule.conf.meta.cms.noPosChangeWithOtherSubModules
    } else {
      return false
    }
  }

  /** Checks if two page-submodule are allowed to swap their positions based on
  the template configration. */
  private checkIfSubChangePosAllowed = (
    pageSubModuleSource: ISubModulePage,
    pageSubModuleTarget: ISubModulePage
  ): boolean => {
    const pageSubModuleSourceId = pageSubModuleSource.meta.global.id
    const pageSubModuleTargetId = pageSubModuleTarget.meta.global.id

    // Look up template-submodules for page-submodules
    const metaSource = this.findTemplateSubModuleById(pageSubModuleSourceId)
    const metaTarget = this.findTemplateSubModuleById(pageSubModuleTargetId)

    if (!metaSource) {
      console.error(`Could not find template for id ${pageSubModuleSourceId}.`)
    }
    if (!metaTarget) {
      console.error(`Could not find template for id ${pageSubModuleTargetId}.`)
    }

    // Check if postion changes are allowed in source module
    if (
      metaSource &&
      metaSource.conf?.meta?.cms?.noPosChangeWithOtherSubModules &&
      metaSource.conf.meta.cms.noPosChangeWithOtherSubModules === true
    ) {
      if (pageSubModuleSourceId !== pageSubModuleTargetId) {
        return false
      }
    }

    // Check if postion changes are allowed in target module
    if (
      metaTarget &&
      metaTarget.conf?.meta?.cms?.noPosChangeWithOtherSubModules &&
      metaTarget.conf.meta.cms.noPosChangeWithOtherSubModules === true
    ) {
      if (pageSubModuleSourceId !== pageSubModuleTargetId) {
        return false
      }
    }

    return true
  }

  /** Find submodule in template configuration */
  private findTemplateSubModuleById = (templateSubModuleId: string): ISubModuleTemplate | false => {
    if (this.template) {
      for (let i = 0; i < this.template.modules?.length || 0; i++) {
        for (let s = 0; s < (this.template.modules[i].subModules?.length || 0); s++) {
          if (
            this.template.modules[i].subModules[s].conf?.meta?.global?.id === templateSubModuleId
          ) {
            return this.template.modules[i].subModules[s]
          }
        }
      }
    }
    return false
  }

  private countTemplateModulesInPage = (newModule: IModuleTemplate, page: IPage): number => {
    let count = 0
    page.modules.forEach((pageModule: IModulePage) => {
      if (pageModule.meta.global.id === newModule.meta.global.id) {
        count += 1
      }
    })
    return count
  }

  private countTemplateSubModulesInPageModule = (
    templateSubModule: ISubModuleTemplate,
    pageModule: IModulePage
  ): number =>
    pageModule.subModules.reduce((totalCount: number, subModule: ISubModulePage) => {
      if (subModule.meta.global.id === templateSubModule.conf.meta.global.id) {
        return ++totalCount
      } else {
        return totalCount
      }
    }, 0)

  private createDBTemplate = async (cmsTemplatesConfModel: ICmsTemplatesConfig) => {
    const res = await Api.postJson('/cms/template', toJS(cmsTemplatesConfModel))
    Logger.log('Created template', res.data)
    return res.data
  }

  /** Get empty page object */
  private getInitObject = (
    dbTemplatesConfId: string,
    templateIdChoosen: string,
    name: string = ''
  ): unknown => {
    const initObj = {
      dbTemplatesConfId,
      templateId: templateIdChoosen,
      meta: { nameIntern: name, email: '' },
      modules: []
    }
    return initObj
  }

  private addUidToModules = (page: IPage) => {
    page.modules.forEach((module) => {
      if (!module.meta?.global?.uid) {
        module.meta.global.uid = cuid()
      }

      module.subModules.forEach((subModule) => {
        if (!subModule.meta.global.uid) {
          subModule.meta.global.uid = cuid()
        }
      })
    })
    page.hasUids = true
  }
}

export default CMSStore
