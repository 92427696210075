import React, { ReactNode } from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

interface IPageTitleProps {
  children: ReactNode
}

const PageTitle: React.FC<IPageTitleProps> = ({ children }: IPageTitleProps) => {
  const classes = useStyles()

  return (
    <Typography className={classes.pageTitle} variant='h1' component='h1'>
      {children}
    </Typography>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3)
    }
  })
)

export default PageTitle
