import React, { FC, ReactElement, ReactNode, useContext, useState } from 'react'
import { FileUploadListItem } from '../../types'

interface UploadsListProviderProps {
  children: FC | ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

interface UploadsListProviderType {
  fileUploadList: FileUploadListItem[]
  setFileUploadList?: (uploadsList: FileUploadListItem[]) => void
}

const UploadsListContext = React.createContext<UploadsListProviderType>({
  fileUploadList: []
})

const UploadsListProvider: FC<UploadsListProviderProps> = ({
  children
}: UploadsListProviderProps) => {
  const [fileUploadList, setFileUploadList] = useState<FileUploadListItem[]>([])

  return (
    <UploadsListContext.Provider value={{ fileUploadList, setFileUploadList }}>
      {children}
    </UploadsListContext.Provider>
  )
}

export default UploadsListProvider

export const useUploadsListContext = () => useContext(UploadsListContext)
