import FileLibraryCard from '../components/FileLibraryCard'

export const defaultProps = {
  show: false,
  fileLibraryList: [],
  modalTitle: 'Media Library',
  selectBtnText: 'Select File',
  deleteBtnText: 'Delete',
  uploadTabText: 'Upload',
  libraryTabText: 'Library',
  dropAreaIdleText: "Drag'n drop some files here, or click to select files",
  dropAreaDraggingText: 'Drop the files here ...',
  uploadListTitleText: 'Uploaded Files',
  uploadSuccessText: 'Success',
  uploadFailedText: 'Failed',
  uploadProcessingText: 'Processing',
  sortLabelText: 'Sort by',
  sortOptionsTexts: new Map([
    ['title', 'title'],
    ['createdAt', 'date'],
    ['size', 'size'],
    ['fileName', 'file name']
  ]),
  sorOrderLabelText: 'Order',
  sorOrderOptionsTexts: new Map([
    [true, 'ascending'],
    [false, 'descending']
  ]),
  showUploadTab: true,
  showTitle: true,
  showDescription: true,
  defaultSelectedTab: 'upload',
  acceptedTypes: '',
  uploadCallbackReturnType: 'file',
  defaultSortProperty: 'createdAt',
  defaultSortAscending: false,
  itemsPerPage: 8,
  libraryCardComponent: FileLibraryCard
}
