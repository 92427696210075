import React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

import SimpleInputBase from './SimpleInputBase'

interface ITextDisplayElementProps {
  element: { title: string; mainText?: string; description?: string; fullWidth?: boolean }
  constants?: { [key: string]: string | undefined }
  baseId?: string
}

const TextDisplayElement: React.FC<ITextDisplayElementProps> = ({
  element,
  constants,
  baseId = ''
}: ITextDisplayElementProps) => {
  const classes = useStyles()

  /* If the mainText-property of the element is a string that identifies a constant,
  display the value of the constant instead of the value of the mainText-property.*/
  let displayText = ''
  if (element.mainText && element.mainText?.length > 0) {
    displayText = constants?.[element.mainText] || element.mainText
  }

  return (
    <SimpleInputBase
      fullWidth={element.fullWidth || false}
      firstRowTitle={element.title || 'Konstante'}
      firstRowId={`constant_${baseId}`}
      firstRowChildren={
        <>
          <Typography variant='body1' component='p'>
            {displayText}
          </Typography>
          {element.description && (
            <Typography variant='body2' component='p' className={classes.descriptionText}>
              {element.description}
            </Typography>
          )}
        </>
      }
    />
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionText: {
      marginTop: theme.spacing(1)
    }
  })
)

export default TextDisplayElement
