import React, { useEffect } from 'react'
import {
  createStyles,
  makeStyles,
  Dialog,
  useMediaQuery,
  useTheme,
  Typography,
  Toolbar,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import ReactMediaLibraryTabs from './ReactMediaLibraryTabs'
import { useSettings } from '../context/SettingsContext'
import { ReactMediaLibraryProps } from '../../types'

interface ReactMediaLibraryContainerProps {
  show: boolean
  onHide?: () => void
  modalTitle?: string
  mainProps: ReactMediaLibraryProps
}

const ReactMediaLibraryContainer: React.FC<ReactMediaLibraryContainerProps> = ({
  show,
  onHide,
  modalTitle,
  mainProps
}: ReactMediaLibraryContainerProps) => {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { setSettings } = useSettings()

  useEffect(() => {
    if (setSettings) {
      setSettings(mainProps)
    }
  }, [mainProps])

  return (
    <Dialog
      classes={{ paper: classes.dialogRoot }}
      open={show}
      onClose={onHide}
      maxWidth={'lg'}
      fullScreen={fullScreen}
      aria-labelledby='dialog-title'>
      <Toolbar classes={{ root: classes.dialogTitle }}>
        <Typography variant='h4'>{modalTitle}</Typography>
        <IconButton color='inherit' onClick={onHide} aria-label='close'>
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <ReactMediaLibraryTabs />
    </Dialog>
  )
}

const useStyles = makeStyles(
  createStyles({
    dialogRoot: {
      minWidth: '80vw',
      minHeight: '80vh'
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
)

export default ReactMediaLibraryContainer
