import React from 'react'
import { Box, CircularProgress, Typography } from '@material-ui/core'

const CenteredSpinner: React.FC = () => (
  <Box
    p={4}
    display='flex'
    flexDirection='column'
    justifyContent='center'
    alignItems='center'
    height='100vh'
    width='100%'>
    <CircularProgress />
    <Typography gutterBottom variant='body1' component='p'>
      Einen Moment bitte...
    </Typography>
  </Box>
)

export default CenteredSpinner
