import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { useStore } from '../stores/StoreContext'
import PageTitle from '../components/atoms/PageTitle'
import HouseGraphicConfig from '../components/energyHouse/HouseGraphicConfig'
import { action } from 'mobx'
import { Toolbar } from '@material-ui/core'
import onSaveNotifier from '../utils/OnSaveNotifier'
import EHSkeleton from '../components/fallbacks/EHSekeleton'
import PageSubtitle from '../components/atoms/PageSubtitle'
import { useReactToChanges } from '../utils/customHooks'

const EHContents: React.FC = ({}) => {
  const { ehStore, uiStore } = useStore()

  const hasChanges = ehStore.contentsDataHasChanges
  useReactToChanges(hasChanges)

  const {
    contentsData,
    cmsData,
    contentHasCmsPage,
    isLoaded,
    specialProps,
    updateContent,
    setShowInGraphic,
    setShowHoverAreaContents,
    showInGraphicAllOptionalLayers,
    placingActiveBtnColor,
    receiveCmsData,
    receiveContentsData,
    addContentWithCMSPage,
    setCMSPageInContent,
    setIconInContent,
    setHoverContentInContent,
    setSpecialPropInContent,
    setContentTitle,
    setContentPageUrl,
    deleteContent,
    setGraphicsPositionInContent,
    selectedContent,
    setSelectedContent
  } = ehStore

  const {
    houseGraphicMinHeight,
    drawerWidth,
    drawerOpenBreakpoint,
    showEHGraphicBreakpoint,
    setSnackbarError,
    topBarHeight,
    btnCanvasViewBoxSize
  } = uiStore

  function handleDeleteContent(contentId: string) {
    uiStore.openConfirmDialog({
      title: 'Inhalt löschen?',
      description:
        'Bitte bestätigen Sie, daß Sie diesen Inhalt wirklich löschen möchten. (Die Daten werden erst endgültig gelöscht, wenn Sie auf "Speichern" klicken.)',
      onConfirm: () => {
        deleteContent(contentId)
      }
    })
  }

  useEffect(() => {
    const initData = action(async () => {
      await receiveCmsData()
      await receiveContentsData()
    })
    initData()
    onSaveNotifier.subscribe(handleSave)

    return () => {
      onSaveNotifier.unsubscribe(handleSave)
    }
  }, [])

  async function handleSave() {
    if (contentsData) {
      await updateContent()
      await receiveContentsData()
    } else {
      console.error('Could not update contents. No contents available')
      setSnackbarError('Speichern nicht möglich.')
    }
  }

  return (
    <>
      <Toolbar />
      <PageTitle>Energiehaus</PageTitle>
      <PageSubtitle>{uiStore.pleaseSaveYourSettingsText}</PageSubtitle>

      {isLoaded ? (
        <HouseGraphicConfig
          contentsData={contentsData}
          cmsData={cmsData}
          contentHasCmsPage={contentHasCmsPage}
          specialProps={specialProps}
          setShowInGraphic={setShowInGraphic}
          setShowHoverAreaContents={setShowHoverAreaContents}
          houseGraphicMinHeight={houseGraphicMinHeight}
          appBarLeftOffset={drawerWidth}
          appBarTopOffset={topBarHeight}
          appBarLeftOffsetBreakPoint={drawerOpenBreakpoint}
          showEHGraphicBreakpoint={showEHGraphicBreakpoint}
          btnCanvasViewBoxSize={btnCanvasViewBoxSize}
          showInGraphicAllOptionalLayers={showInGraphicAllOptionalLayers}
          placingActiveBtnColor={placingActiveBtnColor}
          onAddContent={addContentWithCMSPage}
          onSetProduct={setCMSPageInContent}
          onSetIcon={setIconInContent}
          onSetHoverSpecial={setHoverContentInContent}
          onSetSpecialProp={setSpecialPropInContent}
          onSetTitle={setContentTitle}
          onSetPageUrl={setContentPageUrl}
          onDeleteContent={handleDeleteContent}
          setGraphicsPositionInContent={setGraphicsPositionInContent}
          selectedContent={selectedContent}
          setSelectedContent={setSelectedContent}
        />
      ) : (
        <EHSkeleton showEHGraphicBreakpoint={showEHGraphicBreakpoint} />
      )}
    </>
  )
}

export default observer(EHContents)
