import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
  Typography,
  Box
} from '@material-ui/core'
import { IEHContents, IShowInGraphicOptionalLayer } from '../../../models/ehInterfaces'

interface IEHConfigCheckboxesProps {
  contentsData?: IEHContents
  hoverAreasOpen: boolean
  onChangeOpenHoverAreas: (open: boolean) => void
  onToggleSpecialProduct: (productname: string) => void
  showInGraphicAllOptionalLayers: IShowInGraphicOptionalLayer[]
}

/** observer-Component */
const EHConfigCheckboxes: React.FC<IEHConfigCheckboxesProps> = ({
  contentsData,
  hoverAreasOpen,
  onChangeOpenHoverAreas,
  onToggleSpecialProduct,
  showInGraphicAllOptionalLayers
}: IEHConfigCheckboxesProps) => {
  function handleChangeOpenAllHoverAreas(event: React.ChangeEvent<HTMLInputElement>): void {
    onChangeOpenHoverAreas(event.target.checked)
  }

  function handleShowInGraphicChange(productName: string) {
    onToggleSpecialProduct(productName)
  }

  return (
    <Box p={4} pt={0}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h2' component='h2'>
            Darstellungsoptionen
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Hover Flächen</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={hoverAreasOpen}
                    onChange={handleChangeOpenAllHoverAreas}
                    name='openAllHoverAreas'
                  />
                }
                label='Zeige alles hinter Hover-Flächen'
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Ebenen ein- und ausblenden</FormLabel>
            <FormGroup>
              {showInGraphicAllOptionalLayers?.map((layer: IShowInGraphicOptionalLayer) => (
                <FormControlLabel
                  key={layer.id}
                  control={
                    <Checkbox
                      checked={contentsData?.meta?.showInGraphic?.[layer.name as string] === 'y'}
                      onChange={() => handleShowInGraphicChange(layer.name)}
                      name={layer.name}
                    />
                  }
                  label={layer.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(EHConfigCheckboxes)
