import React, { ReactNode } from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

interface IPageSubTitleProps {
  children: ReactNode
}

const PageSubTitle: React.FC<IPageSubTitleProps> = ({ children }: IPageSubTitleProps) => {
  const classes = useStyles()

  return (
    <Typography className={classes.pageSubTitle} variant='body1' component='p'>
      {children}
    </Typography>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageSubTitle: {
      padding: `0 ${theme.spacing(0.5)}px`,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  })
)

export default PageSubTitle
