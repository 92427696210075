import { Box, Typography } from '@material-ui/core'
import React from 'react'
import PageTitle from '../components/atoms/PageTitle'
import CodeBlock from '../components/molecules/CodeBlock'

const Home: React.FC = () => {
  const codeRawTeaserWC = String.raw`<script type="text/javascript" src="https://app.waermecheck.KUNDE.evu-cloud.de/assets/js/teaser.js"></script>
    <script>
    window.onload = function(){
      render_teaser_m_wc(document.querySelector("#teaserAutoWT"), true)
    }
  </script>`

  const codeRawTeaserWCEmbed = String.raw`<div id="teaserAutoWT" class="teaserWrapper"></div>`

  const codeRawTeaserEH = String.raw`<script type="text/javascript" src="https://app.energiehaus.KUNDE.evu-cloud.de/assets/js/teaser.js"></script>
  <script>
  window.onload = function(){
    render_teaser_m_eh(document.querySelector("#teaserAutoEH"))
  }
  </script>`

  const codeRawTeaserEHEmbed = String.raw`<div id="teaserAutoEH" class="teaserWrapper"></div>`

  const codeRawTeaserPV = String.raw`<script type="text/javascript" src="https://app.pv-rechner.KUNDE.evu-cloud.de/assets/js/teaser.js"></script> 
  
  <script> 
    window.onload = function(){ 
      render_teaser_m_pv(document.querySelector("#teaserAutoPV"), true) 
    } 
  </script>`

  const codeRawTeaserPVEmbed = String.raw`<div id="teaserAutoPV" class="teaserWrapper"></div>`

  return (
    <>
      <PageTitle>Willkommen zum ASEW-Konfigurator</PageTitle>
      <Typography variant='body1'>
        Hier haben Sie die Möglichkeit Ihre gebuchte Applikation zu konfigurieren.
      </Typography>

      {/* ----- Waermecheck ----- */}
      {process.env.REACT_APP_BUILD && process.env.REACT_APP_BUILD.toLowerCase() === 'wc' && (
        <Box mt={2}>
          <Typography variant='h2' component='h2'>
            Wie kann ich den ASEW.WärmeCheck auf meine Webseite einbinden?
          </Typography>

          <br />
          <Typography variant='body1' gutterBottom>
            Um den ASEW.WärmeCheck auf Ihre Webseite einzubinden, müssen Sie folgende Schritte
            durchführen:
          </Typography>

          <Typography variant='body1' gutterBottom>
            Den von uns zur Verfügung gestellten Teaser können Sie auf Ihre Webseite einbinden,
            indem Sie vor dem Ende des <code>body</code>
            -Tags folgenden Code einfügen:
          </Typography>

          <CodeBlock>
            <code>{codeRawTeaserWC}</code>
          </CodeBlock>

          <Typography variant='body1' gutterBottom>
            Um die Postleitzahlenabfrage ein- oder auszuschalten, müssen sie lediglich oben im Code{' '}
            <code>withZip</code> auf <code>true</code> oder <code>false</code> setzen.
          </Typography>

          <Typography variant='body1' gutterBottom>
            An der Stelle, an dem Sie den Teaser anzeigen lassen möchten, fügen Sie bitte den
            folgenden Code ein:
          </Typography>

          <CodeBlock>
            <code>{codeRawTeaserWCEmbed}</code>
          </CodeBlock>
        </Box>
      )}

      {/* ----- Energiehaus ----- */}
      {process.env.REACT_APP_BUILD && process.env.REACT_APP_BUILD.toLowerCase() === 'eh' && (
        <Box mt={2}>
          <Typography variant='h2' component='h2'>
            Wie kann ich das ASEW.Energiehaus auf meine Webseite einbinden?
          </Typography>

          <br />

          <Typography variant='body1' gutterBottom>
            Um das ASEW.Energiehaus auf Ihre Webseite einzubinden, müssen Sie folgende Schritte
            durchführen:
          </Typography>

          <Typography variant='body1' gutterBottom>
            Den von uns zur Verfügung gestellten Teaser können Sie auf Ihre Webseite einbinden,
            indem Sie vor dem Ende des <code>body</code>
            -Tags folgenden Code einfügen:
          </Typography>

          <CodeBlock>
            <code>{codeRawTeaserEH}</code>
          </CodeBlock>

          <Typography variant='body1' gutterBottom>
            An der Stelle, an dem Sie den Teaser anzeigen lassen möchten, fügen Sie bitte den
            folgenden Code ein:
          </Typography>

          <CodeBlock>
            <code>{codeRawTeaserEHEmbed}</code>
          </CodeBlock>
        </Box>
      )}

      {/* ----- PV-Rechner ----- */}
      {process.env.REACT_APP_BUILD && process.env.REACT_APP_BUILD.toLowerCase() === 'pv' && (
        <Box mt={2}>
          <Typography variant='h2' component='h2'>
            Wie kann ich den ASEW.PV-Rechner auf meine Webseite einbinden?
          </Typography>

          <br />

          <Typography variant='body1' gutterBottom>
            Um den ASEW.PV-Rechner auf Ihre Webseite einzubinden, müssen Sie folgende Schritte
            durchführen:
          </Typography>

          <Typography variant='body1' gutterBottom>
            Den von uns zur Verfügung gestellten Teaser können Sie auf Ihre Webseite einbinden,
            indem Sie vor dem Ende des <code>body</code>
            -Tags folgenden Code einfügen:
          </Typography>

          <CodeBlock>
            <code>{codeRawTeaserPV}</code>
          </CodeBlock>

          <Typography variant='body1' gutterBottom>
            An der Stelle, an dem Sie den Teaser anzeigen lassen möchten, fügen Sie bitte den
            folgenden Code ein:
          </Typography>

          <CodeBlock>
            <code>{codeRawTeaserPVEmbed}</code>
          </CodeBlock>
        </Box>
      )}
    </>
  )
}

export default Home
