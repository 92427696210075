import React from 'react'
import { observer } from 'mobx-react-lite'

import { useStore } from '../stores/StoreContext'
import SettingsAccordion from '../components/organisms/SettingsAccordion'
import SettingsPage from '../components/SettingsPage'
import { useReactToChanges } from '../utils/customHooks'

const PVSettings: React.FC = observer(() => {
  const settingsId = 'pvsettings'
  const { uiStore, formStore } = useStore()
  const hasChanges = formStore.settingHasChanges(formStore.PVSettingsData)

  useReactToChanges(hasChanges)

  function categoryHasChanges(categoryId: string) {
    return formStore.categoryHasChanges(categoryId, formStore.PVSettingsData)
  }

  return (
    <>
      <SettingsPage
        settings={formStore.PVSettings}
        settingsLoaded={formStore.PVSettingsLoaded}
        data={formStore.PVSettingsData}
        dataLoaded={formStore.PVSettingsDataLoaded}
        setDataFunc={formStore.setPVData}
        updateDataFnc={formStore.updatePVData}
        receiveDataFunc={formStore.receivePVSettingsData}>
        {({ settings, getElement }) => (
          <>
            <SettingsAccordion
              expandedPanels={uiStore.expandedPanels}
              setExpandedPanel={uiStore.setExpandedPanel}
              settingsId={settingsId}
              settings={settings}
              getElement={getElement}
              categoryHasChanges={categoryHasChanges}
            />
          </>
        )}
      </SettingsPage>
    </>
  )
})

export default PVSettings
