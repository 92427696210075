import React from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'

import { IQuestion } from '../../../models/questionsInterfaces'

export interface IMainQuestionsProps {
  question: IQuestion
  questionErrors?: { [errorkey: string]: string }
  changeQuestion: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeQuestionLong: (event: React.ChangeEvent<HTMLInputElement>) => void
  maxNumSubQuestions: number
  baseId: string
}

/** observer-Component */
const MainQuestions: React.FC<IMainQuestionsProps> = ({
  question,
  questionErrors,
  changeQuestion,
  changeQuestionLong,
  baseId
}: IMainQuestionsProps) => (
  <>
    <Grid item xs={12}>
      <Typography variant='body1' component='label' htmlFor={`${baseId}-q`}>
        <b>Frage</b>
      </Typography>

      <TextField
        id={`${baseId}-q`}
        value={question.question}
        fullWidth
        variant='outlined'
        size='small'
        InputProps={{
          inputProps: {
            maxLength: 2000
          }
        }}
        error={Boolean(questionErrors?.question)}
        helperText={questionErrors?.question ? questionErrors.question : ''}
        onChange={changeQuestion}
      />
    </Grid>

    <Grid item xs={12}>
      <Typography variant='body1' component='label' htmlFor={`${baseId}-ql`}>
        Erklärung, unterhalb der Frage
      </Typography>

      <TextField
        id={`${baseId}-ql`}
        value={question.questionLong}
        fullWidth
        variant='outlined'
        size='small'
        InputProps={{
          inputProps: {
            maxLength: 2000
          }
        }}
        error={Boolean(questionErrors?.questionLong)}
        helperText={questionErrors?.questionLong ? questionErrors.questionLong : ''}
        onChange={changeQuestionLong}
      />
    </Grid>
  </>
)

export default observer(MainQuestions)
