import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import { useStore } from '../stores/StoreContext'

smoothscroll.polyfill()

/** Provides a ref that can be applied to a div-Element and another ref that is
 * a flag which indicates wether the div-Element should be scrolled into view
 * on the next render.
 */
export function useScrollToTarget() {
  const scrollTarget = useRef<HTMLDivElement>(null)
  const scrollToTargetAfterRender = useRef(false) // flag set by 'handleSubModuleAdd' function

  /* Scroll to target if new     scrollToTargetAfterRender
 is true */
  useEffect(() => {
    if (scrollToTargetAfterRender.current) {
      // Scroll to target
      scrollTarget?.current?.scrollIntoView({
        block: 'end',
        behavior: 'smooth'
      })
      scrollToTargetAfterRender.current = false
    }
  })

  return {
    scrollTarget,
    scrollToTargetAfterRender
  }
}

/** Scrolls element into view when a new id (to the element that we want to scroll
 * to) is provided.
 *
 * PLEASE NOTE: This hook is used in the CMSModule component to scroll accordion
 * panels into view once a new sub-module has been added. When this happens, the
 * new module's panel will be expanded and all other panels will be collapsed. This
 * process usually is not finished on the next render which is when the effect in
 * this hook will be called. Thus, the effect sets a timeout, which should be longer
 * than the duration of the animation.
 *
 * @param yOffset - Vertical offset that will be added to the scroll end-position.
 * Can be used to take into account menu-bars with fixed postion. In a case like
 * this, you might want to provide a negative value.
 */
export function useScrollToId(yOffset: number = 0) {
  const [scrollToId, setScrollToId] = useState<string | undefined>(undefined)

  // Scroll element into view if 'scrollToId' has changed
  useEffect(() => {
    const timer = setTimeout(() => {
      if (scrollToId) {
        const element = document.getElementById(scrollToId)
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        }
      }
    }, 150)
    return () => clearTimeout(timer)
  }, [scrollToId])

  return {
    setScrollToId
  }
}

/* Interface for form errors on settings pages.  */
export interface IError {
  [errorkey: string]: string
}

/* Manage errors in input components*/
export function useErrors() {
  const [errors, setErrors] = useState<IError>({})

  const setError = useCallback(
    (key: string, message: string) => {
      setErrors({ ...errors, [key]: message })
    },
    [errors]
  )

  const clearError = useCallback(
    (key: string) => {
      const newErrors = { ...errors }
      delete newErrors[key]

      setErrors(newErrors)
    },
    [errors]
  )

  const clearErrors = useCallback(() => {
    setErrors({})
  }, [])

  return {
    errors,
    setError,
    clearError,
    clearErrors
  }
}

/* Manages an array of ids that can be used to store identify containers whose
input elements have form errors. E.g. on the WTQuestions-page, the ids would 
identify questions with invalid input values and the information would be used
to style the panels that contain those questions differently.*/
export function useSettingsWithErrors() {
  const [settingsWtErrors, setSettingsWtErrors] = useState<string[]>([])
  const errorsRef = useRef(settingsWtErrors)

  /* This ref is needed, because the current state of settingsWtErrors cannot be
  read from inside the functions which update it. Only the ref has the updated
  value. Heaven knows why.*/
  errorsRef.current = settingsWtErrors

  const setSettingError = (questionId: string) => {
    const index = errorsRef.current.findIndex((error: string) => error === questionId)
    if (index < 0) {
      setSettingsWtErrors((settingsWtErrors) => [...settingsWtErrors, questionId])
    }
  }

  const clearSettingError = (questionId: string) => {
    const newErrors = [...errorsRef.current]
    const index = newErrors.findIndex((error) => error === questionId)
    if (index > -1) {
      newErrors.splice(index, 1)
      setSettingsWtErrors(newErrors)
    }
  }

  return {
    settingsWtErrors,
    errorsRef,
    setSettingError,
    clearSettingError
  }
}

export function useReactToChanges(hasChanges: boolean) {
  const { uiStore } = useStore()
  const history = useHistory()
  let unblock: () => void | undefined
  useEffect(() => {
    if (hasChanges === true) {
      uiStore.setEmphasizeSaveBtn(true)
      unblock = history.block(uiStore.onLeaveText)
    } else {
      uiStore.setEmphasizeSaveBtn(false)
      if (unblock) {
        unblock()
      }
    }

    return () => {
      uiStore.setEmphasizeSaveBtn(false)
      if (unblock) {
        unblock()
      }
    }
  }, [hasChanges])
}
