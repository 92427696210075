import React, { ReactElement, ReactNode } from 'react'
import { createStyles, makeStyles, Theme, Box, Button, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'

import {
  AccordionDark,
  AccordionDetailsDark,
  AccordionSummaryDark
} from '../../styles/customizedAccordion'
import { useUtilStyles } from '../../styles/useUtilStyles'
import { observer } from 'mobx-react-lite'

interface ICMSModuleAccordionPanelProps {
  id: string
  title: string
  expanded: boolean
  panelDisabled: boolean
  onPanelClick: (panelName: string) => void
  onDeleteModule: () => void
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
  hasChanges?: boolean
}

/** observer-Component */
const CMSModuleAccordionPanel: React.FC<ICMSModuleAccordionPanelProps> = ({
  id,
  title,
  expanded,
  panelDisabled,
  onPanelClick,
  onDeleteModule,
  children,
  hasChanges
}: ICMSModuleAccordionPanelProps) => {
  const classes = useStyles({ hasChanges: typeof hasChanges === 'undefined' ? false : hasChanges })
  const classesUtil = useUtilStyles()

  function handlePanelClick() {
    if (id) {
      onPanelClick(id)
    }
  }

  function handleDelete(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    onDeleteModule()
  }

  return (
    <AccordionDark id={id} expanded={expanded} disabled={panelDisabled} onChange={handlePanelClick}>
      <AccordionSummaryDark
        id={`${id}-header`}
        aria-controls={`${id}-content`}
        expandIcon={panelDisabled ? null : <ExpandMoreIcon style={{ color: 'white' }} />}
        classes={{ root: classes.accordionSummaryRoot }}>
        <Box display='flex' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            <Typography variant='h5' component='h3' className={classesUtil.overflowWrap_breakWord}>
              {title}
            </Typography>
          </Box>

          <Box display='flex' alignItems='center'>
            <Button
              classes={{ root: classes.panelBtnRoot }}
              aria-label='Löschen'
              onClick={handleDelete}>
              <DeleteIcon style={{ color: 'white' }} />
            </Button>
          </Box>
        </Box>
      </AccordionSummaryDark>

      <AccordionDetailsDark>{children}</AccordionDetailsDark>
    </AccordionDark>
  )
}

interface IStyleProps {
  hasChanges: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionSummaryRoot: {
      backgroundColor: (props: IStyleProps) =>
        props.hasChanges ? theme.palette.primary.light : theme.palette.primary.main,
      '&.Mui-disabled': {
        backgroundColor: (props: IStyleProps) =>
          props.hasChanges ? theme.palette.primary.light : theme.palette.primary.main
      }
    },
    panelBtnRoot: {
      minWidth: '48px',
      [theme.breakpoints.up('sm')]: {
        minWidth: '64px'
      }
    }
  })
)

export default observer(CMSModuleAccordionPanel)
