import React from 'react'
import { observer } from 'mobx-react-lite'

import { useStore } from '../stores/StoreContext'
import SettingsAccordion from '../components/organisms/SettingsAccordion'
import SettingsPage from '../components/SettingsPage'
import { useReactToChanges } from '../utils/customHooks'

const EHSettings: React.FC = observer(() => {
  const settingsId = 'ehsettings'
  const { uiStore, formStore } = useStore()
  const hasChanges = formStore.settingHasChanges(formStore.EHSettingsData)

  useReactToChanges(hasChanges)

  function categoryHasChanges(categoryId: string) {
    return formStore.categoryHasChanges(categoryId, formStore.EHSettingsData)
  }

  return (
    <>
      <SettingsPage
        settings={formStore.EHSettings}
        settingsLoaded={formStore.EHSettingsLoaded}
        data={formStore.EHSettingsData}
        dataLoaded={formStore.EHSettingsDataLoaded}
        setDataFunc={formStore.setEHData}
        updateDataFnc={formStore.updateEHData}
        receiveDataFunc={formStore.receiveEHSettingsData}>
        {({ settings, getElement }) => (
          <>
            <SettingsAccordion
              expandedPanels={uiStore.expandedPanels}
              setExpandedPanel={uiStore.setExpandedPanel}
              settingsId={settingsId}
              settings={settings}
              getElement={getElement}
              categoryHasChanges={categoryHasChanges}
            />
          </>
        )}
      </SettingsPage>
    </>
  )
})

export default EHSettings
