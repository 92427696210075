import _ from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { IPage } from '../models/cmsInterfaces'
import { IEHContents, IShowInGraphicOptionalLayer, ISpecialProp } from '../models/ehInterfaces'
import Api from '../utils/api'
import { Logger } from '../utils/log'
import ApplicationStore from './ApplicationStore'

class EHStore {
  private rootStore: ApplicationStore
  public contentsData: IEHContents | undefined = undefined
  public contentsDataInitial: IEHContents | undefined = undefined
  public cmsData: IPage[] | undefined = undefined
  private contentsDataLoaded = false
  private cmsDataLoaded = false
  public placingActiveBtnColor = '#00c943'
  public selectedContent: string | undefined = undefined
  public specialProps: ISpecialProp[] = [
    {
      name: 'Energiespartipps',
      specialProp: {
        id: 'energySavingTipps',
        firstInMobileSlider: false,
        iconOptic: 'promotion',
        menuOptic: 'promotion',
        menuIcon: 'leaf'
      }
    },
    {
      name: 'Kein Spezial',
      specialProp: getEmptySpecialProp()
    }
  ]
  public showInGraphicAllOptionalLayers: IShowInGraphicOptionalLayer[] = [
    { name: 'trash', label: 'Mülleimer', id: 'Muelleimer' },
    { name: 'moveHouse', label: 'Umzugskartons', id: 'Umzug' },
    { name: 'bus', label: 'Bushaltestelle', id: 'Bushaltestelle' },
    {
      name: 'carsharing',
      label: 'Carsharing-Station',
      id: 'Carsharing_Station'
    },
    { name: 'emobility', label: 'E-Mobil', id: 'Elektromobilitaet' },
    { name: 'ac', label: 'Klimaanlage', id: 'AC' }
  ]

  public constructor(rootStore: ApplicationStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore

    if (typeof this.contentsData === 'undefined') {
      this.initContentsData()
    }
  }

  /* -------- Computeds -------- */
  public get isLoaded() {
    return this.contentsDataLoaded && this.cmsDataLoaded
  }

  public get contentsDataHasChanges() {
    return !_.isEqual(this.contentsData, this.contentsDataInitial)
  }

  /* -------- Initialisation -------- */
  private initContentsData = () => {
    let initObj = {
      customerId: 1,
      contents: [],
      meta: {}
    }

    initObj = ehInitMeta(initObj)
    this.contentsData = initObj
  }

  /* -------- Fetch and Save -------- */
  public receiveCmsData = async () => {
    runInAction(() => {
      this.cmsDataLoaded = false
    })
    try {
      const res = await Api.get('/cms/pages-for-configurator')
      runInAction(() => {
        this.cmsData = res.data
        this.cmsDataLoaded = true
      })
      Logger.log('received cms pages for configurator', res.data)
    } catch (error) {
      runInAction(() => {
        this.cmsDataLoaded = true
        this.rootStore.uiStore.setSnackbarError('CMS Daten konnten nicht geladen werden.')
      })
    }
  }

  public receiveContentsData = async () => {
    try {
      runInAction(() => {
        this.contentsDataLoaded = false
      })
      const res = await Api.get('/api/allcontents')
      runInAction(() => {
        this.contentsData = res.data
        this.contentsDataInitial = res.data
        this.contentsDataLoaded = true
      })
      Logger.log('received contents', res.data)
    } catch (error) {
      runInAction(() => {
        this.contentsDataLoaded = true
        this.rootStore.uiStore.setSnackbarError('Inhalte konnten nicht geladen werden.')
      })
    }
  }

  public updateContent = async () => {
    Logger.log('updateContent', toJS(this.contentsData))
    try {
      runInAction(() => {
        this.rootStore.uiStore.setSavingDisabled(true)
      })

      const res = await Api.postJson('/api/updateContents', toJS(this.contentsData))
      Logger.log('updateContents response', res.data)

      runInAction(() => {
        this.rootStore.uiStore.setSnackbarSuccess('Die Änderungen wurden gespeichert.')
        this.rootStore.uiStore.setSavingDisabled(false)
      })
    } catch (error) {
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarError('Fehler beim Speichern.')
        this.rootStore.uiStore.setSavingDisabled(false)
      })
    }
  }

  /* -------- Display Options -------- */
  public setShowInGraphic = (propName: string, show: 'y' | 'n') => {
    if (this.contentsData?.meta?.showInGraphic) {
      this.contentsData.meta.showInGraphic[propName] = show
    } else {
      console.error(
        "Could not set layer visibility. Either the 'meta' property or the contentsData object itself is not initialized."
      )
    }
  }

  public setShowHoverAreaContents = (showHoverContents: boolean) => {
    if (this.contentsData?.meta) {
      this.contentsData.meta.showHoverAreaContents = showHoverContents
    } else {
      console.error(
        "Could not enable/disable hover area contents . Either the 'meta' property or the contentsData object itself is not initialized."
      )
    }
  }

  public setSelectedContent = (contentId: string | undefined) => {
    this.selectedContent = contentId
  }

  /* -------- Contents -------- */
  public addContentWithCMSPage = (cmsPageId: string): string => {
    if (!this.contentsData) {
      this.initContentsData()
    }
    const cmsPage = this.cmsData?.find((page: IPage) => page._id === cmsPageId)

    if (!cmsPage) {
      console.error('Could not find cmsPage.')
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarError('Seite wurde nicht gefunden.')
      })
      return ''
    }

    const o = (this.contentsData as IEHContents)?.contents?.length

    const newContent = {
      id: Math.random().toString(36).substr(2, 9),
      order: o,
      title: cmsPage.meta.nameIntern,
      cmsPage: {
        name: cmsPage.meta.nameIntern,
        _id: cmsPage._id
      },
      specialProp: {
        id: 'none'
      },
      graphic: {
        showSpecialSVGElementIdOnHoverIcon: 'none',
        icon: {
          name: ''
        },
        position: {
          percent: {
            x: 10,
            y: 10
          }
        }
      }
    }

    if (this.contentsData) {
      this.contentsData.contents.push(newContent)
    }

    return newContent.id
  }

  public setCMSPageInContent = (contentId: string, cmsPageId: string) => {
    const content = this.contentsData?.contents?.find((item) => item.id === contentId)
    const cmsPage = this.cmsData?.find((page: IPage) => page._id === cmsPageId)
    if (content) {
      if (cmsPage) {
        /* If the content's title comes from it's cms-page (which is the default), set the
        title to the name of the new cms-page. Otherweise, the user has probably chosen a 
        custom title*/
        const oldCMSPage = content.cmsPage
        if (content.title === oldCMSPage.nameIntern) {
          content.title = cmsPage.meta.nameIntern
        }

        /* set cms-page */
        content.cmsPage = {
          nameIntern: cmsPage.meta.nameIntern,
          _id: cmsPage._id
        }
      } else {
        console.error('Could not set cms page. Page not found')
      }
    } else {
      console.error('Could update content. Content not found')
    }
  }

  public setIconInContent = (contentId: string, iconName: string) => {
    const content = this.contentsData?.contents?.find((item) => item.id === contentId)
    if (content) {
      content.graphic.icon.name = iconName
    } else {
      console.error('Could update content. Content not found')
    }
  }

  public setHoverContentInContent = (contentId: string, specialContentId: string) => {
    const content = this.contentsData?.contents?.find((item) => item.id === contentId)
    if (content) {
      content.graphic.showSpecialSVGElementIdOnHoverIcon = specialContentId
    } else {
      console.error('Could update content. Content not found')
    }
  }

  public setContentTitle = (contentId: string, title: string) => {
    const content = this.contentsData?.contents?.find((item) => item.id === contentId)
    if (content) {
      content.title = title
    } else {
      console.error('Could update content. Content not found')
    }
  }

  public setContentPageUrl = (contentId: string, url: string) => {
    const content = this.contentsData?.contents?.find((item) => item.id === contentId)
    if (content) {
      content.customerPage = url
    } else {
      console.error('Could update content. Content not found')
    }
  }

  // TODO: Old app does something different
  public setSpecialPropInContent = (contentId: string, specialPropId: string) => {
    const content = this.contentsData?.contents?.find((item) => item.id === contentId)
    const newSpecialProp = this.specialProps.find(
      (prop: ISpecialProp) => prop.specialProp.id === specialPropId
    )
    if (content) {
      if (newSpecialProp) {
        content.specialProp = newSpecialProp.specialProp
      }
    } else {
      console.error('Could update content. Content not found')
    }
  }

  public setGraphicsPositionInContent = (contentId: string, xpos: number, ypos: number) => {
    Logger.log('xpos', xpos, 'ypos', ypos)
    const content = this.contentsData?.contents?.find((item) => item.id === contentId)
    if (content) {
      content.graphic.position.percent = { x: xpos, y: ypos }
    } else {
      console.error('Could update content. Content not found')
    }
  }

  public deleteContent = (contentId: string) => {
    const index = this.contentsData?.contents?.findIndex((item) => item.id === contentId)
    if (index === undefined || index < 0 || !this.contentsData) {
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarError('Could not delete content. Index not found.')
      })
      console.error('Could not delete content. Index not found.')
    } else {
      this.contentsData.contents.splice(index, 1)
    }
  }

  // Check if a content's cms page is in our pages array
  public contentHasCmsPage = (contentId: string) => {
    const content = this.contentsData?.contents?.find((content) => content.id === contentId)

    if (!content?.cmsPage?._id) {
      return false
    }

    if (this.cmsData?.find((page) => page._id === content.cmsPage._id)) {
      return true
    }

    return false
  }
}

function ehInitMeta(initObj: any) {
  if (!initObj.meta || !initObj.meta.showInGraphic || !initObj.meta.showInGraphic.bus) {
    initObj.meta.showInGraphic = {
      trash: 'y',
      moveHouse: 'y',
      bus: 'y',
      carsharing: 'y',
      emobility: 'y',
      ac: 'y'
    }
    initObj.meta.showHoverAreaContents = true
  }
  return initObj
}

function getEmptySpecialProp() {
  return {
    id: 'none',
    iconOptic: '',
    menuOptic: '',
    menuIcon: '',
    firstInMobileSlider: ''
  }
}

export default EHStore
