import React from 'react'
import { observer } from 'mobx-react-lite'

import { useStore } from '../stores/StoreContext'
import SettingsAccordion from '../components/organisms/SettingsAccordion'
import SettingsPage from '../components/SettingsPage'
import { useReactToChanges } from '../utils/customHooks'

const WCEmail: React.FC = observer(() => {
  const settingsId = 'wcemailsettings'
  const { uiStore, formStore } = useStore()

  const hasChanges = formStore.settingHasChanges(formStore.WCEmailSettingsData)

  useReactToChanges(hasChanges)

  function categoryHasChanges(categoryId: string) {
    return formStore.categoryHasChanges(categoryId, formStore.WCEmailSettingsData)
  }

  return (
    <SettingsPage
      settings={formStore.WCEmailSettings}
      settingsLoaded={formStore.WCEmailSettingsLoaded}
      data={formStore.WCEmailSettingsData}
      dataLoaded={formStore.WCEmailSettingsDataLoaded}
      setDataFunc={formStore.setWCEmailData}
      updateDataFnc={formStore.updateWCEmailData}
      receiveDataFunc={formStore.receiveWCEmailSettingsData}>
      {({ settings, getElement }) => (
        <SettingsAccordion
          expandedPanels={uiStore.expandedPanels}
          setExpandedPanel={uiStore.setExpandedPanel}
          settingsId={settingsId}
          settings={settings}
          getElement={getElement}
          categoryHasChanges={categoryHasChanges}
        />
      )}
    </SettingsPage>
  )
})

export default WCEmail
