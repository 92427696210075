import { makeAutoObservable, runInAction } from 'mobx'
import Api from '../utils/api'
import { Logger } from '../utils/log'
import ApplicationStore from './ApplicationStore'

class HiddenStore {
  private rootStore: ApplicationStore
  public isLoading: boolean = false
  public downloadUrl: string | undefined = undefined
  public downloadUrlDbOnly: string | undefined = undefined
  private backupTimeoutAfter: number = 180000
  private cleanupTimeoutAfter: number = 6000
  public constructor(rootStore: ApplicationStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public restoreBackup = async (data: any, type: 'configurator' | 'cms' | 'db') => {
    this.isLoading = true
    let apiUrl: string
    switch (type) {
      case 'configurator':
        apiUrl = '/restoreConfiguratorBackup'
        break
      case 'cms':
        apiUrl = '/restoreCMSBackup'
        break
      default:
        apiUrl = '/restoreDBBackup'
    }

    const formData = new FormData()
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key])
      }
    }

    try {
      runInAction(() => {
        this.rootStore.uiStore.setSavingDisabled(true)
      })
      const res = await Api.postMultipart(apiUrl, formData, { timeout: this.backupTimeoutAfter })
      Logger.log('restoreBackup response', res)
      runInAction(() => {
        this.isLoading = false
        this.rootStore.uiStore.setSnackbarSuccess('Upload erfolgreich.')
        this.rootStore.uiStore.setSavingDisabled(false)
        this.rootStore.fileStore.getFileList()
      })
    } catch (error) {
      runInAction(() => {
        this.isLoading = false
        this.rootStore.uiStore.setSnackbarError('Fehler beim Hochladen.')
        this.rootStore.uiStore.setSavingDisabled(false)
        this.rootStore.fileStore.getFileList()
      })
    }
  }

  public createBackup = async (type: 'configurator' | 'cms' | 'all', dbOnly: boolean) => {
    let apiUrl: string
    switch (type) {
      case 'configurator':
        apiUrl = dbOnly ? '/createConfiguratorbackupDbOnly' : '/createConfiguratorbackup'
        break
      case 'cms':
        apiUrl = dbOnly ? '/createCMSbackupDbOnly' : '/createCMSbackup'
        break
      default:
        apiUrl = dbOnly ? '/createDBbackupDbOnly' : '/createDBbackup'
    }
    this.isLoading = true
    try {
      runInAction(() => {
        this.rootStore.uiStore.setSavingDisabled(true)
      })
      const res = await Api.get(apiUrl, { timeout: this.backupTimeoutAfter })
      Logger.log('createBackup response', res)
      runInAction(() => {
        this.isLoading = false
        this.downloadUrl = !dbOnly && res.data ? res.data : this.downloadUrl
        this.downloadUrlDbOnly = dbOnly && res.data ? res.data : this.downloadUrlDbOnly
        this.rootStore.uiStore.setSnackbarSuccess('Backup wurde erstellt.')
        this.rootStore.uiStore.setSavingDisabled(false)
      })
    } catch (error) {
      runInAction(() => {
        this.isLoading = false
        this.rootStore.uiStore.setSnackbarError('Fehler beim Erstellen des Backups.')
        this.rootStore.uiStore.setSavingDisabled(false)
      })
    }
  }

  public cleanup = async () => {
    try {
      const res = await Api.delete('/cleanup', { timeout: this.cleanupTimeoutAfter })
      Logger.log(res.data)
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarSuccess('Alte Backup Daten wurden gelöscht.')
      })
    } catch (error) {
      this.rootStore.uiStore.setSnackbarError('Backup Daten konnten nicht gelöscht werden.')
      Logger.log('Backup Daten konnten nicht gelöscht werden', error)
    }
  }

  public deleteUploads = async () => {
    try {
      const res = await Api.delete('/deleteUploads', { timeout: this.cleanupTimeoutAfter })
      Logger.log(res.data)
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarSuccess('Uploads wurden gelöscht.')
        this.rootStore.fileStore.getFileList()
      })
    } catch (error) {
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarError('Uploads konnten nicht gelöscht werden.')
        this.rootStore.fileStore.getFileList()
      })
      Logger.log('Uploads konnten nicht gelöscht werden', error)
    }
  }

  public regenerateThumbnails = async () => {
    try {
      const res = await Api.putJson('/files/generateThumbnails', { data: 'foo' })
      Logger.log(res.data)
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarSuccess('Thumbnails wurden erstellt.')
        this.rootStore.fileStore.getFileList()
      })
    } catch (error) {
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarError('Fehler beim Erstellen von Thumbails.')
        this.rootStore.fileStore.getFileList()
      })
      Logger.log('Fehler beim Erstellen von Thumbails')
    }
  }

  public resetAdmin = async () => {
    try {
      await Api.get('/resetAdmin')
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarSuccess('Das Admin Passwort wurde zurück gesetzt.')
      })
    } catch (error) {
      runInAction(() => {
        this.isLoading = false
        this.rootStore.uiStore.setSnackbarError('Fehler beim Zurücksetzen des Admin Passworts.')
      })
    }
  }

  public resetSupport = async () => {
    try {
      await Api.get('/resetSupport')
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarSuccess('Das Support Passwort wurde zurück gesetzt.')
      })
    } catch (error) {
      runInAction(() => {
        this.isLoading = false
        this.rootStore.uiStore.setSnackbarError('Fehler beim Zurücksetzen des Support Passworts.')
      })
    }
  }

  public dropConfigCollections = async () => {
    try {
      const res = await Api.delete('/configDB', { timeout: this.cleanupTimeoutAfter })
      Logger.log(res.data)
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarSuccess('Konfigurator Settings wurden gelöscht.')
      })
    } catch (error) {
      this.rootStore.uiStore.setSnackbarError(
        'Konfigurator Settings konnten nicht gelöscht werden.'
      )
      Logger.log('Konfigurator Settings konnten nicht gelöscht werden', error)
    }
  }

  public dropCMSCollections = async () => {
    try {
      const res = await Api.delete('/cmsDB', { timeout: this.cleanupTimeoutAfter })
      Logger.log(res.data)
      runInAction(() => {
        this.rootStore.uiStore.setSnackbarSuccess('CMS Settings wurden gelöscht.')
      })
    } catch (error) {
      this.rootStore.uiStore.setSnackbarError('CMS Settings konnten nicht gelöscht werden.')
      Logger.log('CMS Settings konnten nicht gelöscht werden', error)
    }
  }
}

export default HiddenStore
