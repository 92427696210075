import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Theme, Typography } from '@material-ui/core'
import { toJS } from 'mobx'
import DOMPurify from 'dompurify'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import '../../i11n/tinymce-react/de.js'
import { Editor, IAllProps } from '@tinymce/tinymce-react'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'

import { ITextInputElement } from '../../models/settingsInterfaces'
import SimpleInputBase from './SimpleInputBase'

interface IValues {
  question: string
  questionLong?: string
  order?: string | number
}

interface ITextElementProps extends IAllProps {
  element: ITextInputElement
  fieldKey: string
  baseId?: string
  values?: IValues
  updateData?: (key: string, data: any) => void
  saveObj?: boolean
}

const dompurifyOptions = {
  ADD_ATTR: ['target']
}

/** observer-Component */
/**
 *
 * @param {Object} element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param {string} fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used as an id for the text field.
 * @param {string} baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param {(Object)} values - The data that can be edited in this input. An object
 * with a 'question' property and an optional 'questionLong' property. The latter
 * is probably never used and only included to stay consistent with an older version
 * of this app.
 * @param {function} updateData - 'OnChange' callback for updating data.
 * @param {boolean} saveObj - If false, data will be stored 'as is', otherweise it
 * will be wrapped in an object (needed for most of the configurator settings)
 *
 * Additionally, this component accepts all attributes that can be used with
 * the 'tinymce-react' component (https://www.tiny.cloud/docs/integrations/react/#tinymcereacttechnicalreference)
 */
const RichtextElement: React.FC<ITextElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  saveObj = true, // For CISettings, data is not stored in a question-object
  updateData,
  ...restProps
}: ITextElementProps) => {
  const theme = useTheme()
  const classes = useStyles()
  const question = values?.question || ''
  /* Probably not needed, but included here to keep data consistent with with an older 
  version of this app. */
  const order = values?.order || undefined

  if (!baseId) {
    baseId = fieldKey
  }

  const [contentEditor, setContentEditor] = useState<string>(question)

  const handleEditorChange = (content: string) => {
    const html = DOMPurify.sanitize(content, dompurifyOptions)
    setContentEditor(html)

    const hasChanges = html !== toJS(question)
    if (updateData && hasChanges) {
      /* For CISettings, data is stored directly, for all other settings, it
    is wrapped in an object */
      if (!saveObj) {
        updateData(fieldKey, html)
      } else {
        const saveMe: {
          question: string
          order?: string | number | undefined
        } = { question: html }

        if (order) {
          saveMe.order = order
        }
        updateData(fieldKey, saveMe)
      }
    }
  }

  return (
    <SimpleInputBase
      fullWidth={element.fullWidth || false}
      firstRowTitle={element.title || 'Frage'}
      firstRowId={`input_${baseId}`}
      toolTip={element.toolTip}
      firstRowChildren={
        <>
          <Editor
            init={{
              height: 500,
              menubar: false,
              language: 'de',
              plugins: ['lists link wordcount paste'],
              fontsize_formats: '10pt 12pt 14pt 16pt 18pt',
              toolbar: [
                'formatselect | fontsizeselect | link | undo redo ',
                'bold italic underline | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat'
              ],
              content_style: `body { font-family: ${theme.typography.fontFamily}; }`,
              paste_as_text: true,
              decodeEntities: false,
              link_title: false,
              target_list: false,
              default_link_target: '_blank'
            }}
            value={contentEditor}
            onEditorChange={handleEditorChange}
            {...restProps}
          />
          {element.description && (
            <Typography
              variant='caption'
              component='p'
              id={`description_${baseId}`}
              className={classes.caption}>
              {element.description}
            </Typography>
          )}
        </>
      }
    />
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    caption: {
      marginBlockStart: '3px',
      color: theme.palette.text.secondary
    }
  })
)

export default observer(RichtextElement)
