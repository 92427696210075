import { AppBar, Tab, Tabs } from '@material-ui/core'
import React from 'react'
import { useSettings } from '../context/SettingsContext'
import { defaultProps } from '../utils/defaultProps'
import FileLibrary from './FileLibrary'
import FileUpload from './FileUpload'

interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
}

const ReactMediaLibraryTabs: React.FC = () => {
  const { settings } = useSettings()
  const {
    fileLibraryList,
    defaultSelectedTab = defaultProps.defaultSelectedTab,
    uploadTabText = defaultProps.uploadTabText,
    libraryTabText = defaultProps.libraryTabText,
    showUploadTab = defaultProps.showUploadTab
  } = settings

  const [selectedTab, setSelectedTab] = React.useState(
    !(Array.isArray(fileLibraryList) && fileLibraryList.length > 0) ? 'upload' : defaultSelectedTab
  )

  function handleChange(event: React.ChangeEvent<unknown>, newValue: 'upload' | 'library') {
    setSelectedTab(newValue)
  }

  return (
    <>
      <AppBar position='static' color='primary'>
        <Tabs value={selectedTab} onChange={handleChange} aria-label='Select Tab'>
          {showUploadTab && (
            <Tab
              value='upload'
              label={uploadTabText}
              id='tab-upload'
              aria-controls='tabpanel-upload'
            />
          )}
          <Tab
            value='library'
            label={libraryTabText}
            id='tab-library'
            aria-controls='tabpanel-library'
          />
        </Tabs>
      </AppBar>
      {showUploadTab && (
        <TabPanel value={selectedTab} index='upload'>
          <FileUpload />
        </TabPanel>
      )}
      {Array.isArray(fileLibraryList) && fileLibraryList.length > 0 && (
        <TabPanel value={selectedTab} index='library'>
          <FileLibrary />
        </TabPanel>
      )}
    </>
  )
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}>
    {value === index && <>{children}</>}
  </div>
)

export default ReactMediaLibraryTabs
