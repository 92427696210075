import React, { useEffect, useState } from 'react'
import { action, runInAction } from 'mobx'
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Button,
  Dialog,
  Link,
  Typography,
  DialogContent,
  withStyles,
  WithStyles,
  IconButton
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

import PageTitle from '../components/atoms/PageTitle'
import { useStore } from '../stores/StoreContext'
import { observer } from 'mobx-react-lite'
import { IPage, ITemplate } from '../models/cmsInterfaces'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import CMSContentCard from '../components/organisms/CMSContentCard'
import { useUtilStyles } from '../styles/useUtilStyles'
import cmsStrings from '../utils/cmsStrings'
import CMSTemplateNotFound from '../components/fallbacks/CMSTemplateNotFound'
import CMSPagesSkeleton from '../components/fallbacks/CMSPagesSkeleton'
import PageSubtitle from '../components/atoms/PageSubtitle'

export interface ICMSPagesPageProps extends RouteComponentProps<{ id: string }> {}

/** observer-Component */
const CMSPagesPage: React.FC<ICMSPagesPageProps> = ({ match }: ICMSPagesPageProps) => {
  const classes = useStyles()
  const classesUtil = useUtilStyles()
  const { cmsStore, uiStore } = useStore()
  const history = useHistory()
  const templateId = match.params.id
  const template: ITemplate | undefined = cmsStore.getTemplateByIdName(templateId)

  const productName = cmsStrings[templateId]?.plural
  const productNameLong = cmsStrings[templateId]?.longPlural
  const description = cmsStrings[templateId]?.description
  const descriptionParagraphs = description ? description.split('\n') : []

  const [descriptionOpen, setDescriptionOpen] = useState(false)

  useEffect(() => {
    const initData = action(async () => {
      await cmsStore.getPagesByTemplateId(templateId)
    })
    initData()
  }, [templateId])

  async function handleAddClick() {
    runInAction(async () => {
      if (template) {
        cmsStore.setTemplateChoosenForAdding(template)
        const page: IPage = await cmsStore.createPage(template.meta.global.id)
        await cmsStore.getPagesByTemplateId(templateId)

        if (page) {
          history.push(`/cmsPage/${page._id}`)
        }
      } else {
        console.error('Could not add page. No template found.')
        runInAction(() => {
          uiStore.setSnackbarError('Seite konnte nicht erstellt werden.')
        })
      }
    })
  }

  function handlePageDeleteRequest(pageId: string) {
    uiStore.openConfirmDialog({
      title: 'Seite löschen?',
      extraDanger: true,
      description: 'Bitte bestätigen Sie. Die Seite und ihre Inhalte werden dann SOFORT gelöscht.',
      onConfirm: action(async () => {
        await cmsStore.deletePage(pageId)
        await cmsStore.getPagesByTemplateId(templateId)
      })
    })
  }

  function openDescription() {
    setDescriptionOpen(true)
  }

  function handleCloseDescription() {
    setDescriptionOpen(false)
  }

  return (
    <>
      {template ? (
        <>
          <PageTitle>{productName}</PageTitle>
          <PageSubtitle>{uiStore.pleaseSaveYourSettingsText}</PageSubtitle>

          <Box my={3} px={0.5}>
            <Typography variant='body1' className={classes.paragraph}>
              {`Hier können Sie ${productNameLong} erstellen und bearbeiten. `}

              {!!descriptionParagraphs.length && (
                <Link
                  component='button'
                  variant='body1'
                  onClick={openDescription}
                  classes={{ root: classes.descriptionBtnRoot }}>
                  Erklärung anzeigen.
                </Link>
                // <Button variant='outlined' size='small' onClick={openDescription} classes={{root: classes.descriptionBtnRoot}}>
                //   Erklärung anzeigen
                // </Button>
              )}
            </Typography>
            {/* <Typography variant="body1">
              Bitte klicken Sie auf{' '}
              <span className={classes.highlightedText}>
                <Hidden xsDown>Änderungen</Hidden> Speichern
              </span>{' '}
              (oben rechts) um neu erstellte Inhalte zu speichern.
            </Typography> */}

            {!!descriptionParagraphs.length && (
              <Dialog
                onClose={handleCloseDescription}
                aria-labelledby='simple-dialog-title'
                open={descriptionOpen}>
                <DialogTitle id='description-dialog-title' onClose={handleCloseDescription}>
                  {productNameLong}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogRoot }}>
                  {descriptionParagraphs?.map((paragraph: string, index: number) => (
                    <Typography key={index} variant='body1' className={classesUtil.mb_2}>
                      {paragraph}
                    </Typography>
                  ))}
                </DialogContent>
              </Dialog>
            )}
          </Box>

          <Box>
            <Button
              aria-label='Hinzufügen'
              variant='contained'
              color='primary'
              startIcon={<AddIcon />}
              onClick={handleAddClick}>
              Hinzufügen
            </Button>
          </Box>
          {cmsStore.pagesListByTemplateId[templateId]?.loaded ? (
            cmsStore.pagesListByTemplateId[templateId]?.list?.map((page: IPage) => (
              <CMSContentCard
                key={page._id}
                pageTitle={page.meta?.nameIntern || ''}
                pageId={page._id}
                onDelete={handlePageDeleteRequest}
              />
            ))
          ) : (
            <CMSPagesSkeleton />
          )}
        </>
      ) : (
        <CMSTemplateNotFound />
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionBtnRoot: {
      display: 'inline',
      color: theme.palette.secondary.dark
      // marginBottom: theme.spacing(2)
    },
    dialogRoot: {
      padding: theme.spacing(3)
    },
    highlightedText: {
      textTransform: 'uppercase'
      // fontWeight: 'bold'
    },
    paragraph: {
      marginBottom: theme.spacing(1)
    }
  })
)

const customTitlestyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.custom.defaultDarkColor
    }
  })

export interface IDialogTitleProps extends WithStyles<typeof customTitlestyles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(customTitlestyles)((props: IDialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export default observer(CMSPagesPage)
