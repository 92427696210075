import React, { ReactElement, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd'

import { IQuestion } from '../../../models/questionsInterfaces'
import QuestionsAccordionPanel from './QuestionsAccordionPanel'
import { IPanelOpenSetting } from '../../../stores/UIStore'
import { Logger } from '../../../utils/log'

interface IQuestionAccordionProps {
  questions: IQuestion[]
  questionTitleMaxLength: number
  expandedPanels: IPanelOpenSetting
  setExpandedPanel: (isExpanded: boolean, panelId: string) => void
  questionsWtErrors?: string[]
  onDelete(id: string): void
  onMove(id: string, oldIndex: number, newIndex: number): void
  onMoveUpOrDown(q: IQuestion, up: boolean): void
  getElement(
    element: IQuestion,
    key: string
  ): ReactElement | ReactElement[] | ReactNode | ReactNode[]
  getQuestionHasChanges?: (id: string) => boolean
}

/** observer-Component */
const QuestionsAccordion: React.FC<IQuestionAccordionProps> = ({
  questions,
  questionTitleMaxLength,
  expandedPanels,
  setExpandedPanel,
  questionsWtErrors,
  onDelete,
  onMove,
  onMoveUpOrDown,
  getElement,
  getQuestionHasChanges
}: IQuestionAccordionProps) => {
  // TODO: This is necessary otherwise component isn't rerendered when new questions are added.
  const l = questions.length
  if (process.env.NODE_ENV === 'test') {
    Logger.log(l)
  }

  function onDragEnd(result: DropResult) {
    const { destination, source } = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    if (result.destination?.index) {
      onMove(result.draggableId, result.source.index, result.destination.index)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='questions'>
        {(provided: DroppableProvided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {questions &&
              questions.map((questionItem: IQuestion) => (
                <QuestionsAccordionPanel
                  key={questionItem.id}
                  questionItem={questionItem}
                  questionTitleMaxLength={questionTitleMaxLength}
                  expandedPanels={expandedPanels}
                  setExpandedPanel={setExpandedPanel}
                  errors={questionsWtErrors}
                  onDelete={onDelete}
                  onMoveUpOrDown={onMoveUpOrDown}
                  getElement={getElement}
                  getQuestionHasChanges={getQuestionHasChanges}
                />
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default observer(QuestionsAccordion)
