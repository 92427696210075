import React, { ReactElement, ReactNode, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useUtilStyles } from '../../../styles/useUtilStyles'
import { ICategory, IInputElement } from '../../../models/settingsInterfaces'
import { Accordion, AccordionDetails, AccordionSummary } from '../../../styles/customizedAccordion'
import { IPanelOpenSetting } from '../../../stores/UIStore'

interface SettingsAccordionPanelProps {
  settingsId: string
  category: ICategory
  panelDisabled?: boolean
  expandedPanels: IPanelOpenSetting
  setExpandedPanel: (settingsId: string, categoryId: string | false) => void
  getElement: (
    element: IInputElement,
    key: string
  ) => ReactElement | ReactElement[] | ReactNode | ReactNode[]
  categoryHasChanges?: (categoryId: string) => boolean
}

/** observer-Component */
const SettingsAccordionPanel: React.FC<SettingsAccordionPanelProps> = ({
  settingsId,
  category,
  panelDisabled = false,
  expandedPanels,
  setExpandedPanel,
  getElement,
  categoryHasChanges
}: SettingsAccordionPanelProps) => {
  const hasSubcategories = !!category.subcategories
  const hasChanges = hasSubcategories
    ? false
    : !!categoryHasChanges
    ? categoryHasChanges(category.id)
    : false

  const classes = useStyles({ hasChanges })
  const classesUtil = useUtilStyles()

  // Open panel if 'panelDisabled' is true
  useEffect(() => {
    if (panelDisabled) {
      setExpandedPanel(settingsId, category.id)
    }
  }, [])

  const handleCollapseChange =
    (panelId: string) => (_event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedPanel(settingsId, isExpanded ? panelId : false)
    }

  return (
    <>
      {/* Panel without subcategories */}
      {!hasSubcategories && (
        <Accordion
          expanded={expandedPanels[settingsId] === category.id}
          disabled={panelDisabled}
          onChange={handleCollapseChange(category.id)}>
          <AccordionSummary
            id={`${category.id}-header`}
            aria-controls={category.id}
            expandIcon={panelDisabled ? null : <ExpandMoreIcon />}
            classes={{ root: classes.accordionSummaryRoot }}>
            <Typography
              variant={category.type === 'subcategory' ? 'body1' : 'h5'}
              component={category.type === 'subcategory' ? 'h3' : 'h2'}
              className={clsx(classesUtil.overflowWrap_breakWord, {
                [classes.accordionSubCatTitle]: category.type === 'subcategory'
              })}>
              {category.title}
            </Typography>
            <Typography variant='body2' component='p'>
              {category.description}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            {category.elements &&
              category.elements.map((element: IInputElement) => {
                const key = `${element.id || ''}${element.postfix || ''}`
                return getElement(element, key)
              })}
          </AccordionDetails>
        </Accordion>
      )}

      {/* Panel with subcategories */}
      {hasSubcategories && (
        <Accordion disabled>
          <AccordionSummary id={`${category.id}-header`}>
            <Typography variant='h5' component='h2' className={classesUtil.overflowWrap_breakWord}>
              {category.title}
            </Typography>
          </AccordionSummary>
        </Accordion>
      )}
    </>
  )
}

interface IStyleProps {
  hasChanges: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionSubCatTitle: {
      marginLeft: theme.spacing(2)
    },
    accordionSummaryRoot: {
      backgroundColor: (props: IStyleProps) =>
        props.hasChanges ? theme.custom.highlightBackgroundColor : 'initial',
      '&.Mui-disabled': {
        backgroundColor: (props: IStyleProps) =>
          props.hasChanges ? theme.custom.highlightBackgroundColor : 'initial'
      }
    }
  })
)

export default observer(SettingsAccordionPanel)
