import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
// import { useStore } from '../../stores/StoreContext'
import { observer } from 'mobx-react-lite'

interface IDialogTitleProps {
  id: string
  children: React.ReactNode
}

/* Custom dialog title*/
const DialogTitle = (props: IDialogTitleProps) => {
  const { children, ...other } = props
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant='h4'>{children}</Typography>
    </MuiDialogTitle>
  )
}

interface IAddPagePopupProps {
  templateId: string
  triggerCallback?: () => unknown
  onCreatePage?: (templateId: string, pageName: string) => Promise<unknown>
  // onCreatePage?: (newPage: any) => unknown
}

/** observer-Component */
/**
 * Dialog that enables user to create a new follow-up page ('Folgeseite').
 * Currently, the trigger component (that opens the dialog when clicked) is
 * a MenuItem. TODO: Allow to pass trigger-component as parameter.
 * @param templateId - Id of the template the new page is created from
 * @param triggerCallback - Function that gets called when the Element that
 * opens the dialog is clicked. Can e.g. be used to close a dropdown menu that
 * contains the trigger
 * @param onCreatePage - Callback that is executed once a new page is created.
 * Gets handed over the new page as a parameter.
 */
const AddPagePopup: React.FC<IAddPagePopupProps> = (
  { templateId, triggerCallback, onCreatePage }: IAddPagePopupProps,
  ref: React.Ref<HTMLLIElement>
) => {
  const [open, setOpen] = useState(false)
  // const { cmsStore } = useStore()
  const [pageName, setPageName] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function handleTriggerClick() {
    handleOpen()

    if (triggerCallback) {
      triggerCallback()
    }
  }

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPageName(event.target.value)
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setError(undefined)

    if (!pageName) {
      setError('Bitte geben Sie einen Namen ein.')
      return
    }

    handleClose()
    if (onCreatePage) {
      await onCreatePage(templateId, pageName)
    }
  }

  return (
    <>
      <MenuItem key='imlast' onClick={handleTriggerClick} ref={ref}>
        <ListItemIcon>
          <AddIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>Neue Seite erstellen</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        aria-labelledby='addPage-dialog-title'>
        <DialogTitle id='addPage-dialog-title'>Neue Folgeseite erstellen</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label='Name der Folgeseite'
                  InputProps={{
                    inputProps: {
                      maxLength: 2000
                    }
                  }}
                  id='pageName'
                  name='pageName'
                  value={pageName}
                  variant='outlined'
                  fullWidth
                  size='small'
                  onChange={handleNameChange}
                  error={Boolean(error)}
                  helperText={error || null}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type='submit' variant='contained' color='primary' startIcon={<AddIcon />}>
                  Erstellen
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default observer(AddPagePopup, { forwardRef: true })
