import React from 'react'
import { Box, Typography } from '@material-ui/core'

interface IBasicFallbackProps {
  title?: string
  message?: string
}

const BasicFallback: React.FC<IBasicFallbackProps> = ({ title, message }: IBasicFallbackProps) => (
  <Box p={4}>
    {title && (
      <Typography gutterBottom variant='h3' component='h1'>
        {title}
      </Typography>
    )}
    {message && (
      <Typography gutterBottom variant='body1' component='p'>
        {message}
      </Typography>
    )}
  </Box>
)

export default BasicFallback
