import React from 'react'
import { Box, createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

interface IEHSkeletonProps {
  showEHGraphicBreakpoint: Breakpoint
}

const EHSkeleton: React.FC<IEHSkeletonProps> = ({ showEHGraphicBreakpoint }: IEHSkeletonProps) => {
  const classes = useStyles({ showEHGraphicBreakpoint })

  // Energy house graphic is only shown on desktop
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(showEHGraphicBreakpoint))

  return (
    <Box display='flex' className={classes.container}>
      <Skeleton variant='rect' height={isDesktop ? 480 : 160} className={classes.rectLarge} />

      <Skeleton variant='rect' height={220} className={classes.rectSmall} />
    </Box>
  )
}

interface IStyleProps {
  showEHGraphicBreakpoint: Breakpoint | undefined
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: IStyleProps) => ({
      flexDirection: 'column',
      [theme.breakpoints.up(props.showEHGraphicBreakpoint || 'md')]: {
        flexDirection: 'row'
      }
    }),
    rectLarge: (props: IStyleProps) => ({
      flex: '0 1 auto',
      marginBottom: theme.spacing(2),
      marginRight: 0,
      [theme.breakpoints.up(props.showEHGraphicBreakpoint || 'md')]: {
        flex: '1 1 60%',
        marginBottom: 0,
        marginRight: theme.spacing(2)
      }
    }),
    rectSmall: (props: IStyleProps) => ({
      flex: '0 1 auto',

      [theme.breakpoints.up(props.showEHGraphicBreakpoint || 'md')]: {
        flex: '1 1 40%'
      }
    })
  })
)

export default EHSkeleton
