import React from 'react'
import { observer } from 'mobx-react-lite'

import { useStore } from '../stores/StoreContext'
import SettingsAccordion from '../components/organisms/SettingsAccordion'
import SettingsPage from '../components/SettingsPage'
import { useReactToChanges } from '../utils/customHooks'

const PVWelcome: React.FC = observer(() => {
  const settingsId = 'pvwelcomesettings'
  const { uiStore, formStore } = useStore()
  const hasChanges = formStore.settingHasChanges(formStore.PVWelcomeSettingsData)

  useReactToChanges(hasChanges)

  function categoryHasChanges(categoryId: string) {
    return formStore.categoryHasChanges(categoryId, formStore.PVWelcomeSettingsData)
  }

  return (
    <SettingsPage
      settings={formStore.PVWelcomeSettings}
      settingsLoaded={formStore.PVWelcomeSettingsLoaded}
      data={formStore.PVWelcomeSettingsData}
      dataLoaded={formStore.PVWelcomeSettingsDataLoaded}
      setDataFunc={formStore.setPVWelcomeData}
      updateDataFnc={formStore.updatePVWelcomeData}
      receiveDataFunc={formStore.receivePVWelcomeSettingsData}>
      {({ settings, getElement }) => (
        <SettingsAccordion
          expandedPanels={uiStore.expandedPanels}
          setExpandedPanel={uiStore.setExpandedPanel}
          settingsId={settingsId}
          settings={settings}
          getElement={getElement}
          categoryHasChanges={categoryHasChanges}
        />
      )}
    </SettingsPage>
  )
})

export default PVWelcome
