import React, { ReactElement, ReactNode, useCallback } from 'react'
import {
  createStyles,
  makeStyles,
  Button,
  ButtonGroup,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Theme
} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { IQuestion } from '../../../models/questionsInterfaces'
import { useUtilStyles } from '../../../styles/useUtilStyles'
import { Accordion, AccordionDetails, AccordionSummary } from '../../../styles/customizedAccordion'
import { IPanelOpenSetting } from '../../../stores/UIStore'
import { Draggable, DraggableProvided } from 'react-beautiful-dnd'

interface IQuestionsAccordionPanelProps {
  questionItem: IQuestion
  questionTitleMaxLength: number
  expandedPanels: IPanelOpenSetting
  setExpandedPanel: (isExpanded: boolean, panelId: string) => void
  errors?: string[]
  onMoveUpOrDown(q: IQuestion, up: boolean): void
  onDelete: (id: string) => void
  getElement: (
    element: IQuestion,
    key: string
  ) => ReactElement | ReactElement[] | ReactNode | ReactNode[]
  getQuestionHasChanges?: (id: string) => boolean
}

/** observer-Component */
const QuestionsAccordionPanel: React.FC<IQuestionsAccordionPanelProps> = ({
  questionItem,
  questionTitleMaxLength,
  expandedPanels,
  setExpandedPanel,
  errors,
  onMoveUpOrDown,
  onDelete,
  getElement,
  getQuestionHasChanges
}: IQuestionsAccordionPanelProps) => {
  const hasError = errors ? errors.includes(questionItem.id) : false
  const hasChanges =
    getQuestionHasChanges && typeof getQuestionHasChanges === 'function'
      ? getQuestionHasChanges(questionItem.id)
      : false
  const classesUtil = useUtilStyles()
  const classes = useStyles({ error: hasError !== undefined ? hasError : false, hasChanges })
  const theme = useTheme()
  const isPanelExpanded = expandedPanels.questions === questionItem.id

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true
  })
  const displayTitle = questionItem?.question?.length
    ? questionItem.question.substring(0, questionTitleMaxLength) +
      (questionItem.question.length > questionTitleMaxLength ? '...' : '')
    : 'Namen vergeben!'
  const panelIdx =
    typeof questionItem.order === 'number' ? questionItem.order : parseInt(questionItem.order, 10)

  const handleMove = useCallback(
    (event: React.MouseEvent<HTMLElement>, questionItem: IQuestion, up: boolean) => {
      event.stopPropagation()
      onMoveUpOrDown(questionItem, up)
    },
    []
  )

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLButtonElement>) => event.stopPropagation(),
    []
  )

  const handleCollapseChange =
    (panelId: string) => (_event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedPanel(isExpanded, panelId)
    }

  function handleDelete(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    onDelete(questionItem.id)
  }

  return (
    <Draggable draggableId={questionItem.id} index={panelIdx}>
      {(provided: DraggableProvided) => (
        <Accordion
          expanded={hasError ? true : isPanelExpanded}
          onChange={handleCollapseChange(questionItem.id)}
          {...provided.draggableProps}
          ref={provided.innerRef}>
          <AccordionSummary
            id={`${questionItem.id}-header`}
            aria-controls={`${questionItem.id}-content`}
            expandIcon={<ExpandMoreIcon />}
            classes={{ root: classes.accordionSummaryRoot }}
            {...provided.dragHandleProps}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={9}>
                <Typography
                  variant='h4'
                  component='h3'
                  className={classesUtil.overflowWrap_breakWord}>
                  {displayTitle}
                </Typography>
                <Typography variant='h5' component='span' className={classesUtil.btnStyleText}>
                  {questionItem.type}
                </Typography>
              </Grid>
              {questionItem.type && (
                <Grid container item xs={12} sm={4} md={3} className={classesUtil.floatRight_sm_up}>
                  <ButtonGroup
                    variant={isMobile ? 'outlined' : 'text'}
                    classes={{ grouped: classes.questionPanelBtnGroup }}>
                    <Button
                      aria-label='Nach oben verschieben'
                      onFocus={handleFocus}
                      onClick={(event) => handleMove(event, questionItem, true)}>
                      <ArrowUpwardIcon color='primary' />
                    </Button>
                    <Button
                      aria-label='Nach unten verschieben'
                      onFocus={handleFocus}
                      onClick={(event) => handleMove(event, questionItem, false)}>
                      <ArrowDownwardIcon color='primary' />
                    </Button>
                    <Button aria-label='Löschen' onFocus={handleFocus} onClick={handleDelete}>
                      <DeleteIcon color='action' />
                    </Button>
                  </ButtonGroup>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {getElement(questionItem, `${questionItem.type}_${questionItem.id}`)}
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  )
}

interface IStyleProps {
  error: boolean
  hasChanges: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionPanelBtnGroup: {
      '&:not(:last-child)': {
        borderRight: 'none',
        padding: '6px 12px'
      }
    },
    accordionSummaryRoot: {
      color: (props: IStyleProps) => (props.error ? theme.palette.error.main : 'initial'),
      backgroundColor: (props: IStyleProps) =>
        props.hasChanges ? theme.custom.highlightBackgroundColor : 'initial'
    }
  })
)

export default observer(QuestionsAccordionPanel)
