import React, { useEffect, useRef, useState } from 'react'
import { ResizableBox } from 'react-resizable'
import { createStyles, makeStyles, Typography, useTheme, useMediaQuery } from '@material-ui/core'

import PageTitle from '../components/atoms/PageTitle'

const Teaser = ({}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [scriptValid, setScriptValid] = useState(false)
  const build = process.env.REACT_APP_BUILD.toLowerCase()
  const scriptSource = getScriptSource(build)
  const containerRef = useRef(null)
  const [size, setSize] = useState({ width: 800, height: 800 })

  useEffect(() => {
    if (scriptSource) {
      const script = document.createElement('script')
      script.src = scriptSource
      script.type = 'text/javascript'
      script.onload = () => {
        setScriptLoaded(true)
        if (build === 'wc') {
          if (typeof render_teaser_m_wc !== 'undefined') {
            setScriptValid(true)
            if (isDesktop) {
              /* In some cases the render function is called while the target
              div ist not available in the DOM yet. This is why we wrap a  
              setTimeout around the render function. */
              setTimeout(() => {
                render_teaser_m_wc(document.querySelector('#teaserLL'), true)
                render_teaser_m_wc(document.querySelector('#teaserML'), true)
              })
            }
            render_teaser_m_wc(document.querySelector('#teaserLS'), true)
            render_teaser_m_wc(document.querySelector('#teaserMS'), true)
          }
        } else if (build === 'eh') {
          if (typeof render_teaser_m_eh !== 'undefined') {
            setScriptValid(true)
            if (isDesktop) {
              setTimeout(() => {
                render_teaser_m_eh(document.querySelector('#teaserResponsive'), true)
                render_teaser_m_eh(document.querySelector('#teaserLL'), true)
                render_teaser_m_eh(document.querySelector('#teaserML'), true)
              })
            }
            render_teaser_m_eh(document.querySelector('#teaserLS'), true)
            render_teaser_m_eh(document.querySelector('#teaserMS'), true)
          }
        } else if (build === 'pv') {
          if (typeof render_teaser_m_pv !== 'undefined') {
            setScriptValid(true)
            if (isDesktop) {
              setTimeout(() => {
                render_teaser_m_pv(document.querySelector('#teaserResponsive'), true)
                render_teaser_m_pv(document.querySelector('#teaserLL'), true)
                render_teaser_m_pv(document.querySelector('#teaserML'), true)
              })
            }
            render_teaser_m_pv(document.querySelector('#teaserLS'), true)
            render_teaser_m_pv(document.querySelector('#teaserMS'), true)
          }
        }
      }
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [isDesktop])

  function renderTeaserFallbacks() {
    return (
      <>
        {!scriptLoaded && <Typography>Teaser Vorschau wird geladen.</Typography>}
        {scriptLoaded && !scriptValid && (
          <Typography>Teaser Vorschau konnte nicht geladen werden.</Typography>
        )}
      </>
    )
  }

  const resizeRef = useRef(null)

  return (
    <div ref={containerRef} className='container'>
      <PageTitle>Teaser Vorschau</PageTitle>
      {build !== 'wc' && (
        <>
          <Typography variant='h6' component='h2'>
            responsive Teaser - Ziehe den Handler unten rechts, um die Größe des Teasers zu ändern.
            <br />
            aktuelle Größe: <br />
            width: {size.width}px, height: {size.height}px
          </Typography>
          <div className={classes.teaserPresentation}>
            <ResizableBox
              ref={resizeRef}
              width={800}
              height={600}
              minConstraints={[300, 300]}
              maxConstraints={[1500, 1200]}
              onResize={() =>
                setSize({
                  width: resizeRef?.current?.state?.width,
                  height: resizeRef?.current?.state?.height
                })
              }>
              <div
                id='teaserResponsive'
                style={{ width: '100%', height: '100%' }}
                className={classes.teaserWrapper}>
                {renderTeaserFallbacks()}
              </div>
            </ResizableBox>
          </div>
        </>
      )}

      <Typography variant='h6' component='h2'>
        width:950px, height:550px
      </Typography>
      <div className={classes.teaserPresentation}>
        {isDesktop ? (
          <div
            id='teaserLL'
            style={{ width: '950px', height: '550px' }}
            className={classes.teaserWrapper}>
            {renderTeaserFallbacks()}
          </div>
        ) : (
          <Typography variant='body1' component='p'>
            Dieser Teaser wird nur auf der Desktop Version angezeigt.
          </Typography>
        )}
      </div>

      <Typography variant='h6' component='h2'>
        width:950px height:300px
      </Typography>
      <div className={classes.teaserPresentation}>
        {isDesktop ? (
          <div
            id='teaserML'
            style={{ width: '950px', height: '300px' }}
            className={classes.teaserWrapper}>
            {renderTeaserFallbacks()}
          </div>
        ) : (
          <Typography variant='body1' component='p'>
            Dieser Teaser wird nur auf der Desktop Version angezeigt.
          </Typography>
        )}
      </div>

      <Typography variant='h6' component='h2'>
        width:300px height:508px
      </Typography>
      <div className={classes.teaserPresentation}>
        <div
          id='teaserLS'
          style={{ width: '300px', height: '508px' }}
          className={classes.teaserWrapper}>
          {renderTeaserFallbacks()}
        </div>
      </div>

      <Typography variant='h6' component='h2'>
        width:300px height:350px
      </Typography>
      <div className={classes.teaserPresentation}>
        <div
          id='teaserMS'
          style={{ width: '300px', height: '350px' }}
          className={classes.teaserWrapper}>
          {renderTeaserFallbacks()}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    teaserPresentation: {
      display: 'table',
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    teaserWrapper: {
      display: 'block',
      boxSizing: 'border-box'
    }
  })
)

const getScriptSource = (build) => {
  switch (build) {
    case 'wc':
      return process.env.REACT_APP_TEASER_SRC_WC

    case 'eh':
      return process.env.REACT_APP_TEASER_SRC_EH

    case 'pv':
      return process.env.REACT_APP_TEASER_SRC_PV

    default:
      return undefined
  }
}

export default Teaser
