import React, { ReactElement, ReactNode } from 'react'
import { createStyles, makeStyles, Theme, Box, Grid, Hidden, Typography } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'

import TooltipWithTriggerIcon from '../molecules/TooltipWithTriggerIcon'

interface ISimpleInputBaseProps {
  firstRowTitle: string
  firstRowId: string
  firstRowChildren: ReactElement | ReactElement[] | ReactNode | ReactNode[]
  secondRowTitle?: string
  secondRowId?: string
  secondRowChildren?: ReactElement | ReactElement[] | ReactNode | ReactNode[]
  toolTip?: string
  fullWidth?: boolean
}

const SimpleInputBase: React.FC<ISimpleInputBaseProps> = ({
  firstRowTitle,
  firstRowId,
  firstRowChildren,
  secondRowTitle,
  secondRowId,
  secondRowChildren,
  toolTip,
  fullWidth = false
}: ISimpleInputBaseProps) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      {fullWidth && (
        <>
          <Grid item xs={12}>
            <Typography
              variant='body1'
              component='label'
              htmlFor={firstRowId}
              className={classes.typographyWtIcon}>
              <b>{firstRowTitle}</b>
              {!!toolTip && (
                <TooltipWithTriggerIcon
                  text={toolTip}
                  trigger={
                    <HelpIcon fontSize='small' classes={{ root: classes.toolTipIconRoot }} />
                  }
                />
              )}
            </Typography>

            <Box className={classes.innerInputsContainerFullWidth}>{firstRowChildren}</Box>
          </Grid>
        </>
      )}
      {!fullWidth && (
        <>
          <Hidden xsDown>
            <Grid item xs={12} sm={4} md={3}>
              <Typography
                variant='body1'
                component='label'
                htmlFor={firstRowId}
                className={classes.typographyWtIcon}>
                <b>{firstRowTitle}</b>
                {!!toolTip && (
                  <TooltipWithTriggerIcon
                    text={toolTip}
                    trigger={
                      <HelpIcon fontSize='small' classes={{ root: classes.toolTipIconRoot }} />
                    }
                  />
                )}
              </Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={8} md={9}>
            <Hidden smUp>
              <Typography
                variant='body1'
                component='label'
                htmlFor={firstRowId}
                className={classes.typographyWtIcon}>
                <b>{firstRowTitle}</b>
                {!!toolTip && (
                  <TooltipWithTriggerIcon
                    text={toolTip}
                    trigger={
                      <HelpIcon fontSize='small' classes={{ root: classes.toolTipIconRoot }} />
                    }
                  />
                )}
              </Typography>
            </Hidden>
            <Box className={classes.innerInputsContainer}>{firstRowChildren}</Box>
          </Grid>
        </>
      )}
      {secondRowChildren && !fullWidth && (
        <>
          {secondRowTitle ? (
            <Hidden xsDown>
              <Grid item xs={12} sm={4} md={3}>
                <Typography variant='body1' component='label' htmlFor={secondRowId || 'srid'}>
                  <b>{secondRowTitle}</b>
                  {}
                </Typography>
              </Grid>
            </Hidden>
          ) : (
            <Hidden xsDown>
              <Grid item xs={12} sm={4} md={3} />
            </Hidden>
          )}

          <Grid id={secondRowId || 'srid'} item xs={12} sm={8} md={9}>
            {secondRowChildren}
          </Grid>
        </>
      )}
      {secondRowChildren && fullWidth && (
        <>
          <Grid item xs={12}>
            {secondRowTitle && (
              <Typography variant='body1' component='label' htmlFor={secondRowId || 'srid'}>
                <b>{secondRowTitle}</b>
              </Typography>
            )}

            <Box id={secondRowId || 'srid'} className={classes.innerInputsContainerFullWidth}>
              {secondRowChildren}
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerInputsContainer: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(0)
      }
    },
    innerInputsContainerFullWidth: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1)
      }
    },
    toolTipIconRoot: {
      marginTop: '2px',
      marginLeft: theme.spacing(1),
      fill: theme.palette.action.active
    },
    typographyWtIcon: {
      display: 'inline-flex',
      alignItems: 'flex-start'
    }
  })
)

export default SimpleInputBase
