import React, { useState } from 'react'
import { createStyles, makeStyles, Button, ButtonGroup, Menu } from '@material-ui/core'
import SimpleInputBase from './SimpleInputBase'

// Props handed down to children components
interface IInjectedProps {
  handleClose: () => void
}

interface ISelectBtnProps {
  element: { title: string; fullWidth?: boolean; toolTip?: string }
  fieldKey: string
  baseId?: string
  values?: string
  children?: (props: IInjectedProps) => JSX.Element | JSX.Element[]
}

const SelectBtn: React.FC<ISelectBtnProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  children
}: ISelectBtnProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  if (!baseId) {
    baseId = fieldKey
  }

  function handleOpenClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <SimpleInputBase
      fullWidth={element.fullWidth || false}
      firstRowTitle={element.title}
      firstRowId={`bg_${baseId}`}
      toolTip={element.toolTip}
      firstRowChildren={
        <>
          <ButtonGroup
            id={`bg_${baseId}`}
            classes={{
              root: classes.btn
            }}
            disableElevation>
            <Button
              classes={{
                root: classes.btnRoot
              }}
              variant='contained'
              color='primary'
              aria-haspopup='true'
              aria-controls={`menu_${baseId}`}
              onClick={handleOpenClick}>
              Auswählen
            </Button>
            <Button
              classes={{
                root: classes.btnTextBtn,
                label: classes.btnTextLabel
              }}
              component='span'
              variant='outlined'
              color='primary'>
              {values || 'Keine Seite gewählt'}
            </Button>
          </ButtonGroup>
          {children && (
            <Menu
              id={`menu_${baseId}`}
              anchorEl={anchorEl}
              onClose={handleClose}
              keepMounted
              open={Boolean(anchorEl)}>
              {children({ handleClose })}
            </Menu>
          )}
        </>
      }
    />
  )
}

const useStyles = makeStyles(
  createStyles({
    btn: {
      maxWidth: '100%'
    },
    btnRoot: {
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    btnTextBtn: {
      justifyContent: 'flex-start',
      textTransform: 'none',
      cursor: 'initial'
    },
    btnTextLabel: {
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis'
    }
  })
)

export default SelectBtn
