import React from 'react'
import { observer } from 'mobx-react-lite'
import { IGroupOfBooleanElement } from '../../models/settingsInterfaces'
import SimpleInputBase from './SimpleInputBase'
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core'

interface IGroupOfBooleanElementProps {
  element: IGroupOfBooleanElement
  fieldKey: string
  baseId?: string
  values?: any
  updateData?: (key: string, data: any) => void
}

const getInitialValues = (values: string | boolean | undefined): boolean =>
  values !== undefined ? (typeof values === 'boolean' ? values : values === 'true') : false

const GroupOfBooleanElement: React.FC<IGroupOfBooleanElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: IGroupOfBooleanElementProps) => {
  const checked: { key: string; value: boolean }[] = values
    ? values
    : element.options.map((option) => ({ key: option, value: getInitialValues(option) }))

  function toggleChecked(event: React.ChangeEvent<HTMLInputElement>): void {
    const newValues = checked
    if (updateData) {
      const index = checked.findIndex((item) => item.key === event.target.name)
      newValues[index] = { key: newValues[index].key, value: event.target.checked }
      updateData(fieldKey, newValues)
    }
  }

  return (
    <>
      <SimpleInputBase
        fullWidth={element.fullWidth || false}
        firstRowTitle={element.title}
        firstRowId={`input_${baseId}`}
        toolTip={element.toolTip}
        firstRowChildren={
          <FormControl>
            {element.options.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    id={`input_${baseId}_${option}`}
                    checked={getInitialValues(checked.find((item) => item.key === option)?.value)}
                    onChange={toggleChecked}
                    name={option}
                    color='primary'
                    aria-describedby={`description_${baseId}`}
                  />
                }
                label={element.optionTitles[index] || ''}
              />
            ))}
          </FormControl>
        }
      />
    </>
  )
}

export default observer(GroupOfBooleanElement)
