import React from 'react'
import { Typography } from '@material-ui/core'
import PageTitle from '../atoms/PageTitle'

const CMSTemplateNotFound: React.FC = () => (
  <>
    <PageTitle>Nicht gefunden</PageTitle>
    <Typography variant='body1'>In dieser Kategorie gibt es keine Inhalte</Typography>
  </>
)

export default CMSTemplateNotFound
