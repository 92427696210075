import React, { ReactNode } from 'react'
import {
  createStyles,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Theme,
  Typography,
  Button,
  Box
} from '@material-ui/core'

import { FileLibraryListItem, FileUploadListItem, FileUploadListProps } from '../../types'
import { Alert } from '@material-ui/lab'
import { useSettings } from '../context/SettingsContext'
import { useUploadsListContext } from '../context/UploadsListContext'
import { defaultProps } from '../utils/defaultProps'

function renderBadge(
  status: number,
  uploadSuccessText: string,
  uploadFailedText: string,
  uploadProcessingText: string
): ReactNode {
  switch (status) {
    case -1:
      return <Alert severity='error'>{uploadFailedText}</Alert>
    case 0:
      return <Alert severity='info'>{uploadProcessingText}</Alert>
    case 1:
      return <Alert severity='success'>{uploadSuccessText}</Alert>
    default:
      return null
  }
}

// const FileUploadList: React.FC<FileUploadListProps> = ({ fileUploadList }: FileUploadListProps) => {
const FileUploadList: React.FC<FileUploadListProps> = () => {
  const { fileUploadList } = useUploadsListContext()
  const { settings } = useSettings()
  const {
    fileLibraryList,
    uploadListTitleText = defaultProps.uploadListTitleText,
    uploadSuccessText = defaultProps.uploadSuccessText,
    uploadFailedText = defaultProps.uploadFailedText,
    uploadProcessingText = defaultProps.uploadProcessingText,
    fileSelectCallback,
    selectBtnText = defaultProps.selectBtnText
  } = settings

  function getLibraryListItemForUploadListItem(
    uploadItem: FileUploadListItem
  ): FileLibraryListItem | undefined {
    return fileLibraryList.find((libItem) => libItem.fileName === uploadItem.fileName)
  }

  function handleSelect(selectedItem: FileLibraryListItem) {
    if (fileSelectCallback) {
      fileSelectCallback(selectedItem)
    }
  }

  function renderList(): ReactNode[] {
    const classes = useStyles()
    return fileUploadList.map((element: FileUploadListItem, index: number) => {
      const libraryItem = getLibraryListItemForUploadListItem(element)

      return (
        <React.Fragment key={index}>
          <ListItem divider disableGutters classes={{ root: classes.listItemRoot }}>
            <ListItemText classes={{ primary: classes.listItemText }} primary={element.fileName} />
            <Box display='flex'>
              {libraryItem && (
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classes.mr_1}
                  onClick={() => handleSelect(libraryItem)}>
                  {selectBtnText}
                </Button>
              )}
              <ListItemIcon>
                {renderBadge(
                  element.status,
                  uploadSuccessText,
                  uploadFailedText,
                  uploadProcessingText
                )}
              </ListItemIcon>
            </Box>
          </ListItem>
        </React.Fragment>
      )
    })
  }

  return (
    <React.Fragment>
      {fileUploadList.length > 0 && (
        <Typography variant='h5' component='h3'>
          {uploadListTitleText}
        </Typography>
      )}
      <List component='ul' aria-label='files with upload status'>
        {renderList()}
      </List>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemRoot: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center'
      }
    },
    listItemText: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      overflowX: 'hidden',
      [theme.breakpoints.up('sm')]: {
        marginBottom: 0
      }
    },
    mr_1: {
      marginRight: theme.spacing(1)
    }
  })
)

export default FileUploadList
