import React from 'react'

import { ReactMediaLibraryProps } from '../../types'
import SettingsProvider from '../context/SettingsContext'
import UploadsListProvider from '../context/UploadsListContext'
import ReactMediaLibraryContainer from './ReactMediaLibraryContainer'

const ReactMediaLibrary: React.FC<ReactMediaLibraryProps> = (props: ReactMediaLibraryProps) => (
  <SettingsProvider>
    <UploadsListProvider>
      <ReactMediaLibraryContainer
        show={props.show}
        onHide={props.onHide}
        modalTitle={props.modalTitle}
        mainProps={props}
      />
    </UploadsListProvider>
  </SettingsProvider>
)

export default ReactMediaLibrary
