import React, { FC, useEffect } from 'react'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { ErrorBoundary } from 'react-error-boundary'

import theme from './styles/theme'
import { useStore } from './stores/StoreContext'
import { observer } from 'mobx-react-lite'
import ErrorFallbackApp from './components/fallbacks/ErrorFallbackApp'
import ConfirmDialog from './components/dialogs/ConfirmDialog'
import Toast from './components/dialogs/Toast'
import { action } from 'mobx'
import MediaLibraryWrapper from './components/organisms/MediaLibraryWrapper'
import CustomRouter from './components/routing/Routes'

import routesData from './routes.json'

const App: FC = () => {
  const { userStore, cmsStore } = useStore()
  const routes = routesData.routes
  console.log('env', process.env)
  useEffect(() => {
    const initData = action(async () => {
      await userStore.receiveUser()
      if (process.env.REACT_APP_BUILD && process.env.REACT_APP_BUILD.toLowerCase() === 'eh') {
        await cmsStore.receiveConfiguration()
      }
    })
    initData()
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackApp}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toast />
        <ConfirmDialog />
        <MediaLibraryWrapper />
        <CustomRouter routes={routes} />
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default observer(App)
