import { Pagination } from '@material-ui/lab'
import React from 'react'
import { FileLibraryPagerProps } from '../../types'
import { useSettings } from '../context/SettingsContext'
import { defaultProps } from '../utils/defaultProps'

const FileLibraryPager: React.FC<FileLibraryPagerProps> = ({
  page,
  pagerCallback,
  count
}: // offsetDisplay = 2
FileLibraryPagerProps) => {
  const { settings } = useSettings()
  const { itemsPerPage = defaultProps.itemsPerPage } = settings
  const totalPages = Math.ceil(count / itemsPerPage)

  function handleChange(event: React.ChangeEvent<unknown>, value: number) {
    pagerCallback(value)
  }

  return <Pagination color='primary' count={totalPages} page={page} onChange={handleChange} />
}

export default FileLibraryPager
