import React, { ReactElement, ReactNode } from 'react'
import { Box, Container, Toolbar } from '@material-ui/core'
import { usePageStyles } from '../../styles/usePageStyles'

interface ILoggedOutLayoutProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

const LoggedOutLayout: React.FC<ILoggedOutLayoutProps> = ({ children }: ILoggedOutLayoutProps) => {
  const classes = usePageStyles()
  return (
    <>
      <Box className={classes.appContainer}>
        <Container component='main' maxWidth='md' className={classes.main}>
          {/* Empty Toolbar Component pushes main content below Top Bar */}
          <Toolbar />
          {children}
        </Container>
      </Box>
    </>
  )
}

export default LoggedOutLayout
