import { createStyles, makeStyles, Theme } from '@material-ui/core'

/** Creates some reusable utility styles */
export function useUtilStyles() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      btnStyleText: {
        ...theme.typography.button,
        color: theme.palette.primary.light
      },
      mb_2: {
        marginBottom: theme.spacing(2)
      },
      mb_3: {
        marginBottom: theme.spacing(3)
      },
      floatRight_sm_up: {
        justifyContent: 'flex-start',
        [theme.breakpoints.up('sm')]: {
          justifyContent: 'flex-end'
        }
      },
      overflowWrap_breakWord: {
        overflowWrap: 'break-word'
      }
    })
  )
  return useStyles()
}
