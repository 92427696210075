import unorm from 'unorm'

export class Utility {
  /** Validate String that represents a number (only allow positive values) */
  public static stripNum = (num: string) => {
    let val = num.trimLeft()
    val = val.startsWith('-') ? '0' : val
    // Logger.log('stripped', num, val)
    return val
  }

  public static renameFile = (filename: string) =>
    unorm.nfc(filename).replace(/[^a-z0-9.\-_]/gi, '_')

  // Inspiration: https://masteringjs.io/tutorials/fundamentals/typeof-date
  public static isDate = (input: unknown): boolean =>
    Object.prototype.toString.call(input) === '[object Date]'

  // Inspiration: https://masteringjs.io/tutorials/fundamentals/typeof-date
  public static isValidDate = (input: unknown): boolean =>
    input instanceof Date && !Number.isNaN(input.valueOf())
}
