import React from 'react'
import { observer } from 'mobx-react-lite'
import { Grid, TextField, Typography } from '@material-ui/core'

import InputsContainer from './InputsContainer'
import { IQuestion } from '../../models/questionsInterfaces'

interface ITextElementQuestionProps {
  questionData: IQuestion
  baseId: string
  updateData?: (id: string, field: string, value: any) => void
  setSettingError?: (id: string) => void
  clearSettingError?: (id: string) => void
}

/** observer-Component */
const TextElementQuestion: React.FC<ITextElementQuestionProps> = ({
  questionData,
  baseId,
  updateData
}: ITextElementQuestionProps) => {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (updateData) {
      updateData(questionData.id, 'question', event.target.value)
    }
  }

  function handleChangeQuestionLong(event: React.ChangeEvent<HTMLInputElement>) {
    if (updateData) {
      updateData(questionData.id, 'questionLong', event.target.value)
    }
  }

  return (
    <InputsContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='body1' component='label' htmlFor={`${baseId}-q`}>
            <b>Frage</b>
          </Typography>

          <TextField
            id={`${baseId}-q`}
            value={questionData.question || ''}
            fullWidth
            variant='outlined'
            size='small'
            InputProps={{
              inputProps: {
                maxLength: 2000
              }
            }}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' component='label' htmlFor={`${baseId}-ql`}>
            Erklärung, unterhalb der Frage
          </Typography>

          <TextField
            id={`${baseId}-ql`}
            value={questionData.questionLong || ''}
            fullWidth
            variant='outlined'
            size='small'
            InputProps={{
              inputProps: {
                maxLength: 2000
              }
            }}
            onChange={handleChangeQuestionLong}
          />
        </Grid>
      </Grid>
    </InputsContainer>
  )
}

export default observer(TextElementQuestion)
