import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import {
  createStyles,
  makeStyles,
  Theme,
  AppBar,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { useStore } from '../../stores/StoreContext'
import onSaveNotifier from '../../utils/OnSaveNotifier'
import ReactResizeDetector from 'react-resize-detector'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

/** observer-Component */
const TopBar: React.FC = () => {
  const { uiStore } = useStore()
  const {
    drawerWidth,
    drawerOpenBreakpoint,
    toggleDrawerOpen,
    savingDisabled,
    topBarHeight,
    setTopBarHeight,
    emphasizeSaveBtn
  } = uiStore

  // TODO: remove third parameter if not needed
  const classes = useStyles({ drawerWidth, drawerOpenBreakpoint, emphasizeSaveBtn })
  const appBarRef = useRef<HTMLDivElement>()

  function handleSave() {
    onSaveNotifier.notify()
  }

  function onResize() {
    if (appBarRef.current && topBarHeight !== appBarRef.current.getBoundingClientRect().height) {
      setTopBarHeight(appBarRef.current.getBoundingClientRect().height)
    }
  }

  return (
    <>
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
      <AppBar position='fixed' className={classes.appBar} ref={appBarRef}>
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={toggleDrawerOpen}
              className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
          </Hidden>

          {/* <Link component={RouterLink} to={'/'} className={classes.homeButtonRoot}>
            <HomeIcon />
        </Link> */}

          <Typography variant='h5' component='h2' className={classes.topBarTitle}>
            ASEW Konfigurator
          </Typography>
          <Button
            variant={emphasizeSaveBtn ? 'contained' : 'outlined'}
            color={emphasizeSaveBtn ? 'secondary' : 'default'}
            classes={{
              root: classes.saveButton,
              disabled: classes.saveButtonDisabled
            }}
            onClick={handleSave}
            disabled={savingDisabled}>
            <Hidden xsDown>Änderungen</Hidden> Speichern
          </Button>
        </Toolbar>
      </AppBar>
    </>
  )
}

interface IStyleProps {
  drawerWidth: number | undefined
  drawerOpenBreakpoint: Breakpoint | undefined
  emphasizeSaveBtn: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: (props: IStyleProps) => ({
      zIndex: 50,
      [theme.breakpoints.up(props.drawerOpenBreakpoint || 'md')]: {
        width: `calc(100% - ${props.drawerWidth || 240}px)`,
        marginLeft: props.drawerWidth
      }
    }),
    menuButton: {
      marginRight: theme.spacing(2),
      '&:hover': {
        color: theme.palette.secondary.light
      }
    },
    topBarTitle: {
      flexGrow: 1
    },
    saveButton: {
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.contrastText,
      '&:hover': {
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light
      }
    },
    saveButtonDisabled: {
      color: theme.custom.defaultDarkColor,
      borderColor: theme.custom.defaultDarkColor
    }
  })
)

export default observer(TopBar)
