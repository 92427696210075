import CMSStore from './CMSStore'
import EHStore from './EHStore'
import FileStore from './FileStore'
import FormStore from './FormStore'
import HiddenStore from './HiddenStore'
import UIStore from './UIStore'
import UserStore from './UserStore'
import { configure } from 'mobx'

/* This is needed because the app might receive scripts from other apps that
also use mob-x (e.g. the 'Teaser' component uses a script from the 'energiehaus'
or 'waermecheck' app) */
configure({ isolateGlobalState: true })

class ApplicationStore {
  public readonly formStore: FormStore
  public readonly ehStore: EHStore
  public readonly cmsStore: CMSStore
  public readonly uiStore: UIStore
  public readonly userStore: UserStore
  public readonly hiddenStore: HiddenStore
  public readonly fileStore: FileStore
  public readonly constants: { [key: string]: string | undefined }

  public constructor() {
    this.formStore = new FormStore(this)
    this.ehStore = new EHStore(this)
    this.cmsStore = new CMSStore(this)
    this.uiStore = new UIStore(this)
    this.userStore = new UserStore(this)
    this.hiddenStore = new HiddenStore(this)
    this.fileStore = new FileStore(this)
    this.constants = { CONSTANTS_FRIEDA_URL: process.env.REACT_APP_FRIEDA_URL }
  }
}

export default ApplicationStore
