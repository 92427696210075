import {
  createStyles,
  makeStyles,
  Theme,
  Button,
  InputBaseComponentProps,
  Paper,
  FormControl,
  FormHelperText,
  OutlinedInput
} from '@material-ui/core'
import React from 'react'
import { NumberFormatProps } from 'react-number-format'
import NumberFormatCustom from '../utilComponents/NumberFormat'

type TTextFieldInnerLabelProps = InputBaseComponentProps & {
  label: string
  helperText?: string | null
  numberFormatProps?: NumberFormatProps
}

const TextFieldInnerLabel: React.FC<TTextFieldInnerLabelProps> = ({
  label,
  helperText,
  numberFormatProps,
  ...otherProps
}: TTextFieldInnerLabelProps) => {
  const classes = useStyles({ error: !!otherProps.error })
  const CustomLabel = () => (
    <Button
      className={classes.textFieldInnerLabel}
      variant='outlined'
      disabled
      disableElevation
      component='label'
      htmlFor={otherProps?.id ? otherProps?.id : undefined}>
      {label}
    </Button>
  )

  return (
    <FormControl error={Boolean(otherProps.error)} classes={{ root: classes.formControlRoot }}>
      <Paper
        component='form'
        variant='outlined'
        className={classes.textFieldWithInnerLabelContainer}>
        <CustomLabel />
        <OutlinedInput
          className={classes.textFieldWithInnerLabel}
          fullWidth
          inputComponent={!!numberFormatProps ? (NumberFormatCustom as any) : 'input'}
          inputProps={{ ...numberFormatProps, ...otherProps }}
        />
      </Paper>
      <FormHelperText error={Boolean(otherProps.error)} className={classes.helperText}>
        {helperText}
      </FormHelperText>
    </FormControl>
  )
}

interface IStyleProps {
  error: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControlRoot: {
      width: '100%'
    },
    helperText: {
      marginLeft: theme.spacing(0.5)
    },
    textFieldWithInnerLabelContainer: {
      display: 'flex',
      alignItems: 'stretch'
    },
    textFieldWithInnerLabel: {
      backgroundColor: theme.palette.common.white,
      flex: 1,
      padding: '18.5px 14px',
      paddingTop: '10.5px',
      paddingBottom: '10.5px',
      border: 'none',
      borderRadius: '4px',
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      '& input': {
        padding: 0
      }
    },
    textFieldInnerLabel: {
      width: 'max-content',
      margin: '-1',
      color: (props: IStyleProps) =>
        props.error ? theme.palette.error.main : theme.custom.defaultDarkColor,
      backgroundColor: theme.palette.common.white,
      whiteSpace: 'nowrap',
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      border: (props: IStyleProps) =>
        props.error
          ? `1px solid ${theme.palette.error.main}`
          : `1px solid ${theme.custom.defaultBorderColor}`,
      borderRight: (props: IStyleProps) => (props.error ? 'none' : 'none'), // This rule doesn't work if 'props' isn't used
      '&.Mui-disabled': {
        color: (props: IStyleProps) =>
          props.error ? theme.palette.error.main : theme.custom.defaultDarkColor,
        border: (props: IStyleProps) =>
          props.error
            ? `1px solid ${theme.palette.error.main}`
            : `1px solid ${theme.custom.defaultBorderColor}`,
        borderRight: (props: IStyleProps) => (props.error ? 'none' : 'none') // This rule doesn't work if 'props' isn't used
      }
    }
  })
)

export default TextFieldInnerLabel
