import React, { ReactElement, ReactNode, createContext } from 'react'
import ApplicationStore from './ApplicationStore'

interface IStoreProviderProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

export const rootStore = new ApplicationStore()
const StoreContext = createContext<ApplicationStore | null>(rootStore)

export const StoreProvider: React.FC<IStoreProviderProps> = ({ children }: IStoreProviderProps) => (
  <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
)

export const useStore = () => {
  const store = React.useContext(StoreContext)
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}
