import { createStyles, makeStyles, Theme } from '@material-ui/core'

/** Creates styles for App and pages components */
export function usePageStyles() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContainer: {
        display: 'flex'
      },
      main: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(2)
        }
      }
    })
  )
  return useStyles()
}
