import React from 'react'
import { observer } from 'mobx-react-lite'
import { IInteractElement } from '../../models/settingsInterfaces'
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core'

interface IInteract {
  checked: boolean
  inputValue: string
}

interface IInteractElementProps {
  element: IInteractElement
  fieldKey: string
  baseId?: string
  values?: any
  updateData?: (key: string, data: any) => void
}

const getInitialValue = (values: string | boolean | undefined): boolean =>
  values !== undefined ? (typeof values === 'boolean' ? values : values === 'true') : false

const InteractElement: React.FC<IInteractElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: IInteractElementProps) => {
  const value: IInteract = values || { checked: false, inputValue: '' }
  const checked = getInitialValue(value.checked)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    let newValues: IInteract = value

    if (event.target.type === 'checkbox') {
      newValues = { ...value, [`${event.target.name}`]: event.target.checked }
    } else {
      newValues = { ...value, [`${event.target.name}`]: event.target.value }
    }

    if (updateData) {
      updateData(fieldKey, newValues)
    }
  }

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant='body1'>
          <b>{element.title}</b>
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={1} alignItems='center'>
        <Grid item xs={12} sm={4}>
          <Typography variant='body1' component='label' htmlFor={`${baseId}`}>
            {element.subTitle1}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormControlLabel
            control={
              <Checkbox
                id={`${fieldKey}-checked`}
                name='checked'
                checked={checked}
                onChange={handleChange}
                color='primary'
              />
            }
            label={element.caption}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant='body1' component='label' htmlFor={`${baseId}-mr`}>
            {element.subTitle2}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            type='text'
            disabled={!value.checked}
            id={`${fieldKey}-inputValue`}
            name='inputValue'
            value={value.inputValue}
            fullWidth
            variant='outlined'
            size='small'
            onChange={handleChange}
            placeholder={element.description && element.description}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(InteractElement)
