import React, { ReactElement, ReactNode } from 'react'
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Button,
  ButtonGroup,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { Draggable, DraggableProvided } from 'react-beautiful-dnd'

import {
  AccordionNested,
  AccordionSummaryNested,
  AccordionDetailsNested
} from '../../styles/customizedAccordion'

interface CMSSubModuleAccordionPanelProps {
  title: string
  id: string
  moveAllowed: boolean
  deleteAllowed: boolean
  pageSubModuleIndex: number
  onSubModulePositionChangeUpOrDown: (pageSubModuleIndex: number, direction: 'up' | 'down') => void
  onDeleteSubModule: () => void
  onPanelClick: (id: string) => void
  expanded: boolean
  panelDisabled?: boolean
  activeScroll?: boolean
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
  hasChanges?: boolean
}

const CMSSubModuleAccordionPanel: React.FC<CMSSubModuleAccordionPanelProps> = ({
  title,
  id,
  moveAllowed,
  deleteAllowed,
  pageSubModuleIndex,
  onSubModulePositionChangeUpOrDown,
  onDeleteSubModule,
  onPanelClick,
  expanded,
  panelDisabled = false,
  children,
  hasChanges
}: CMSSubModuleAccordionPanelProps) => {
  const classes = useStyles({ hasChanges: typeof hasChanges === 'undefined' ? false : hasChanges })
  const anyActionAllowed = deleteAllowed || moveAllowed

  function handlePanelClick() {
    onPanelClick(id)
  }

  function handleClickUp(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    onSubModulePositionChangeUpOrDown(pageSubModuleIndex, 'up')
  }

  function handleClickDown(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    onSubModulePositionChangeUpOrDown(pageSubModuleIndex, 'down')
  }

  function handleDelete(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    onDeleteSubModule()
  }

  return (
    <Draggable draggableId={id} index={pageSubModuleIndex} isDragDisabled={!moveAllowed}>
      {(provided: DraggableProvided) => (
        <AccordionNested
          ref={provided.innerRef}
          id={id}
          square
          expanded={expanded}
          onChange={handlePanelClick}
          TransitionProps={{
            timeout: 50
          }}
          {...provided.draggableProps}>
          <AccordionSummaryNested
            expandIcon={panelDisabled ? null : <ExpandMoreIcon />}
            disabled={panelDisabled}
            id={`${id}-header`}
            aria-controls={`${id}-content`}
            classes={{ root: classes.accordionSummaryRoot }}
            {...provided.dragHandleProps}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' component='h4' className={classes.panelTitle}>
                {title}
              </Typography>
              {anyActionAllowed && (
                <Box className={classes.btnGoupContainer}>
                  <ButtonGroup classes={{ root: classes.btnGroupRoot }}>
                    {moveAllowed && (
                      <Button aria-label='Nach oben verschieben' onClick={handleClickUp}>
                        <ArrowUpwardIcon color='primary' />
                      </Button>
                    )}
                    {moveAllowed && (
                      <Button aria-label='Nach unten verschieben' onClick={handleClickDown}>
                        <ArrowDownwardIcon color='primary' />
                      </Button>
                    )}
                    {deleteAllowed && (
                      <Button aria-label='Löschen' onClick={handleDelete}>
                        <DeleteIcon color='action' />
                      </Button>
                    )}
                  </ButtonGroup>
                </Box>
              )}
            </Box>
          </AccordionSummaryNested>

          <AccordionDetailsNested>{children}</AccordionDetailsNested>
        </AccordionNested>
      )}
    </Draggable>
  )
}

interface IStyleProps {
  hasChanges: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionSummaryRoot: {
      backgroundColor: (props: IStyleProps) =>
        props.hasChanges ? theme.custom.highlightBackgroundColor : 'initial',
      '&.Mui-disabled': {
        backgroundColor: (props: IStyleProps) =>
          props.hasChanges ? theme.custom.highlightBackgroundColor : 'initial'
      }
    },
    btnGroupRoot: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    btnGoupContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.custom.defaultDarkColor}`
      }
    },
    panelBtnRoot: {
      minWidth: '48px',
      [theme.breakpoints.up('sm')]: {
        minWidth: '64px'
      }
    },
    panelTitle: {
      overflowWrap: 'break-word',
      color: theme.palette.primary.dark
    }
  })
)

export default CMSSubModuleAccordionPanel
