import React, { ReactElement, ReactNode, useCallback, useEffect } from 'react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import {
  IInputElement,
  ITextInputElement,
  IBooleanInputElement,
  IHeatloadElement,
  IFileElement,
  IColorInputElement,
  ISettings,
  IGroupInteractElement,
  ITextWithLinkElement,
  IGroupOfTextElement,
  IHeatloadElementHP,
  IHeatloadElementPVBS,
  IHeatloadElementWB,
  IRadioGroupElement,
  IGroupOfBooleanElement,
  IInteractElement
} from '../models/settingsInterfaces'
import { useStore } from '../stores/StoreContext'
import onSaveNotifier from '../utils/OnSaveNotifier'
import PageTitle from '../components/atoms/PageTitle'
import InputsContainer from './inputElements/InputsContainer'
import TextElement from './inputElements/TextElement'
import RichtextElement from './inputElements/RichtextElement'
import BooleanElement from './inputElements/BooleanElement'
import ColorElement from './inputElements/ColorElement'
import HeatloadElement from './inputElements/HeatloadElement'
import TextDisplayElement from './inputElements/TextDisplayElement'
import FileElementFileManager from './inputElements/FileElementFileManager'
import { FileLibraryListItem } from '../mediaLibrary/types'
import LoadingBackdrop from './fallbacks/LoadingBackdrop'
import { Logger } from '../utils/log'
import PageSubtitle from './atoms/PageSubtitle'
import GroupInteractElement from './inputElements/GroupInteractElement'
import TextWithLinkElement from './inputElements/TextWithLinkElement'
import GroupOfTextElement from './inputElements/GroupOfTextElement'
import GroupOfBooleanElement from './inputElements/GroupOfBooleanElement'
import HeatloadElementHP from './inputElements/HeatloadElementHP'
import HeatloadElementPVBS from './inputElements/HeatloadElementPVBS'
import HeatloadElementWB from './inputElements/HeatloadElementWB'
import RadioGroupElement from './inputElements/RadioGroupElement'
import InteractElement from './inputElements/InteractElement'

interface ISettingsPageProps {
  settings?: ISettings // configuration settings for the input elements
  settingsLoaded: boolean
  data: any // contents of the input elements
  dataLoaded: boolean
  setDataFunc: (key: string, newData: any) => void // Change a setting in the store
  receiveDataFunc: () => Promise<any> // Receive settings from the database
  updateDataFnc: () => Promise<void> // Send settings to the database
  saveObj?: boolean
  children(props: IInjectedProps): JSX.Element
}

// Props handed down to children components
interface IInjectedProps {
  settings: any
  getElement(
    element: IInputElement,
    key: string
  ): ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

/** observer-Component */
const SettingsPage: React.FC<ISettingsPageProps> = ({
  settings,
  settingsLoaded,
  data,
  dataLoaded,
  setDataFunc,
  receiveDataFunc,
  updateDataFnc,
  saveObj = true,
  children
}: ISettingsPageProps) => {
  const { formStore, fileStore, constants, uiStore } = useStore()

  // Receive configurtaion and subscribe to 'save' event
  useEffect(() => {
    onSaveNotifier.subscribe(onSave)

    const initData = async () => {
      await formStore.receiveConfiguration()

      if (receiveDataFunc && receiveDataFunc instanceof Function) {
        await receiveDataFunc()
      }
    }
    initData()

    return () => {
      onSaveNotifier.unsubscribe(onSave)
    }
  }, [])

  /* Callback that gets handed down to children */
  const receiveNewData = useCallback((key: string, newData: any) => {
    Logger.log('receiving', key, newData)
    runInAction(() => {
      setDataFunc(key, newData)
    })
  }, [])

  /* Send data to store once 'save' event is fired */
  async function onSave() {
    await updateDataFnc()
    if (receiveDataFunc && receiveDataFunc instanceof Function) {
      await receiveDataFunc()
    }
  }

  /* Callback that gets executed when user attempts to set a file in
  the file input component. Will be handed down to file input component.*/
  function onFileSelectRequest(
    onSelect: (item: FileLibraryListItem) => void,
    acceptedTypes: string
  ) {
    fileStore.openMediaLibrary(onSelect, acceptedTypes)
  }

  /* Checks if file manager has a specific file. Will be handed down to 
  file input component.*/
  function checkIfFileExists(filename: string): boolean {
    const fileElement = fileStore.getFileElementFromList(filename)
    return !!fileElement
  }

  // This will be passed to text inputs if they are number inputs
  const numberFormatProps = {
    decimalSeparator: ',',
    thousandSeparator: '.',
    isNumericString: true,
    allowEmptyFormatting: false,
    allowLeadingZeros: true
  }

  /* Create Input Element depending on element type. Will be handed down
   * to child components.
   */
  const getElement = useCallback(
    (element: IInputElement, key: string) => {
      switch (element.type) {
        case 'boolean':
          return (
            <InputsContainer key={key}>
              <BooleanElement
                element={element as IBooleanInputElement}
                fieldKey={`${element.id}${element.postfix}`}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )
        case 'radioGroup':
          return (
            <InputsContainer key={key}>
              <RadioGroupElement
                element={element as IRadioGroupElement}
                fieldKey={`${element.id}${element.postfix}`}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )
        case 'constant':
          return (
            <InputsContainer key={key}>
              <TextDisplayElement element={element as IInputElement} constants={constants} />
            </InputsContainer>
          )
        case 'heatload':
          return (
            <InputsContainer key={key}>
              <HeatloadElement
                element={element as IHeatloadElement}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )
        case 'upload':
          return (
            <InputsContainer key={key}>
              <FileElementFileManager
                element={element as IFileElement}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
                onFileSelectRequest={onFileSelectRequest}
                checkIfFileExists={checkIfFileExists}
              />
            </InputsContainer>
          )
        case 'color':
          return (
            <InputsContainer key={key}>
              <ColorElement
                element={element as IColorInputElement}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )

        case 'heatload_fpv':
          return (
            <InputsContainer key={key}>
              <HeatloadElementPVBS
                element={element as IHeatloadElementPVBS}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )

        case 'heatload_hp':
          return (
            <InputsContainer key={key}>
              <HeatloadElementHP
                element={element as IHeatloadElementHP}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )

        case 'heatload_wb':
          return (
            <InputsContainer key={key}>
              <HeatloadElementWB
                element={element as IHeatloadElementWB}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )

        case 'group_interact':
          return (
            <InputsContainer key={key}>
              <GroupInteractElement
                element={element as IGroupInteractElement}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )

        case 'group_of_text':
          return (
            <InputsContainer key={key}>
              <GroupOfTextElement
                element={element as IGroupOfTextElement}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )

        case 'group_of_boolean':
          return (
            <InputsContainer key={key}>
              <GroupOfBooleanElement
                element={element as IGroupOfBooleanElement}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )

        case 'interact':
          return (
            <InputsContainer key={key}>
              <InteractElement
                element={element as IInteractElement}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )

        case 'text_with_link':
          return (
            <InputsContainer key={key}>
              <TextWithLinkElement
                element={element as ITextWithLinkElement}
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  data?.settings
                    ? data.settings[`${element.id}${element.postfix}`]
                    : data?.[`${element.id}${element.postfix}`]
                }
                updateData={receiveNewData}
              />
            </InputsContainer>
          )

        case 'text':
          if (!!(element as ITextInputElement).isRichText) {
            return (
              <InputsContainer key={key}>
                <RichtextElement
                  element={element as ITextInputElement}
                  fieldKey={`${element.id}${element.postfix}` || key}
                  values={
                    saveObj
                      ? data?.settings?.[`${element.id}${element.postfix}`] || undefined
                      : { question: data?.[`${element.id}${element.postfix}`] } || undefined
                  }
                  updateData={receiveNewData}
                  saveObj={saveObj}
                />
              </InputsContainer>
            )
          } else {
            return (
              // eslint-disable-next-line prettier/prettier
              <InputsContainer key={key}>
                <TextElement
                  element={element as ITextInputElement}
                  numberFormatProps={
                    (element as ITextInputElement).validate === 'num'
                      ? numberFormatProps
                      : undefined
                  }
                  fieldKey={`${element.id}${element.postfix}` || key}
                  values={
                    saveObj
                      ? data?.settings?.[`${element.id}${element.postfix}`] || undefined
                      : { question: data?.[`${element.id}${element.postfix}`] } || undefined
                  }
                  updateData={receiveNewData}
                  saveObj={saveObj}
                  multiline={(element as ITextInputElement).isTextArea}
                  minRows={(element as ITextInputElement).isTextArea ? 6 : 1}
                  InputProps={{
                    inputProps: {
                      maxLength: (element as ITextInputElement).max_length || 2000
                    }
                  }}
                />
              </InputsContainer>
            )
          }

        default:
          return (
            <InputsContainer key={key}>
              <TextElement
                element={element as ITextInputElement}
                numberFormatProps={
                  (element as ITextInputElement).validate === 'num' ? numberFormatProps : undefined
                }
                fieldKey={`${element.id}${element.postfix}` || key}
                values={
                  saveObj
                    ? data?.settings?.[`${element.id}${element.postfix}`] || undefined
                    : { question: data?.[`${element.id}${element.postfix}`] } || undefined
                }
                updateData={receiveNewData}
                saveObj={saveObj}
                multiline={(element as ITextInputElement).isTextArea}
                minRows={(element as ITextInputElement).isTextArea ? 6 : 1}
                InputProps={{
                  inputProps: {
                    maxLength: (element as ITextInputElement).max_length || 2000
                  }
                }}
              />
            </InputsContainer>
          )
      }
    },
    [data]
  )

  return (
    <>
      {settingsLoaded && settings?.categories && (
        <>
          <PageTitle>{settings.title}</PageTitle>
          <PageSubtitle>{uiStore.pleaseSaveYourSettingsText}</PageSubtitle>
          {(data || dataLoaded) && children({ getElement, settings })}
        </>
      )}
      <LoadingBackdrop open={!settingsLoaded || !dataLoaded} />
      {settingsLoaded && !settings?.categories && <p>Keine Einstellungen vorhanden...</p>}
    </>
  )
}

export default observer(SettingsPage)
