import { Logger } from '../utils/log'

export type OnSaveObserver = () => void

class OnSaveNotifier {
  private observers: OnSaveObserver[] = []

  public subscribe(observer: OnSaveObserver) {
    this.observers.push(observer)
  }

  public unsubscribe(observerToRemove: OnSaveObserver) {
    this.observers = this.observers.filter((observer) => observer !== observerToRemove)
  }

  public notify() {
    Logger.log('notifying ' + this.observers.length + ' components')
    this.observers.forEach((observer) => observer())
  }
}

const onSaveNotifier = new OnSaveNotifier()

export default onSaveNotifier
