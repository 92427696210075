import { createStyles, makeStyles, Theme, Box, Typography } from '@material-ui/core'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { FileMeta, FileUploadListItem } from '../../types'
import { useSettings } from '../context/SettingsContext'
import { useUploadsListContext } from '../context/UploadsListContext'
import { defaultProps } from '../utils/defaultProps'
import formatBytes from '../utils/formatBytes'
import FileUploadList from './FileUploadList'

function readFile(file: File): Promise<string> {
  const fileReader = new FileReader()

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    fileReader.onload = () => {
      resolve(fileReader.result as string)
    }
    fileReader.readAsDataURL(file)
  })
}

const FileUpload: React.FC = () => {
  const classes = useStyles()
  const { settings } = useSettings()
  const { fileUploadList, setFileUploadList } = useUploadsListContext()
  const {
    fileUploadCallback,
    renameFile,
    acceptedTypes = defaultProps.acceptedTypes,
    maxFileSize,
    maxNumFiles,
    uploadCallbackReturnType = defaultProps.uploadCallbackReturnType,
    dropAreaIdleText = defaultProps.dropAreaIdleText,
    dropAreaDraggingText = defaultProps.dropAreaDraggingText,
    acceptedFormatsText,
    maxFileSizeText,
    maxNumFilesText
  } = settings

  function onDrop(acceptedFiles: File[]) {
    let newFileUploadList: FileUploadListItem[] = acceptedFiles
      .map((element: File) => {
        const filename =
          renameFile && typeof renameFile === 'function' ? renameFile(element.name) : element.name
        return { fileName: filename, status: 0 }
      })
      .concat(fileUploadList)
    if (setFileUploadList) {
      setFileUploadList(newFileUploadList)
    }

    // Loop through dropped files
    acceptedFiles.forEach((file: File, index: number) => {
      ;(async () => {
        const fileMeta: FileMeta = { fileName: file.name, type: file.type, size: file.size }
        if (fileUploadCallback) {
          let result: boolean
          if (uploadCallbackReturnType === 'base64') {
            const fileBase64 = await readFile(file)
            result = await fileUploadCallback(fileBase64, fileMeta)
          } else {
            result = await fileUploadCallback(file, fileMeta)
          }
          newFileUploadList = [...newFileUploadList]
          newFileUploadList[index].status = result ? 1 : -1
          if (setFileUploadList) {
            setFileUploadList(newFileUploadList)
          }
        }
      })()
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedTypes,
    maxSize: maxFileSize,
    maxFiles: maxNumFiles
  })

  return (
    <Box p={3}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        px={3}
        py={6}
        mb={4}
        boxShadow={2}
        bgcolor='grey.300'
        {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant='body1' className={classes.dropzoneMsg}>
            {dropAreaDraggingText}
          </Typography>
        ) : (
          <Typography variant='body1' className={classes.dropzoneMsg}>
            {dropAreaIdleText}
          </Typography>
        )}
        {acceptedFormatsText && acceptedTypes && (
          <Typography variant='body2'>{`${acceptedFormatsText} ${acceptedTypes}`}</Typography>
        )}
        {maxFileSizeText && maxFileSize && (
          <Typography variant='body2'>{`${maxFileSizeText} ${formatBytes(
            maxFileSize
          )}`}</Typography>
        )}
        {maxNumFilesText && maxNumFiles && (
          <Typography variant='body2'>{`${maxNumFilesText} ${maxNumFiles}`}</Typography>
        )}
      </Box>
      <FileUploadList fileUploadList={fileUploadList} />
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzoneMsg: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2)
      }
    }
  })
)

export default FileUpload
