import React, { ReactElement, ReactNode } from 'react'

import { createStyles, makeStyles, Theme, CardContent, Card } from '@material-ui/core'

interface IInputsContainerProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

const InputsContainer: React.FC<IInputsContainerProps> = ({ children }: IInputsContainerProps) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent>{children}</CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: theme.custom.defaultBackgroundColor,
      margin: theme.spacing(0),
      [theme.breakpoints.up('xs')]: {
        margin: theme.spacing(2)
      }
    }
  })
)

export default InputsContainer
