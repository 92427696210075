import { Typography } from '@material-ui/core'
import React from 'react'
import PageTitle from '../components/atoms/PageTitle'

const Fallback404: React.FC = () => (
  <>
    <PageTitle>Nicht gefunden</PageTitle>
    <Typography variant='body1'>Die von Ihnen angeforderte Seite wurde nicht gefunden.</Typography>
  </>
)

export default Fallback404
