import React, { ReactElement, useState } from 'react'
import { createStyles, makeStyles, Button, Menu, ButtonProps } from '@material-ui/core'

// Props handed down to children components
interface IInjectedProps {
  closeMenu: () => void
}

interface IBtnWtPopupSelectProps {
  btnText: string
  baseId?: string
  btnIcon?: ReactElement
  children?: (props: IInjectedProps) => JSX.Element | JSX.Element[]
}

/**
 * Button that opens a popup-menu.
 * @param btnText - Text displayed on the button.
 * @param baseId - Optional base for the id of the menu - used for the 'aria-
 * controls' attribute. If not passed, a default string will be used.
 * @param btnIcon - Optional MUI Icon Element that will be passed as 'startIcon'
 * attribute to the button
 * @param children - The menu items should be passed to the conponent as render
 * props which receive a function as prop that can be used to close the menu.
 */
const BtnWtPopupSelect: React.FC<IBtnWtPopupSelectProps & ButtonProps> = ({
  btnText,
  baseId,
  btnIcon,
  children,
  ...btnProps
}: IBtnWtPopupSelectProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  function handleOpenClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function closeMenu() {
    handleClose()
  }

  return (
    <>
      <Button
        classes={{
          root: classes.btnRoot
        }}
        variant='contained'
        color='primary'
        aria-haspopup='true'
        aria-controls={baseId ? `${baseId}_menu` : 'popup_menu'}
        startIcon={btnIcon || null}
        onClick={handleOpenClick}
        {...btnProps}>
        {btnText}
      </Button>

      {children && (
        <Menu
          id={baseId ? `${baseId}_menu` : 'popup_menu'}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}>
          {children({ closeMenu })}
        </Menu>
      )}
    </>
  )
}

const useStyles = makeStyles(
  createStyles({
    btnRoot: {
      width: 'max-content'
    }
  })
)

export default BtnWtPopupSelect
