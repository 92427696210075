import React from 'react'
import { observer } from 'mobx-react-lite'

import { useStore } from '../stores/StoreContext'
import SettingsAccordion from '../components/organisms/SettingsAccordion'
import SettingsPage from '../components/SettingsPage'
import { useReactToChanges } from '../utils/customHooks'

const Notifications: React.FC = observer(() => {
  const settingsId = 'notifiersettings'
  const { uiStore, formStore } = useStore()
  const hasChanges = formStore.settingHasChanges(formStore.NotifierSettingsData)

  useReactToChanges(hasChanges)

  function categoryHasChanges(categoryId: string) {
    return formStore.categoryHasChanges(categoryId, formStore.NotifierSettingsData)
  }

  return (
    <SettingsPage
      settings={formStore.NotifierSettings}
      settingsLoaded={formStore.NotifierSettingsLoaded}
      data={formStore.NotifierSettingsData}
      dataLoaded={formStore.NotifierSettingsDataLoaded}
      setDataFunc={formStore.setNotifierData}
      updateDataFnc={formStore.updateNotifierData}
      receiveDataFunc={formStore.receiveNotifierSettingsData}>
      {({ settings, getElement }) => (
        <SettingsAccordion
          expandedPanels={uiStore.expandedPanels}
          setExpandedPanel={uiStore.setExpandedPanel}
          settingsId={settingsId}
          settings={settings}
          getElement={getElement}
          categoryHasChanges={categoryHasChanges}
        />
      )}
    </SettingsPage>
  )
})

export default Notifications
