import React, { useState } from 'react'
import { createStyles, makeStyles, Button, Grid } from '@material-ui/core'

import PageTitle from '../components/atoms/PageTitle'
import { useStore } from '../stores/StoreContext'
import { observer } from 'mobx-react-lite'
import TextFieldLabelOnTop from '../components/molecules/TextFieldLabelOnTop'
import PageSubtitle from '../components/atoms/PageSubtitle'

interface IErrors {
  username?: string
  password?: string
  rptPassword?: string
}

/** observer-Component */
const User: React.FC = () => {
  const classes = useStyles()
  const minPasswordLength: number = process.env.REACT_APP_MIN_PASSWORD_LENGTH
    ? parseInt(process.env.REACT_APP_MIN_PASSWORD_LENGTH, 10)
    : 8
  const regexStringPW = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d*.!@$%^§&(){}[\\]:;<>,.?\\/~_+\\-=|\]{${minPasswordLength},}$`
  const pwRegEx = new RegExp(regexStringPW)
  const { userStore } = useStore()
  const [username, setUsername] = useState(userStore.user?.username || '')
  const [password, setPassword] = useState('')
  const [rptPassword, setRptPassword] = useState('')
  const [errors, setErrors] = useState<IErrors>({})

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'username') {
      setUsername(event.target.value)
    } else if (event.target.name === 'password') {
      setPassword(event.target.value)
    } else if (event.target.name === 'rptPassword') {
      setRptPassword(event.target.value)
    }
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const tmpErrors: IErrors = {}
    if (!username) {
      tmpErrors.username = 'Bitte geben Sie ihren Benutzernamen ein.'
    }

    if (!password) {
      tmpErrors.password = 'Bitte geben Sie ihr Passwort ein.'
    }

    if (!pwRegEx.test(password)) {
      tmpErrors.password = `Das Passwort muss mindestens ${minPasswordLength} Zeichen 
      lang sein, einen Groß- und keinen Kleinbuchstaben sowie eine Nummer enthalten.`
    }

    if (password !== rptPassword) {
      tmpErrors.rptPassword = 'Die Passwörter müssen identisch sein.'
    }

    if (Object.entries(tmpErrors).length > 0) {
      setErrors(tmpErrors)
      return
    } else {
      setErrors({})
    }

    await userStore.updateUser({
      username,
      password
    })
  }

  return (
    <>
      <PageTitle>Mein Zugang</PageTitle>
      <PageSubtitle>{`
        Erlaubte Sonderzeichen für Passwörter: * . ! @ $ ${String.fromCharCode(
          167
        )} % ${String.fromCharCode(94)} ${String.fromCharCode(38)} ( ) ${String.fromCharCode(
        123
      )} ${String.fromCharCode(125)} [ ]
        : ; ${String.fromCharCode(60)} ${String.fromCharCode(62)} , . ? / ${String.fromCharCode(
        732
      )} _ + - = |
      `}</PageSubtitle>
      <form className={classes.formRoot} onSubmit={handleSubmit}>
        {/* Username */}
        <Grid container spacing={3}>
          <Grid item xs={12} container direction='column'>
            <TextFieldLabelOnTop
              label='Benutzername'
              inputprops={{
                maxLength: 2000
              }}
              id='username'
              name='username'
              value={username}
              variant='outlined'
              size='small'
              onChange={handleChange}
              error={errors.username}
              helperText={errors.username ? errors.username : null}
            />
          </Grid>

          {/* Password */}
          <Grid item xs={12} container direction='column'>
            <TextFieldLabelOnTop
              type='password'
              label='Neues Passwort'
              inputprops={{
                maxLength: 2000
              }}
              id='password'
              name='password'
              value={password}
              variant='outlined'
              size='small'
              onChange={handleChange}
              error={errors.password}
              helperText={errors.password ? errors.password : null}
            />
          </Grid>

          {/* Password Repeat */}
          <Grid item xs={12} container direction='column'>
            <TextFieldLabelOnTop
              type='password'
              label='Neues Passwort wiederholen'
              inputprops={{
                maxLength: 2000
              }}
              id='rptPassword'
              name='rptPassword'
              value={rptPassword}
              variant='outlined'
              size='small'
              onChange={handleChange}
              error={errors.rptPassword}
              helperText={errors.rptPassword ? errors.rptPassword : null}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant='contained' color='primary' type='submit'>
              Speichern
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

const useStyles = makeStyles(
  createStyles({
    formRoot: {
      width: '100%',
      maxWidth: '400px'
    }
  })
)

export default observer(User)
