import React from 'react'
import { SvgIconProps, Tooltip } from '@material-ui/core'

interface TooltipWithTriggerIconProps {
  text: string
  trigger: React.ReactElement<SvgIconProps>
}

const TooltipWithTriggerIcon: React.FC<TooltipWithTriggerIconProps> = ({
  text,
  trigger
}: TooltipWithTriggerIconProps) => (
  <Tooltip disableFocusListener title={text}>
    {trigger}
  </Tooltip>
)

export default TooltipWithTriggerIcon
