import React, { FC, ReactElement, ReactNode, useContext, useState } from 'react'
import { ReactMediaLibraryProps } from '../../types'

interface SettingsProviderProps {
  children: FC | ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

interface SettingsProviderType {
  settings: ReactMediaLibraryProps
  setSettings?: (settings: ReactMediaLibraryProps) => void
}

const defaultSettings: ReactMediaLibraryProps = {
  show: false,
  fileLibraryList: []
}

const SettingsContext = React.createContext<SettingsProviderType>({
  settings: defaultSettings
})

const SettingsProvider: FC<SettingsProviderProps> = ({ children }: SettingsProviderProps) => {
  const [settings, setSettings] = useState<ReactMediaLibraryProps>(defaultSettings)

  const setMediaLibrarySettings = (newSettings: ReactMediaLibraryProps) => {
    setSettings({ ...settings, ...newSettings })
  }

  return (
    <SettingsContext.Provider value={{ settings, setSettings: setMediaLibrarySettings }}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider

export const useSettings = () => useContext(SettingsContext)
