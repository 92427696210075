import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import {
  createStyles,
  makeStyles,
  Theme,
  Button,
  Card,
  CardActions,
  CardContent,
  Hidden,
  Typography,
  Box
} from '@material-ui/core'
import cuid from 'cuid'

import { useStore } from '../stores/StoreContext'
import { ICategory, IInputElement } from '../models/settingsInterfaces'
import { IQuestion, TQuestionType } from '../models/questionsInterfaces'
import { useUtilStyles } from '../styles/useUtilStyles'
import TextElementQuestion from '../components/inputElements/TextElementQuestion'
import QuestionsAccordion from '../components/organisms/QuestionsAccordion'
import RangeElement from '../components/inputElements/RangeElement'
import PageTitle from '../components/atoms/PageTitle'
import onSaveNotifier from '../utils/OnSaveNotifier'
import { Alert, AlertTitle } from '@material-ui/lab'
import AddQuestionFloatingBtn from '../components/organisms/AddQuestionFloatingBtn'
import { useReactToChanges, useScrollToTarget, useSettingsWithErrors } from '../utils/customHooks'
import LoadingBackdrop from '../components/fallbacks/LoadingBackdrop'
import { Logger } from '../utils/log'
import MultiSelectElement from '../components/inputElements/MultiSelectElement'
import PageSubtitle from '../components/atoms/PageSubtitle'

/** observer-Component */
const WTQuestions: React.FC = observer(() => {
  const utilClasses = useUtilStyles()
  const classes = useStyles()
  const { formStore, uiStore } = useStore()

  const hasChanges = formStore.questionsDataHasChanges
  useReactToChanges(hasChanges)

  /* Used to identify which questions have invalid values in their inputs */
  const { settingsWtErrors, setSettingError, clearSettingError } = useSettingsWithErrors()

  /*  refs for used for scrolling to bottom when new question is added */
  const { scrollTarget, scrollToTargetAfterRender } = useScrollToTarget()

  /* Max number of questions */
  const maxNumQuestions = process.env.REACT_APP_MAX_NUM_QUESTIONS ?? 10

  Logger.log('QuestionsData after load: ', toJS(formStore.QuestionsData))
  // Logger.log('QuestionsDataINitial after load: ', toJS(formStore.QuestionsDataInitial))

  /*  Receive configuration and questions and subscribe to 'save' event */
  useEffect(() => {
    onSaveNotifier.subscribe(onSave)

    const initData = async () => {
      await formStore.receiveConfiguration()
      await formStore.receiveQuestionsData()
    }
    initData()

    return () => {
      onSaveNotifier.unsubscribe(onSave)
    }
  }, [])

  /* Disable saving as long as there are errors */
  useEffect(() => {
    if (!!settingsWtErrors.length) {
      uiStore.setSavingDisabled(true)
    } else {
      uiStore.setSavingDisabled(false)
    }

    return () => {
      uiStore.setSavingDisabled(false)
    }
  }, [settingsWtErrors])

  /* Send data to store once 'save' event is fired */
  async function onSave() {
    await formStore.updateQuestionsData()
    await formStore.receiveQuestionsData()
  }

  /* Get available types of question elements */
  const questionCategory = toJS(formStore.Questions)?.categories?.find(
    (category) => category.id === 'questions' && category.type === 'category'
  ) as ICategory
  const questionELements = questionCategory?.elements || []

  /** Create Input Element depending on element type */
  const getElement = useCallback((questionData: IQuestion, key: string) => {
    switch (questionData.type) {
      case 'range':
        return (
          <RangeElement
            questionData={questionData as IQuestion}
            baseId={key}
            updateData={receiveQuestionUpdate}
            setSettingError={setSettingError}
            clearSettingError={clearSettingError}
            key={key}
          />
        )
      case 'multi':
        return (
          <MultiSelectElement
            questionData={questionData as IQuestion}
            baseId={key}
            maxNumSubQuestions={formStore.maxNumSubQuestions}
            updateData={receiveQuestionUpdate}
            setSettingError={setSettingError}
            clearSettingError={clearSettingError}
            updateSubQuestion={formStore.updateSubQuestion}
            onSubQuestionAdd={formStore.addEmptySubQuestion}
            onSubQuestionDelete={handleSubQuestionDelete}
            key={key}
          />
        )
      default:
        return (
          <TextElementQuestion
            questionData={questionData as IQuestion}
            baseId={key}
            updateData={receiveQuestionUpdate}
            setSettingError={setSettingError}
            clearSettingError={clearSettingError}
            key={key}
          />
        )
    }
  }, [])

  /*  Create new question */
  const addQuestionWithType = useCallback(
    (type: TQuestionType): void => {
      const newEmptyQuestion = getEmptyQuestionData(type, formStore.numQuestions + 1)
      formStore.addQuestion(newEmptyQuestion)

      // open Accordion of new question
      uiStore.setExpandedPanel('questions', newEmptyQuestion.id)

      // scroll to bottom after next render
      scrollToTargetAfterRender.current = true
    },
    [formStore.numQuestions]
  )

  /*  Get default data for new question */
  const getEmptyQuestionData = useCallback(
    (type: TQuestionType, order: number): IQuestion => {
      const o = typeof order ? formStore.numQuestions + 1 : order
      return {
        id: cuid(),
        order: o,
        type,
        question: ''
      }
    },
    [formStore.numQuestions]
  )

  /* ----- Functions that will be passed input elements via getElement ----*/
  /* Callback that gets handed down to children */
  const receiveQuestionUpdate = useCallback((id: string, field: string, value: unknown) => {
    formStore.updateQuestion(id, field, value)
  }, [])

  const handleSubQuestionDelete = useCallback((questionId: string, subQuestionIndex: number) => {
    uiStore.openConfirmDialog({
      title: 'Frage löschen?',
      description:
        'Bitte bestätigen Sie, daß Sie die Antwort wirklich löschen möchten. (Die Daten werden erst endgültig gelöscht, wenn Sie auf "Speichern" klicken.)',
      onConfirm: () => formStore.deleteSubQuestion(questionId, subQuestionIndex)
    })
  }, [])

  /* ----- Functions that will be passed down to QuestionsAccordion component ----*/
  /** Set open panel in accordion */
  const setExpandedPanel = useCallback((isExpanded: boolean, panelId: string) => {
    uiStore.setExpandedPanel('questions', isExpanded ? panelId : false)
  }, [])

  const handleDelete = useCallback((questionId: string) => {
    uiStore.openConfirmDialog({
      title: 'Frage löschen?',
      description:
        'Bitte bestätigen Sie, daß Sie die Frage wirklich löschen möchten. (Die Daten werden erst endgültig gelöscht, wenn Sie auf "Speichern" klicken.)',
      onConfirm: () => formStore.deleteQuestion(questionId)
    })
  }, [])

  return (
    <>
      {formStore.QuestionsLoaded && formStore.Questions && (
        <>
          <PageTitle>{formStore.Questions.title}</PageTitle>
          <PageSubtitle>{uiStore.pleaseSaveYourSettingsText}</PageSubtitle>

          {/* Buttons for adding questions */}
          {!!questionELements.length && (
            <Card className={classes.containerCard}>
              <CardContent>
                <Typography className={utilClasses.mb_3} variant='h3' component='h2'>
                  Fragen hinzufügen
                </Typography>
                <CardActions className={classes.p_0}>
                  {formStore.numQuestions < maxNumQuestions ? (
                    questionELements.map((element: IInputElement) => (
                      <Button
                        key={`btn_${element.id}`}
                        className={classes.addQuestionBtn}
                        variant='contained'
                        size='small'
                        color='primary'
                        onClick={() => addQuestionWithType(element.type as TQuestionType)}>
                        {(element.type as string).toUpperCase()} Frage{' '}
                        <Hidden xsDown>hinzufügen</Hidden>
                      </Button>
                    ))
                  ) : (
                    <Alert severity='warning'>
                      <AlertTitle>{`Sie können maximal ${maxNumQuestions} Fragen hinzufügen.`}</AlertTitle>
                    </Alert>
                  )}
                </CardActions>
              </CardContent>
            </Card>
          )}

          {/* All questions */}
          {formStore.QuestionsData && (
            <>
              <Box p={1}>
                <Typography className={classes.mb_1} variant='h3' component='h2'>
                  Fragen bearbeiten
                </Typography>
                <Typography variant='body1' component='p'>
                  Klicken Sie auf einen Panel um die Frage zu bearbeiten. Sie können die Reihenfolge
                  durch ziehen oder mit Hilfe der Pfeiltasten ändern.
                </Typography>
              </Box>
              <QuestionsAccordion
                questions={formStore.QuestionsData.questions}
                questionsWtErrors={settingsWtErrors}
                questionTitleMaxLength={uiStore.questionTitleMaxLength}
                expandedPanels={uiStore.expandedPanels}
                setExpandedPanel={setExpandedPanel}
                onDelete={handleDelete}
                onMove={formStore.moveQuestion}
                onMoveUpOrDown={formStore.moveQuestionUpOrDown}
                getElement={getElement}
                getQuestionHasChanges={formStore.questionHasChanges}
              />
            </>
          )}

          {formStore.numQuestions < maxNumQuestions && (
            <AddQuestionFloatingBtn
              questionELements={questionELements}
              onAddQuestion={addQuestionWithType}
            />
          )}
        </>
      )}
      <LoadingBackdrop open={!formStore.QuestionsLoaded || !formStore.QuestionsDataLoaded} />
      {formStore.QuestionsLoaded && !formStore.Questions?.categories && (
        <p>Keine Einstellungen vorhanden...</p>
      )}
      <div ref={scrollTarget} />
    </>
  )
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addQuestionBtn: {
      textTransform: 'none'
    },
    containerCard: {
      backgroundColor: theme.custom.defaultBackgroundColor,
      marginBottom: theme.spacing(3)
    },
    mb_1: {
      marginBottom: theme.spacing(1)
    },
    p_0: {
      padding: 0
    }
  })
)

export default WTQuestions
