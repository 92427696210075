import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  createStyles,
  makeStyles,
  Grid,
  Button,
  Theme,
  Drawer,
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
  IconButton
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'

import HouseGraphicParent from '../housegraphic/HouseGraphicParent'
import energyhouse from './assets/energyhouse.svg'
import ContentEditor from './ContentEditor'
import EHConfigCheckboxes from './EHConfigCheckboxes'
import {
  IEHContents,
  IShowInGraphicOptionalLayer,
  ISpecialProp
} from '../../../models/ehInterfaces'
import HideOnScroll from '../../utilComponents/HideOnScroll'
import { IPage } from '../../../models/cmsInterfaces'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import HouseGraphicFallback from './HouseGraphicFallback'

export interface IConfigView {
  placeImagePointId: string
}

interface IHouseGraphicConfigProps {
  cmsData: IPage[] | undefined
  contentsData?: IEHContents
  contentHasCmsPage: (id: string) => boolean
  specialProps: ISpecialProp[]
  setShowInGraphic: (prop: string, show: 'y' | 'n') => void
  setShowHoverAreaContents: (enabled: boolean) => void
  houseGraphicMinHeight: number
  appBarLeftOffset: number
  appBarLeftOffsetBreakPoint: Breakpoint
  showEHGraphicBreakpoint: Breakpoint
  appBarTopOffset: number
  btnCanvasViewBoxSize: number
  showInGraphicAllOptionalLayers: IShowInGraphicOptionalLayer[]
  placingActiveBtnColor: string
  selectedContent: string | undefined
  onAddContent: (pageId: string) => string
  onSetProduct: (contentId: string, productId: string) => void
  onSetIcon: (contentId: string, iconId: string) => void
  onSetHoverSpecial: (contentId: string, specialId: string) => void
  onSetSpecialProp: (contentId: string, specialPropId: string) => void
  onSetTitle: (contentId: string, title: string) => void
  onSetPageUrl: (contentId: string, url: string) => void
  onDeleteContent: (contentId: string) => void
  setGraphicsPositionInContent: (contentId: string, xpos: number, ypos: number) => void
  setSelectedContent: (contentId: string | undefined) => void
}

/** observer-Component */
const HouseGraphicConfig: React.FC<IHouseGraphicConfigProps> = ({
  cmsData,
  contentsData,
  contentHasCmsPage,
  specialProps,
  setShowInGraphic,
  setShowHoverAreaContents,
  houseGraphicMinHeight,
  btnCanvasViewBoxSize,
  appBarLeftOffset,
  appBarLeftOffsetBreakPoint,
  showEHGraphicBreakpoint,
  appBarTopOffset,
  showInGraphicAllOptionalLayers,
  placingActiveBtnColor,
  selectedContent,
  onAddContent,
  onSetProduct,
  onSetIcon,
  onSetHoverSpecial,
  onSetSpecialProp,
  onSetTitle,
  onSetPageUrl,
  onDeleteContent,
  setGraphicsPositionInContent,
  setSelectedContent
}: IHouseGraphicConfigProps) => {
  const classes = useStyles({
    appBarLeftOffset,
    appBarLeftOffsetBreakPoint,
    appBarTopOffset,
    houseGraphicMinHeight
  })
  const [configView, setConfigView] = useState<IConfigView>({
    placeImagePointId: ''
  })

  const [drawerOpen, setDrawerOpen] = useState(false)

  // Energy house graphic is only shown on desktop
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(showEHGraphicBreakpoint))

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  function closeDrawer() {
    setDrawerOpen(false)
  }

  function placeOneImagePointModeOn(id: string) {
    if (configView.placeImagePointId === id) {
      setConfigView({ ...configView, placeImagePointId: '' })
    } else {
      setConfigView({ ...configView, placeImagePointId: id })
    }
  }

  function setOpenHoverAreas(open: boolean) {
    setShowHoverAreaContents(open)
  }

  function toggleSpecialProduct(propName: string) {
    if (contentsData?.meta?.showInGraphic) {
      setShowInGraphic(propName, contentsData.meta.showInGraphic[propName] === 'y' ? 'n' : 'y')
    }
  }

  return (
    <>
      {/* Graphic configuration checkboxes */}
      <HideOnScroll>
        <AppBar position='fixed' classes={{ root: classes.appBarRoot }} onClick={toggleDrawer}>
          <Toolbar classes={{ root: classes.toolbarRoot }}>
            <Button endIcon={<ExpandMoreIcon />} component='span'>
              Darstellungsoptionen
            </Button>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Drawer anchor='top' open={drawerOpen} onClose={closeDrawer}>
        <Toolbar variant='dense' classes={{ root: classes.toolbarRoot }}>
          <IconButton aria-label='Schliessen' onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <EHConfigCheckboxes
          contentsData={contentsData}
          hoverAreasOpen={
            contentsData?.meta?.showHoverAreaContents === undefined
              ? true
              : contentsData.meta.showHoverAreaContents
          }
          onChangeOpenHoverAreas={setOpenHoverAreas}
          onToggleSpecialProduct={toggleSpecialProduct}
          showInGraphicAllOptionalLayers={showInGraphicAllOptionalLayers}
        />
      </Drawer>

      <Grid container spacing={2}>
        {/* House Graphic and edit box */}
        <Grid item xs={12} md={8} className={clsx({ [classes.graphicParentContainer]: isDesktop })}>
          {isDesktop ? (
            <HouseGraphicParent
              contentsData={contentsData}
              contentHasCmsPage={contentHasCmsPage}
              showInGraphicAllOptionalLayers={showInGraphicAllOptionalLayers}
              configView={configView}
              svgPath={energyhouse}
              openAppHoverAreas={
                contentsData?.meta?.showHoverAreaContents === undefined
                  ? true
                  : contentsData.meta.showHoverAreaContents
              }
              houseGraphicMinHeight={houseGraphicMinHeight}
              setGraphicsPositionInContent={setGraphicsPositionInContent}
              btnCanvasViewBoxSize={btnCanvasViewBoxSize}
            />
          ) : (
            <HouseGraphicFallback />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentEditor
            contentsData={contentsData}
            contentHasCmsPage={contentHasCmsPage}
            cmsData={cmsData}
            specialProps={specialProps}
            placeOneImagePointModeOn={placeOneImagePointModeOn}
            placingImagePoint={!!configView.placeImagePointId}
            showEHGraphicBreakpoint={showEHGraphicBreakpoint}
            placingActiveBtnColor={placingActiveBtnColor}
            selectedContent={selectedContent}
            onAddContent={onAddContent}
            onSetProduct={onSetProduct}
            onSetIcon={onSetIcon}
            onSetHoverSpecial={onSetHoverSpecial}
            onSetSpecialProp={onSetSpecialProp}
            onSetTitle={onSetTitle}
            onSetPageUrl={onSetPageUrl}
            onDeleteContent={onDeleteContent}
            setSelectedContent={setSelectedContent}
          />
        </Grid>
      </Grid>
    </>
  )
}

interface IStyleProps {
  appBarLeftOffset: number | undefined
  appBarLeftOffsetBreakPoint: Breakpoint | undefined
  appBarTopOffset: number | undefined
  houseGraphicMinHeight: number | undefined
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBarRoot: (props: IStyleProps) => ({
      top: props.appBarTopOffset || 56,
      zIndex: 30,
      paddingLeft: 0,
      right: 0,
      backgroundColor: theme.custom.defaultBackgroundColor,
      cursor: 'pointer',
      [theme.breakpoints.up(props.appBarLeftOffsetBreakPoint || 'md')]: {
        paddingLeft: props.appBarLeftOffset || 240
      }
    }),
    toolbarRoot: {
      justifyContent: 'flex-end'
    },
    graphicParentContainer: {
      minHeight: (props: IStyleProps) => props.houseGraphicMinHeight || 600,
      position: 'relative',
      overflowX: 'hidden',
      overflowY: 'hidden'
    }
  })
)

export default observer(HouseGraphicConfig)
