import React, { useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { createStyles, makeStyles, Theme, Box, MenuItem } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd'

import CMSSubModule from './CMSSubModule'
import { observer } from 'mobx-react-lite'
import {
  IAtomTemplate,
  IModulePage,
  IModuleTemplate,
  IPage,
  ISubModulePage,
  ISubModuleTemplate
} from '../../models/cmsInterfaces'
import { IFeedback, IPagesListByTemplateId } from '../../stores/CMSStore'
import BtnWtPopupSelect from './BtnWtPopupSelect'
import { FileLibraryListItem } from '../../mediaLibrary/types'
import CMSModuleAccordionPanel from './CMSModuleAccordionPanel'
import { useScrollToId } from '../../utils/customHooks'

interface ICMSModuleProps {
  page: IPage | undefined
  pageModule: IModulePage
  createPage: (templateId: string, name?: string | undefined) => Promise<any>
  templateModule: IModuleTemplate
  pagesListByTemplateId: IPagesListByTemplateId
  getPagesByTemplateId: (id: string) => Promise<void>
  onFileSelectRequest: (
    onSelect: (item: FileLibraryListItem) => void,
    acceptedTypes: string
  ) => void
  onDeleteModule: (page: IPage | undefined, pageModuleId: string | undefined) => void
  checkIfSubAddAllowed: (
    templateModule: IModuleTemplate,
    pageModule: IModulePage,
    tSubModule: ISubModuleTemplate
  ) => boolean
  checkIfSubDeleteAllowed: (
    templateSubModule: ISubModuleTemplate,
    pageModule: IModulePage
  ) => boolean
  checkIfSubmodulePositionFixed: (pageSubModule: ISubModulePage) => boolean
  addSubModule: (
    templateModule: IModuleTemplate,
    pageModule: IModulePage,
    subModule: ISubModuleTemplate
  ) => IFeedback
  onDeleteSubModule: (
    templateSubModule: ISubModuleTemplate,
    pageSubModule: ISubModulePage,
    pageModule: IModulePage
  ) => void
  onChangeSubModulePosUpOrDown: (
    pageModule: IModulePage,
    pageSubModuleIndex: number,
    direction: 'up' | 'down'
  ) => IFeedback
  onChangeSubModulePos: (
    pageModule: IModulePage,
    subModuleId: string,
    oldIndex: number,
    newIndex: number
  ) => IFeedback
  onSubModuleTextInput: (atom: IAtomTemplate, value: string, pageSubModule: ISubModulePage) => void
  onSubModuleSelectFollowUp: (
    atom: IAtomTemplate,
    page: IPage,
    pageSubModule: ISubModulePage
  ) => void
  onSubModuleSelectFile: (atom: IAtomTemplate, value: string, pageSubModule: ISubModulePage) => void
  checkIfFileExists: (filename: string) => boolean
  displayError: (error: string) => void
  scrollYOffset?: number
  expanded: boolean
  panelDisabled: boolean
  onPanelClick: (panelName: string) => void
  moduleHasChanges?: (pageModule: IModulePage) => boolean
  subModuleHasChanges?: (pageModule: IModulePage, pageSubModule: ISubModulePage) => boolean
}

/** observer-Component */
const CMSModule: React.FC<ICMSModuleProps> = ({
  page,
  pageModule,
  createPage,
  templateModule,
  pagesListByTemplateId,
  getPagesByTemplateId,
  onFileSelectRequest,
  checkIfSubAddAllowed,
  checkIfSubDeleteAllowed,
  checkIfSubmodulePositionFixed,
  onDeleteModule,
  addSubModule,
  onDeleteSubModule,
  onChangeSubModulePos,
  onChangeSubModulePosUpOrDown,
  onSubModuleTextInput,
  onSubModuleSelectFollowUp,
  onSubModuleSelectFile,
  checkIfFileExists,
  displayError,
  scrollYOffset,
  expanded,
  panelDisabled,
  onPanelClick,
  moduleHasChanges,
  subModuleHasChanges
}: ICMSModuleProps) => {
  const classes = useStyles()
  const hasChanges = moduleHasChanges ? moduleHasChanges(pageModule) : false
  const { setScrollToId } = useScrollToId(scrollYOffset ? -scrollYOffset : undefined)
  const [availableSubModules, setAvailableSubModules] = useState<ISubModuleTemplate[]>([])
  const [openSubPanel, setSubOpenPanel] = useState<string>(
    pageModule.subModules.length === 1 && !!pageModule.subModules[0].meta.global.uid
      ? pageModule.subModules[0].meta.global.uid
      : ''
  )

  function getAvailableSubModules() {
    let availableSubModules: ISubModuleTemplate[] = []
    if (templateModule.subModules.length) {
      const subModules = toJS(templateModule.subModules)
      availableSubModules = subModules.filter((tSubModule: ISubModuleTemplate) =>
        checkIfSubAddAllowed(templateModule, pageModule, tSubModule)
      )
    }

    setAvailableSubModules(availableSubModules)
  }

  function handleModuleDelete() {
    onDeleteModule(page, pageModule.meta.global.uid)
  }

  function handleSubModuleAdd(index: number) {
    const feedback: IFeedback = addSubModule(templateModule, pageModule, availableSubModules[index])

    if (feedback.success) {
      /* Open newly created panel and scroll it into view on next render */
      const newSubModule = pageModule?.subModules?.[pageModule.subModules.length - 1]

      if (newSubModule && newSubModule.meta.global.uid) {
        setSubOpenPanel(newSubModule.meta.global.uid)
        setScrollToId(newSubModule.meta.global.uid)
      }
    } else {
      displayError(feedback.error)
    }
  }

  function handlePanelClick() {
    if (pageModule.meta.global.uid) {
      onPanelClick(pageModule.meta.global.uid)
    }
  }

  function onDragEnd(result: DropResult) {
    const { draggableId, destination, source } = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    if (destination?.index) {
      onChangeSubModulePos(pageModule, draggableId, source.index, destination.index)
    }
  }

  /* Load info about submodules that are available for adding to this module */
  useEffect(() => {
    getAvailableSubModules()
  }, [templateModule, pageModule])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <CMSModuleAccordionPanel
        id={pageModule.meta.global.uid || pageModule.meta.global.id}
        title={templateModule?.meta?.cms?.name || 'Modul'}
        expanded={expanded}
        panelDisabled={panelDisabled}
        onPanelClick={handlePanelClick}
        onDeleteModule={handleModuleDelete}
        hasChanges={hasChanges}>
        <>
          {/* Button for adding new submodules */}
          {!!availableSubModules.length && (
            <Box className={classes.cmsSubmoduleContainer}>
              <BtnWtPopupSelect btnText='Modul Hinzufügen' btnIcon={<AddIcon />} size='small'>
                {/* Pass available submodules as render props */}
                {({ closeMenu }) => {
                  if (availableSubModules) {
                    return availableSubModules.map(
                      (subModule: ISubModuleTemplate, index: number) => (
                        <MenuItem
                          key={subModule.conf.meta.global.id}
                          onClick={() => {
                            handleSubModuleAdd(index)
                            closeMenu()
                          }}>
                          {subModule.conf.meta.cms.name}
                        </MenuItem>
                      )
                    )
                  } else {
                    return <MenuItem>Keine Module verügbar</MenuItem>
                  }
                }}
              </BtnWtPopupSelect>
            </Box>
          )}
          {/* Submodules */}
          <Droppable droppableId={pageModule.meta.global.uid || pageModule.meta.global.id}>
            {(provided: DroppableProvided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {pageModule.subModules.map(
                  (pageSubModule: ISubModulePage, pageSubModuleIndex: number) => {
                    let templateSubModule: ISubModuleTemplate | undefined = undefined
                    templateModule?.subModules.forEach((subModule: ISubModuleTemplate) => {
                      if (subModule.conf.meta.global.id === pageSubModule.meta.global.id) {
                        templateSubModule = subModule
                      }
                    })

                    function handleSubPanelClick() {
                      if (pageSubModule?.meta?.global?.uid) {
                        setSubOpenPanel(
                          openSubPanel !== pageSubModule.meta.global.uid
                            ? pageSubModule.meta.global.uid
                            : ''
                        )
                      }
                    }

                    // Check if submodule can be moved or deleted
                    let deleteAllowed = false
                    if (templateSubModule) {
                      deleteAllowed = checkIfSubDeleteAllowed(templateSubModule, pageModule)
                    }

                    let moveAllowed = true
                    if (templateSubModule) {
                      moveAllowed = !checkIfSubmodulePositionFixed(pageSubModule)
                    }

                    return (
                      <CMSSubModule
                        key={
                          pageSubModule.meta.global.uid ||
                          `subMod_${pageSubModule.meta?.global?.id}_${pageSubModuleIndex}`
                        }
                        pageModule={pageModule}
                        pageSubModule={pageSubModule}
                        templateSubModule={templateSubModule}
                        pageSubModuleIndex={pageSubModuleIndex}
                        pagesListByTemplateId={pagesListByTemplateId}
                        getPagesByTemplateId={getPagesByTemplateId}
                        onDeleteSubModule={onDeleteSubModule}
                        onChangeSubModulePosUpOrDown={onChangeSubModulePosUpOrDown}
                        displayError={displayError}
                        expanded={openSubPanel === pageSubModule.meta.global.uid}
                        panelDisabled={pageModule.subModules.length < 2}
                        deleteAllowed={deleteAllowed}
                        moveAllowed={moveAllowed}
                        onSubPanelClick={handleSubPanelClick}
                        checkIfFileExists={checkIfFileExists}
                        createPage={createPage}
                        onFileSelectRequest={onFileSelectRequest}
                        onFileSelect={onSubModuleSelectFile}
                        onSelectFollowUp={onSubModuleSelectFollowUp}
                        onTextInput={onSubModuleTextInput}
                        subModuleHasChanges={subModuleHasChanges}
                      />
                    )
                  }
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </>
      </CMSModuleAccordionPanel>
    </DragDropContext>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cmsSubmoduleContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      '&:not(:last-child)': {
        borderBottom: `2px solid ${theme.palette.primary.dark}`
      },
      '&:only-child': {
        borderBottom: 'none'
      }
    }
  })
)

export default observer(CMSModule)
