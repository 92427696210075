import React, { ReactNode } from 'react'
import {
  createStyles,
  makeStyles,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  List,
  ListItem,
  Box
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ImageIcon from '@material-ui/icons/Image'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import VideocamIcon from '@material-ui/icons/Videocam'

import { FileLibraryListItem } from '../../types'
import formatBytes from '../utils/formatBytes'
import formatDate from '../utils/formatDate'
import theme from '../../../styles/theme'
import limitString from '../utils/limitString'

interface IFileLibraryCardProps extends FileLibraryListItem {
  selectedItem: FileLibraryListItem | undefined
  showTitle?: boolean
  showDescription?: boolean
}

const FileLibraryCard: React.FC<IFileLibraryCardProps> = ({
  _id,
  title,
  size,
  createdAt,
  thumbnailUrl,
  description,
  fileName,
  selectedItem,
  showTitle = true,
  showDescription = true
}: IFileLibraryCardProps): JSX.Element => {
  const isSelected = selectedItem !== undefined && selectedItem._id === _id

  let cardHeight = 0
  if (showTitle) {
    cardHeight += 66
  }
  if (showDescription) {
    cardHeight += 60
  }
  const classes = useStyles({ isSelected, cardHeight })
  const maxTitleCharacters = 34
  const maxDescriptionCharacters = 54

  function renderImg(): ReactNode {
    if (thumbnailUrl) {
      return <CardMedia image={thumbnailUrl} className={classes.img} />
    } else {
      const fileType = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2)

      switch (fileType.toLocaleLowerCase()) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'giff':
        case 'tif':
        case 'tiff':
        case 'bmp':
        case 'exr':
        case 'pgf':
        case 'psd':
        case 'raw':
        case 'tga':
        case 'webp':
        case 'xcf':
        case 'eps':
        case 'ps':
        case 'svg':
        case 'swf':
          return (
            <CardContent classes={{ root: classes.iconContainer }}>
              <ImageIcon color='action' style={{ fontSize: 60 }} />
            </CardContent>
          )
        case 'wav':
        case 'mp3':
        case 'm4a':
        case 'wma':
        case 'aac':
        case 'aiff':
        case 'ogg':
        case 'wmf':
        case 'flac':
        case 'rm':
          return (
            <CardContent classes={{ root: classes.iconContainer }}>
              <AudiotrackIcon color='action' style={{ fontSize: 60 }} />
            </CardContent>
          )
        case 'avi':
        case 'flv':
        case 'f4v':
        case 'f4p':
        case 'mov':
        case 'mp4':
        case 'm4p':
        case 'm4v':
        case 'mpg':
        case 'mp2':
        case 'mpeg':
        case 'mxf':
        case 'wmv':
        case 'webm':
          return (
            <CardContent classes={{ root: classes.iconContainer }}>
              <VideocamIcon color='action' style={{ fontSize: 60 }} />
            </CardContent>
          )
        default:
          return (
            <CardContent classes={{ root: classes.iconContainer }}>
              <FileCopyIcon color='action' style={{ fontSize: 60 }} />
            </CardContent>
          )
      }
    }
  }

  return (
    <Card className={classes.cardRoot}>
      <CardActionArea>
        {renderImg()}
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <>
            <Box>
              {(title || description) && (
                <>
                  {showTitle && (
                    <Typography
                      gutterBottom
                      variant='h5'
                      component='h4'
                      className={classes.cardTitle}>
                      {title ? limitString(title, maxTitleCharacters) : ''}
                    </Typography>
                  )}
                  {showDescription && (
                    <Typography variant='body2' component='p' className={classes.cardDescription}>
                      {description ? limitString(description, maxDescriptionCharacters) : ''}
                    </Typography>
                  )}
                </>
              )}
            </Box>

            <List component='ul'>
              {fileName && (
                <ListItem disableGutters classes={{ root: classes.listItemRoot }}>
                  {fileName}
                </ListItem>
              )}
              {size && (
                <ListItem disableGutters classes={{ root: classes.listItemRoot }}>
                  {formatBytes(size)}
                </ListItem>
              )}
              {createdAt && (
                <ListItem disableGutters classes={{ root: classes.listItemRoot }}>
                  {formatDate(createdAt)}
                </ListItem>
              )}
            </List>
          </>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

interface IStyleProps {
  isSelected: boolean | undefined
  cardHeight: number
}

const useStyles = makeStyles(
  createStyles({
    cardRoot: {
      border: (props: IStyleProps) =>
        props.isSelected ? `2px solid ${theme.palette.info.light}` : '2px solid transparent'
    },
    cardTitle: {
      maxHeight: 44,
      overflowY: 'hidden'
    },
    cardDescription: {
      overflowY: 'hidden'
    },
    cardContentRoot: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      height: (props: IStyleProps) =>
        props.cardHeight > 0 ? 134 + props.cardHeight : 'min-content',
      [theme.breakpoints.up('sm')]: {
        height: (props: IStyleProps) =>
          props.cardHeight > 0 ? 134 + props.cardHeight : 'min-content'
      }
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[300],
      height: 120,
      [theme.breakpoints.up('sm')]: {
        height: 160
      }
    },
    img: {
      height: 120,
      [theme.breakpoints.up('sm')]: {
        height: 160
      }
    },
    listItemRoot: {
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  })
)

export default FileLibraryCard
