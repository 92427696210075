import { observer } from 'mobx-react-lite'
import React, { ReactElement, ReactNode } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useStore } from '../../stores/StoreContext'
import BasicFallback from '../fallbacks/BasicFallback'
import CenteredSpinner from '../fallbacks/CenteredSpinner'

export type TAdminRouteProps = RouteProps & {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
  // component: Component | FC | ReactElement
}

const AdminRoute: React.FC<TAdminRouteProps> = ({
  // component: Component,
  children,
  ...restProps
}: TAdminRouteProps) => {
  const { userStore } = useStore()

  return userStore.isLoading ? (
    <CenteredSpinner />
  ) : !userStore.isLoading && !userStore.isLoggedIn ? (
    <Redirect to='/login' />
  ) : userStore.isLoggedIn && !userStore.user?.isSupport ? (
    <BasicFallback
      title='Hier geht es nicht weiter...'
      message='Dieser Inhalt ist nur für angemeldete Admins verfügbar.'
    />
  ) : (
    <Route {...restProps}>{children}</Route>
  )
  // <Route
  //   {...restProps}
  //   render={(matchProps) =>
  //     userStore.isLoggedIn &&
  //     !userStore.user?.isSupport ? (
  //
  //   <BasicFallback
  //   title="Hier geht es nicht weiter..."
  //   message="Dieser Inhalt ist nur für angemeldete Admins verfügbar."
  // />
  //     ) : (
  //       {children}
  //     )
  //   }
  // />
}

export default observer(AdminRoute)
