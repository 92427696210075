import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faQuestionCircle,
  faSpa,
  faPlusCircle,
  faCircle,
  faInfoCircle,
  faEuroSign
} from '@fortawesome/free-solid-svg-icons'

export enum IconType {
  Default1 = 'Default1',
  Default2 = 'Default2',
  Default3 = 'Default3',
  Default4 = 'Default4',
  Default5 = 'Default5',
  Default6 = 'Default6'
}

export const Default1 = () => <FontAwesomeIcon icon={faInfoCircle} />

export const Default2 = () => <FontAwesomeIcon icon={faQuestionCircle} />

export const Default3 = () => <FontAwesomeIcon icon={faSpa} />

export const Default4 = () => <FontAwesomeIcon icon={faPlusCircle} />

export const Default5 = () => <FontAwesomeIcon icon={faEuroSign} />

export const Default6 = () => <FontAwesomeIcon icon={faCircle} />
