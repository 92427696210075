import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from 'react-router-dom'
import { action } from 'mobx'
import {
  createStyles,
  makeStyles,
  Box,
  Grid,
  Typography,
  MenuItem,
  Card,
  CardContent,
  Theme
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import PageTitle from '../components/atoms/PageTitle'
import { useStore } from '../stores/StoreContext'
import onSaveNotifier from '../utils/OnSaveNotifier'
import { useUtilStyles } from '../styles/useUtilStyles'
import {
  IAtomTemplate,
  IModulePage,
  IModuleTemplate,
  IPage,
  ISubModulePage,
  ISubModuleTemplate
} from '../models/cmsInterfaces'
import { IFeedback } from '../stores/CMSStore'
import BtnWtPopupSelect from '../components/organisms/BtnWtPopupSelect'
import { FileLibraryListItem } from '../mediaLibrary/types'
import CMSModule from '../components/organisms/CMSModule'
import { useReactToChanges, useScrollToId } from '../utils/customHooks'
import LoadingBackdrop from '../components/fallbacks/LoadingBackdrop'
import TextFieldLabelOnTop from '../components/molecules/TextFieldLabelOnTop'
import PageSubtitle from '../components/atoms/PageSubtitle'

interface IErrors {
  email?: string
}

export interface ICMSPageProps extends RouteComponentProps<{ id: string }> {}

/** observer-Component */
const CMSPage: React.FC<ICMSPageProps> = ({ match }: ICMSPageProps) => {
  const classes = useStyles()
  const classesUtil = useUtilStyles()
  const { setScrollToId } = useScrollToId()
  const regexStringEmail = '^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+.)+[A-Za-z]+$'
  const emailRegEx = new RegExp(regexStringEmail)

  const { cmsStore, fileStore, uiStore } = useStore()
  const [errors, setErrors] = useState<IErrors>({})

  const hasChanges = cmsStore.cmsPageHasChanges
  useReactToChanges(hasChanges)

  /* The page's name has its won state so that it is not updated instantly once
  the user types something in the 'Interner Name' input field, but only once we
  receive new data from the database. */
  const [pageName, setPageName] = useState<string>(cmsStore.page?.meta.nameIntern || 'Neue Seite')

  /* If there is only one module, open panel by default. */
  const [openPanel, setOpenPanel] = useState<string>(
    cmsStore.page?.modules?.length === 1 && !!cmsStore.page.modules[0].meta.global.uid
      ? cmsStore.page.modules[0].meta.global.uid
      : ''
  )

  /* Module uid might not be available on first render, but we want to open the panel by
  default if there is only one module, so we use an effect.*/
  useEffect(() => {
    setOpenPanel(
      cmsStore.page?.modules?.length === 1 && !!cmsStore.page.modules[0].meta.global.uid
        ? cmsStore.page.modules[0].meta.global.uid
        : openPanel
    )
    setPageName(cmsStore.page?.meta.nameIntern || 'Neue Seite')
  }, [cmsStore.page])

  useEffect(() => {
    const init = action(async () => {
      if (match.params.id) {
        await cmsStore.getPageWithTemplateConfig(match.params.id)
      }
    })
    init()

    onSaveNotifier.subscribe(handleSave)

    return () => {
      onSaveNotifier.unsubscribe(handleSave)
    }
  }, [])

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    cmsStore.setCurrentPageName(event.target.value)
  }

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    cmsStore.setCurrentPageEmail(event.target.value)

    if (!!event.target.value.length && !emailRegEx.test(event.target.value)) {
      setErrors({ ...errors, email: 'Keine valide Email-Adresse' })
    } else {
      setErrors({ ...errors, email: '' })
    }
  }

  async function handleSave() {
    if (cmsStore.pageLoaded && cmsStore.page) {
      await cmsStore.updatePage()
      if (match.params.id) {
        await cmsStore.getPageWithTemplateConfig(match.params.id)
      }
    } else {
      console.error('Could not update page. No page loaded.')
      uiStore.setSnackbarError('Speichern nicht möglich.')
    }
  }

  function onAddModule(module: IModuleTemplate) {
    const feedback: IFeedback = cmsStore.addModuleToPage(module)

    if (!feedback.success) {
      uiStore.setSnackbarError(feedback.error)
    } else {
      const newModule = cmsStore.page?.modules?.[cmsStore.page.modules.length - 1]

      if (newModule && newModule.meta.global.uid) {
        setOpenPanel(newModule.meta.global.uid)
        setScrollToId(newModule.meta.global.uid)
      }
    }
  }

  /* Functions passed down to modules */
  const handlePanelClick = useCallback(
    (panelName: string) => {
      setOpenPanel(openPanel !== panelName ? panelName : '')
    },
    [openPanel]
  )

  const handleFileSelectRequest = useCallback(
    (onSelect: (item: FileLibraryListItem) => void, acceptedTypes: string) => {
      fileStore.openMediaLibrary(onSelect, acceptedTypes)
    },
    []
  )

  const handleModuleDeleteRequest = useCallback(
    (page: IPage | undefined, pageModuleId: string | undefined) => {
      uiStore.openConfirmDialog({
        title: 'Inhalt löschen?',
        description:
          'Bitte bestätigen Sie, daß Sie diesen Inhalt wirklich löschen möchten. (Die Daten werden erst endgültig gelöscht, wenn Sie auf "Speichern" klicken.)',
        onConfirm: () => handleModuleDeleteConfirm(page, pageModuleId)
      })
    },
    []
  )

  function handleModuleDeleteConfirm(page: IPage | undefined, pageModuleId: string | undefined) {
    if (page) {
      if (pageModuleId) {
        const feedback: IFeedback = cmsStore.deleteModule(page, pageModuleId)

        if (!feedback.success) {
          uiStore.setSnackbarError(feedback.error)
        }
      } else {
        uiStore.setSnackbarError('Fehler beim Löschen des Inhalts.')
        console.error("Could not delete module. Module doesn't have a uid field.")
      }
    } else {
      uiStore.setSnackbarError('Fehler beim Löschen.')
      console.error('Could not delete module. No page loaded.')
    }
  }

  const handleSubModuleDeleteRequest = useCallback(
    (
      templateSubModule: ISubModuleTemplate,
      pageSubModule: ISubModulePage,
      pageModule: IModulePage
    ) => {
      uiStore.openConfirmDialog({
        title: 'Modul löschen?',
        description:
          'Bitte bestätigen Sie, daß Sie das Modul wirklich löschen möchten. (Die Daten werden erst endgültig gelöscht, wenn Sie auf "Speichern" klicken.)',
        onConfirm: () => handleSubModuleDeleteConfirm(templateSubModule, pageSubModule, pageModule)
      })
    },
    []
  )

  function handleSubModuleDeleteConfirm(
    templateSubModule: ISubModuleTemplate,
    pageSubModule: ISubModulePage,
    pageModule: IModulePage
  ) {
    if (pageSubModule.meta.global.uid) {
      const feedback: IFeedback = cmsStore.deleteSubModule(
        pageModule,
        templateSubModule,
        pageSubModule.meta.global.uid
      )

      if (!feedback.success) {
        uiStore.setSnackbarError(feedback.error)
      }
    } else {
      uiStore.setSnackbarError('Fehler beim Löschen des Moduls.')
      console.error("Could not delete submodule. Submodule doesn't have a uid field.")
    }
  }

  const handleSubModuleTextInput = useCallback(
    (atom: IAtomTemplate, value: string, pageSubModule: ISubModulePage): void => {
      cmsStore.setAtomInputInPageSubmodule(atom, pageSubModule, value, 'string')
    },
    []
  )

  const handleSubModuleSelectFollowUp = useCallback(
    (atom: IAtomTemplate, page: IPage, pageSubModule: ISubModulePage): void => {
      cmsStore.setAtomInputInPageSubmodule(
        atom,
        pageSubModule,
        { _id: page._id, nameIntern: page.meta.nameIntern },
        'object'
      )
    },
    []
  )

  const handleSubModuleSelectFile = useCallback(
    (atom: IAtomTemplate, value: string, pageSubModule: ISubModulePage): void => {
      cmsStore.setAtomInputInPageSubmodule(atom, pageSubModule, value, 'string')
    },
    []
  )

  const checkIfFileExists = useCallback((filename: string): boolean => {
    const fileElement = fileStore.getFileElementFromList(filename)
    return !!fileElement
  }, [])

  return (
    <>
      {cmsStore.page && (
        // {cmsStore.pageLoaded && cmsStore.page ? (
        <>
          <PageTitle>{`${pageName} bearbeiten`}</PageTitle>
          <PageSubtitle>{uiStore.pleaseSaveYourSettingsText}</PageSubtitle>

          {/* Inputs for changing name and email */}
          <Card classes={{ root: classes.cardRoot }}>
            <CardContent classes={{ root: classes.cardContentRoot }}>
              <Grid container spacing={2} className={classes.formRoot}>
                <Grid item xs={12} container direction='column'>
                  <TextFieldLabelOnTop
                    label='Interner Name'
                    placeholder='Namen vergeben!'
                    id='internalName'
                    variant='outlined'
                    size='small'
                    value={cmsStore.page?.meta?.nameIntern || ''}
                    onChange={handleNameChange}
                  />
                </Grid>
                <Grid item xs={12} container direction='column'>
                  <TextFieldLabelOnTop
                    label='Email-Adresse Ansprechpartner'
                    placeholder='Email'
                    id='emaildAdress'
                    variant='outlined'
                    size='small'
                    value={cmsStore.page?.meta?.email || ''}
                    onChange={handleEmailChange}
                    helperText={errors.email ? errors.email : ' '}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Box mt={4}>
            <Typography variant='h3' component='h2' className={classesUtil.mb_2}>
              Inhalte hinzufügen und bearbeiten
            </Typography>

            {/* Button for adding modules */}
            {cmsStore.template && (
              <BtnWtPopupSelect btnText='Inhalt Hinzufügen' btnIcon={<AddIcon />} size='large'>
                {({ closeMenu }) => {
                  if (cmsStore.template?.modules) {
                    return cmsStore.template.modules.map(
                      (module: IModuleTemplate, index: number) => (
                        <MenuItem
                          key={`menuBtn_${module.meta?.global?.id || index}`}
                          onClick={() => {
                            onAddModule(module)
                            closeMenu()
                          }}>
                          <span className={classesUtil.btnStyleText}>
                            {module.meta.cms.name as string}
                          </span>
                        </MenuItem>
                      )
                    )
                  } else {
                    return <MenuItem>Keine Module verügbar</MenuItem>
                  }
                }}
              </BtnWtPopupSelect>
            )}

            <Typography variant='body1' component='p' className={classes.explanationText}>
              Klicken Sie auf einen Panel um Inhalte zu bearbeiten. Innerhalb der Panel können Sie
              einzelne Module ausklappen und barbeiten. Sie Können die Reihenfolge der Module durch
              Ziehen oder durch Klick auf die Pfeiltasten ändern.
            </Typography>

            {/* Module panels */}
            <Box mt={2}>
              {cmsStore.template?.modules?.map((templateModule: IModuleTemplate) =>
                cmsStore.page?.modules?.map((pageModule: IModulePage, pageModuleIndex: number) => {
                  if (pageModule.meta.global.id === templateModule.meta.global.id) {
                    return (
                      <CMSModule
                        key={pageModule.meta.global.uid || pageModuleIndex}
                        page={cmsStore.page}
                        createPage={cmsStore.createPage}
                        pageModule={pageModule}
                        templateModule={templateModule}
                        pagesListByTemplateId={cmsStore.pagesListByTemplateId}
                        getPagesByTemplateId={cmsStore.getPagesByTemplateId}
                        onFileSelectRequest={handleFileSelectRequest}
                        onDeleteModule={handleModuleDeleteRequest}
                        addSubModule={cmsStore.addSubModule}
                        onDeleteSubModule={handleSubModuleDeleteRequest}
                        checkIfSubAddAllowed={cmsStore.checkIfSubAddAllowed}
                        checkIfSubDeleteAllowed={cmsStore.checkIfSubDeleteAllowed}
                        checkIfSubmodulePositionFixed={cmsStore.checkIfSubmodulePositionFixed}
                        onChangeSubModulePos={cmsStore.changeSubModulePos}
                        onChangeSubModulePosUpOrDown={cmsStore.changeSubModulePosUpOrDown}
                        onSubModuleTextInput={handleSubModuleTextInput}
                        onSubModuleSelectFollowUp={handleSubModuleSelectFollowUp}
                        onSubModuleSelectFile={handleSubModuleSelectFile}
                        checkIfFileExists={checkIfFileExists}
                        displayError={uiStore.setSnackbarError}
                        scrollYOffset={uiStore.topBarHeight}
                        expanded={openPanel === pageModule.meta.global.uid}
                        panelDisabled={
                          cmsStore?.page?.modules?.length ? cmsStore.page.modules.length < 2 : false
                        }
                        onPanelClick={handlePanelClick}
                        moduleHasChanges={cmsStore.moduleHasChanges}
                        subModuleHasChanges={cmsStore.subModuleHasChanges}
                      />
                    )
                  }
                })
              )}
            </Box>
          </Box>
        </>
      )}

      {!cmsStore.page && cmsStore.pageLoaded && (
        <>
          <PageTitle>Nicht gefunden</PageTitle>
          <p>Die Seite konnte nicht geladen werden.</p>
        </>
      )}
      <LoadingBackdrop open={!cmsStore.pageLoaded} />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      width: 'fit-content',
      backgroundColor: theme.custom.defaultBackgroundColor,
      margin: theme.spacing(0)
      // [theme.breakpoints.up('xs')]: {
      //   margin: theme.spacing(2)
      // },
    },
    cardContentRoot: {
      padding: `${theme.spacing(2)}px`,
      '&:last-child': {
        paddingBottom: theme.spacing(1)
      }
    },
    explanationText: {
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    },
    formRoot: {
      width: '100%',
      maxWidth: '400px'
    }
  })
)

export default observer(CMSPage)
