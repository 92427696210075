import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { createStyles, makeStyles, Theme, Button, ButtonGroup, Popover } from '@material-ui/core'
import { ChromePicker, ColorResult } from 'react-color'

import { IColorInputElement } from '../../models/settingsInterfaces'
import SimpleInputBase from './SimpleInputBase'

interface IColorElementProps {
  element: IColorInputElement
  fieldKey: string
  baseId?: string
  values?: string
  updateData?: (key: string, data: any) => void
}

/** observer-Component */
/**
 * @param element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used for building the ids of inputs.
 * @param baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param values - The data that can be edited in this input
 * @param updateData - 'OnChange' callback for updating data. Will be passed
 * the fieldKey and the currently selected color (hex string)
 */
const ColorElement: React.FC<IColorElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: IColorElementProps) => {
  const classes = useStyles()
  const color = values || element.default_data || '#0C2e6f'
  const [pickerOpen, setPickerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  if (!baseId) {
    baseId = fieldKey
  }

  function handleColorChange(updatedColor: ColorResult) {
    if (updateData) {
      updateData(fieldKey, updatedColor.hex)
    }
  }

  function handlePickerOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
    setPickerOpen(true)
  }

  function handlePickerClose() {
    setAnchorEl(null)
    setPickerOpen(false)
  }

  return (
    <SimpleInputBase
      fullWidth={element.fullWidth || false}
      firstRowTitle={element.title}
      firstRowId={`bg_${baseId}`}
      toolTip={element.toolTip}
      firstRowChildren={
        <>
          <ButtonGroup id={`bg_${baseId}`} disableElevation fullWidth>
            <Button
              className={classes.colorDisplay}
              component={'div'}
              disableRipple
              disableFocusRipple
              style={{ backgroundColor: color }}
              aria-label={'gewählte Farbe'}
              variant='contained'
              fullWidth>
              {color}
            </Button>
            <Button
              aria-haspopup='true'
              aria-describedby={`cp_${baseId}`}
              variant='contained'
              color='primary'
              onClick={handlePickerOpen}>
              Farbe wählen
            </Button>
          </ButtonGroup>
          <Popover
            id={`cp_${baseId}`}
            anchorEl={anchorEl}
            open={pickerOpen}
            onClose={handlePickerClose}>
            <ChromePicker color={color} onChangeComplete={handleColorChange} />
          </Popover>
        </>
      }
    />
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorDisplay: {
      color: theme.palette.common.white,
      cursor: 'default'
    }
  })
)

export default observer(ColorElement)
