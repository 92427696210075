import React, { useEffect, useState } from 'react'
import {
  createStyles,
  makeStyles,
  Theme,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListItemIcon,
  SvgIcon,
  ListItemText,
  Button,
  Box,
  TextField,
  Hidden,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'

import { SvgLoader } from 'react-svgmt'
import { observer } from 'mobx-react-lite'

import Icons from './assets/icons/icons'
import { IEHContent, IEHContents, ISpecialProp } from '../../../models/ehInterfaces'
import BtnWtPopupSelect from '../../organisms/BtnWtPopupSelect'
import { IPage } from '../../../models/cmsInterfaces'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { Logger } from '../../../utils/log'

interface IContentEditorProps {
  contentsData: IEHContents | undefined
  cmsData: IPage[] | undefined
  contentHasCmsPage: (id: string) => boolean
  specialProps: ISpecialProp[]
  placeOneImagePointModeOn: (id: string) => void
  placingImagePoint: boolean
  showEHGraphicBreakpoint: Breakpoint
  placingActiveBtnColor: string
  selectedContent: string | undefined
  onAddContent: (pageId: string) => string
  onSetProduct: (contentId: string, productId: string) => void
  onSetIcon: (contentId: string, iconId: string) => void
  onSetHoverSpecial: (contentId: string, specialId: string) => void
  onSetSpecialProp: (contentId: string, specialPropId: string) => void
  onSetTitle: (contentId: string, title: string) => void
  onSetPageUrl: (contentId: string, url: string) => void
  onDeleteContent: (contentId: string) => void
  setSelectedContent: (contentId: string | undefined) => void
}

// type TIcon =
//   | {
//       name: string
//       icon: string
//     }
//   | {
//       name: string
//       icon: () => JSX.Element
//     }

const specialSVGElementsToShowOnHoverIcon = [
  {
    id: 'none',
    name: 'Keine Wahl'
  },
  {
    id: 'Bad',
    name: 'Bad'
  },
  {
    id: 'Wohnzimmer',
    name: 'Wohnzimmer'
  },
  {
    id: 'Schlafzimmer',
    name: 'Schlafzimmer'
  }
]

/** observer-Component */
const ContentEditor: React.FC<IContentEditorProps> = ({
  contentsData,
  cmsData,
  contentHasCmsPage,
  specialProps,
  placeOneImagePointModeOn,
  showEHGraphicBreakpoint = 'md',
  placingImagePoint,
  placingActiveBtnColor,
  selectedContent,
  onAddContent,
  onSetProduct,
  onSetIcon,
  onSetHoverSpecial,
  onSetSpecialProp,
  onSetTitle,
  onSetPageUrl,
  onDeleteContent,
  setSelectedContent
}: IContentEditorProps) => {
  const classes = useStyles({ placingActiveBtnColor })
  const icons: Icons = new Icons()

  // const sortedIcons = icons.icons.sort((a: TIcon, b: TIcon) => a.name.localeCompare(b.name))
  // const [selectedContent, setSelectedContent] = useState<string | undefined>(undefined)
  const [selectedCmsPage, setSelectedCmsPage] = useState<string | undefined>(undefined)
  const [selectedIcon, setSelectedIcon] = useState<string | undefined>(undefined)
  const [selectedHoverContent, setSelectedHoverContent] = useState<string | undefined>(undefined)
  const [selectedSpecialProp, setSelectedSpecialProp] = useState<string | undefined>(undefined)

  const selectedContentObject = contentsData?.contents?.find((item) => item.id === selectedContent)
  const hasOwnName = selectedContentObject?.title !== selectedContentObject?.cmsPage.name

  useEffect(() => {
    if (selectedContentObject) {
      setSelectedCmsPage(selectedContentObject.cmsPage._id)
      setSelectedIcon(selectedContentObject.graphic.icon.name)
      setSelectedHoverContent(selectedContentObject.graphic.showSpecialSVGElementIdOnHoverIcon)
      setSelectedSpecialProp(selectedContentObject.specialProp.id)
    }
  }, [selectedContentObject])

  const hiddenProps = {
    xsDown: showEHGraphicBreakpoint === 'sm',
    smDown: showEHGraphicBreakpoint === 'md',
    mdDown: showEHGraphicBreakpoint === 'lg',
    lgDown: showEHGraphicBreakpoint === 'xl'
  }

  function handleAddContentWithCmsPage(pageId: string) {
    Logger.log('handleAddContentWithCmsPage')
    const newContentId = onAddContent(pageId)
    handleSelectContent(newContentId)
  }

  function handleSelectContent(contentId: string | undefined) {
    setSelectedContent(contentId)
    const newContent = contentId
      ? contentsData?.contents?.find((item) => item.id === contentId)
      : undefined
    setSelectedCmsPage(newContent?.cmsPage._id || undefined)
    setSelectedIcon(newContent?.graphic?.icon.name || undefined)
    setSelectedHoverContent(newContent?.graphic?.showSpecialSVGElementIdOnHoverIcon || undefined)
    setSelectedSpecialProp(newContent?.specialProp?.id || undefined)
  }

  function handleSelectCmsPage(event: React.ChangeEvent<{ value: unknown }>) {
    setSelectedCmsPage(event.target.value as string)
    if (selectedContent) {
      onSetProduct(selectedContent, event.target.value as string)
    }
  }

  function handleSelectIcon(event: React.ChangeEvent<{ value: unknown }>) {
    setSelectedIcon(event.target.value as string)
    if (selectedContent) {
      onSetIcon(selectedContent, event.target.value as string)
    }
  }

  function handleSelecetHoverSpecial(event: React.ChangeEvent<{ value: unknown }>) {
    setSelectedHoverContent(event.target.value as string)
    if (selectedContent) {
      onSetHoverSpecial(selectedContent, event.target.value as string)
    }
  }

  function handleSelecetSpecialProp(event: React.ChangeEvent<{ value: unknown }>) {
    setSelectedSpecialProp(event.target.value as string)
    if (selectedContent) {
      onSetSpecialProp(selectedContent, event.target.value as string)
    }
  }

  function handleSetTitle(event: React.ChangeEvent<HTMLInputElement>) {
    if (selectedContent) {
      onSetTitle(selectedContent, event.target.value as string)
    }
  }

  function handleSetUrl(event: React.ChangeEvent<HTMLInputElement>) {
    if (selectedContent) {
      onSetPageUrl(selectedContent, event.target.value as string)
    }
  }

  function handleDelete() {
    if (selectedContent) {
      onDeleteContent(selectedContent)
      handleSelectContent(undefined)
    }
  }

  return (
    <Card>
      <CardHeader title='Inhalte bearbeiten' />
      <CardContent>
        <Box pb={2}>
          <BtnWtPopupSelect btnText='Hinzufügen' btnIcon={<AddIcon />} size='small'>
            {({ closeMenu }) => {
              if (cmsData?.length) {
                return cmsData.map((page: IPage) => (
                  <MenuItem
                    key={page._id}
                    value={page._id}
                    onClick={() => {
                      handleAddContentWithCmsPage(page._id)
                      closeMenu()
                    }}>
                    {page.meta?.nameIntern}
                  </MenuItem>
                ))
              } else {
                return <MenuItem>Keine Seiten gefunden</MenuItem>
              }
            }}
          </BtnWtPopupSelect>
        </Box>

        {contentsData?.contents && (
          <>
            <FormControl variant='outlined' fullWidth classes={{ root: classes.selectRoot }}>
              <InputLabel id='content-select-label'>Inhalt wählen</InputLabel>

              <Select
                value={selectedContent || ''}
                labelId='content-select-label'
                displayEmpty
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  handleSelectContent(event.target.value as string)
                }
                label='Inhalt wählen'>
                {contentsData.contents.map((content: IEHContent) => (
                  <MenuItem key={content.id} value={content.id}>
                    {content.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedContent && (
              <>
                <TextField
                  fullWidth
                  label='Namen vergeben'
                  variant='outlined'
                  value={selectedContentObject?.title || ''}
                  classes={{ root: classes.textFieldRoot }}
                  InputProps={{
                    className: clsx({ [classes.defaulText]: !hasOwnName })
                  }}
                  onChange={handleSetTitle}
                />

                <TextField
                  fullWidth
                  label='Webseite-URL (optional)'
                  variant='outlined'
                  value={selectedContentObject?.customerPage || ''}
                  classes={{ root: classes.textFieldRoot }}
                  onChange={handleSetUrl}
                />

                <Hidden {...hiddenProps}>
                  <Box pb={2}>
                    <Button
                      variant='outlined'
                      classes={{ root: clsx({ [classes.placingActiveBtn]: placingImagePoint }) }}
                      onClick={() => placeOneImagePointModeOn(selectedContent)}>
                      Punkt setzen
                    </Button>
                  </Box>
                </Hidden>

                {!contentHasCmsPage(selectedContent) && (
                  <Box pb={2}>
                    <Typography color='secondary'>Produkt gelöscht!</Typography>
                    <Typography variant='body2' component='p'>
                      Bitte wählen Sie ein neues Produkt.
                    </Typography>
                  </Box>
                )}

                <FormControl variant='outlined' fullWidth classes={{ root: classes.selectRoot }}>
                  <InputLabel id='cms-page-select-label'>Produkt wählen</InputLabel>
                  <Select
                    value={selectedCmsPage || ''}
                    labelId='cms-page-select-label'
                    displayEmpty
                    onChange={handleSelectCmsPage}
                    label='Produkt wählen'>
                    {cmsData?.length &&
                      cmsData.map((page: IPage) => (
                        <MenuItem key={page._id} value={page._id}>
                          {page?.meta?.nameIntern}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl variant='outlined' fullWidth classes={{ root: classes.selectRoot }}>
                  <InputLabel id='icon-select-label'>Icon wählen</InputLabel>
                  <Select
                    value={selectedIcon || ''}
                    labelId='icon-select-label'
                    displayEmpty
                    onChange={handleSelectIcon}
                    classes={{ select: classes.selectWithIcon }}
                    label='Icon wählen'>
                    {icons.icons.map((icon: any) => (
                      <MenuItem key={icon.name} value={icon.name}>
                        <ListItemIcon className={classes.iconListItemIcon}>
                          {typeof icon.icon === 'string' ? (
                            <SvgLoader height='34px' path={icon.icon} viewBox='0 0 42 42' />
                          ) : (
                            <SvgIcon>{icon.icon()}</SvgIcon>
                          )}
                        </ListItemIcon>
                        <ListItemText primary={icon.name} className={classes.iconListItemText} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant='outlined' fullWidth classes={{ root: classes.selectRoot }}>
                  <InputLabel id='hover-content-select-label'>Bei Icon-Hover zeigen</InputLabel>
                  <Select
                    value={selectedHoverContent || ''}
                    labelId='hover-content-select-label'
                    displayEmpty
                    onChange={handleSelecetHoverSpecial}
                    label='Bei Icon-Hover zeigen'>
                    {specialSVGElementsToShowOnHoverIcon.map(
                      (special: { id: string; name: string }) => (
                        <MenuItem key={special.id} value={special.id}>
                          {special.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                <FormControl variant='outlined' fullWidth classes={{ root: classes.selectRoot }}>
                  <InputLabel id='special-content-select-label'>Spezialinhalt</InputLabel>
                  <Select
                    value={selectedSpecialProp || ''}
                    labelId='special-content-select-label'
                    displayEmpty
                    onChange={handleSelecetSpecialProp}
                    label='Spezialinhalt'>
                    {specialProps.map((prop: ISpecialProp) => (
                      <MenuItem key={prop.specialProp.id} value={prop.specialProp.id}>
                        {prop.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box>
                  <Button
                    variant='contained'
                    color='secondary'
                    startIcon={<DeleteIcon />}
                    onClick={handleDelete}>
                    Löschen
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}

interface IStyleProps {
  placingActiveBtnColor: string | undefined
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaulText: {
      color: theme.custom.defaultDarkColor
    },
    iconListItemIcon: {
      minWidth: '42px',
      [theme.breakpoints.up('lg')]: {
        minWidth: '56px'
      }
    },
    iconListItemText: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis'
    },
    placingActiveBtn: {
      color: (props: IStyleProps) => props.placingActiveBtnColor || '#00c943'
    },
    selectRoot: {
      '&:not(:last-child)': {
        paddingBottom: theme.spacing(2)
      }
    },
    selectWithIcon: {
      display: 'flex',
      alignItems: 'center'
    },
    textFieldRoot: {
      paddingBottom: theme.spacing(2)
    }
  })
)

export default observer(ContentEditor)
