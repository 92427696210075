import React, { ReactElement, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

import SettingsAccordionPanel from './SettingsAccordionPanel'
import { IInputElement, ICategory, ISettings } from '../../../models/settingsInterfaces'
import { IPanelOpenSetting } from '../../../stores/UIStore'

interface ISettingsAccordionProps {
  settingsId: string
  settings: ISettings
  expandedPanels: IPanelOpenSetting
  setExpandedPanel: (settingsId: string, categoryId: string | false) => void
  getElement(
    element: IInputElement,
    key: string
  ): ReactElement | ReactElement[] | ReactNode | ReactNode[]
  categoryHasChanges?: (categoryId: string) => boolean
}

/** observer-Component */
const SettingsAccordion: React.FC<ISettingsAccordionProps> = ({
  settingsId,
  settings,
  expandedPanels,
  setExpandedPanel,
  getElement,
  categoryHasChanges
}: ISettingsAccordionProps) => (
  <>
    {settings?.categories?.map((category: ICategory, index: number) => {
      const hasSubcategories = !!category.subcategories

      return (
        <React.Fragment key={category.id || index}>
          {/* --- main category ---- */}
          {category.show && (
            <SettingsAccordionPanel
              // Open panel by default if there is only one category
              panelDisabled={settings.categories.length < 2}
              expandedPanels={expandedPanels}
              setExpandedPanel={setExpandedPanel}
              settingsId={settingsId}
              category={category}
              getElement={getElement}
              categoryHasChanges={categoryHasChanges}
            />
          )}

          {/* --- subcategories ---- */}
          {hasSubcategories &&
            category.subcategories?.map(
              (subcategory: ICategory, index: number) =>
                subcategory.show && (
                  <SettingsAccordionPanel
                    key={subcategory.id || index}
                    panelDisabled={false}
                    expandedPanels={expandedPanels}
                    setExpandedPanel={setExpandedPanel}
                    settingsId={settingsId}
                    category={subcategory}
                    getElement={getElement}
                    categoryHasChanges={categoryHasChanges}
                  />
                )
            )}
        </React.Fragment>
      )
    })}
  </>
)

export default observer(SettingsAccordion)
