import { makeAutoObservable, runInAction } from 'mobx'
import { FileLibraryListItem, FileMeta } from '../mediaLibrary/types'
import Api from '../utils/api'
import { Logger } from '../utils/log'
import { Utility } from '../utils/Utility'
import ApplicationStore from './ApplicationStore'

class FileStore {
  private rootStore: ApplicationStore
  public isLoading: boolean
  public filelist: FileLibraryListItem[] = []
  public mediaLibraryAcceptedTypes: string = ''
  private uploadTimeoutAfter: number = 180000
  public fileSelectCallback: ((fileitem: FileLibraryListItem) => unknown) | undefined = undefined

  public constructor(rootStore: ApplicationStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.isLoading = false
  }

  public getFileList = async () => {
    try {
      const res = await Api.get('/files/filelist')

      const newfilelist = res.data.map((entry: any) => {
        const fileItem: FileLibraryListItem = { ...entry }

        if (fileItem.createDate) {
          const date = Utility.isDate(fileItem.createDate)
            ? fileItem.createDate
            : new Date(Date.parse(fileItem.createDate))

          if (Utility.isValidDate(date)) {
            fileItem.createdAt = date
          }
          delete fileItem.createDate
        }

        return fileItem
      })

      runInAction(() => {
        this.filelist = newfilelist
      })
      Logger.log('received file list', res.data)
    } catch (error) {
      Logger.log('Could not fetch file list.')
    }
  }

  public checkIfFileExists = async (filename: string): Promise<boolean> => {
    try {
      const res = await Api.putJson('/files/fileExists', { fileName: filename })
      return res.data === 'true' || res.data
    } catch (error) {
      return false
    }
  }

  public getFileElementFromList = (filename: string): FileLibraryListItem | undefined =>
    this.filelist.find((libItem) => libItem.fileName === filename)

  public fileUploadCallback = async (data: string | File, meta: FileMeta): Promise<boolean> => {
    try {
      const formData = new FormData()
      formData.append('fileUpload', data)
      formData.append('filename', meta.fileName)
      formData.append('type', meta.type)

      const res = await Api.postMultipart('/files/upload', formData, {
        timeout: this.uploadTimeoutAfter
      })
      Logger.log('fileUploadCallback response', res.data)
      await this.getFileList()
      return true
    } catch (error) {
      return false
    }
  }

  public fileDeleteCallback = async (item: FileLibraryListItem) => {
    this.rootStore.uiStore.openConfirmDialog({
      title: 'Datei löschen?',
      extraDanger: true,
      description:
        'Bitte bestätigen Sie daß Sie diese Diese Datei wirklich SOFORT löschen möchten. Sie wird möglicherweise noch an anderen Stellen verwendet.',
      onConfirm: async () => {
        try {
          const res = await Api.putJson('/files/deleteFile', { filePath: item.filePath })
          Logger.log('fileDeleteCallback response', res.data)
          await this.getFileList()
        } catch (error) {
          Logger.log('Could not delete file.')
        }
      }
    })
  }

  public openMediaLibrary = (
    selectCallback: ((fileitem: FileLibraryListItem) => unknown) | undefined,
    acceptedTypes: string
  ) => {
    this.rootStore.uiStore.setMediaLibraryOpen(true)

    this.mediaLibraryAcceptedTypes = acceptedTypes

    this.fileSelectCallback = (file: FileLibraryListItem) => {
      if (selectCallback) {
        selectCallback(file)
      }
      this.rootStore.uiStore.setMediaLibraryOpen(false)
    }
  }

  public closeMediaLibrary = () => {
    this.rootStore.uiStore.setMediaLibraryOpen(false)
    this.fileSelectCallback = undefined
    this.mediaLibraryAcceptedTypes = ''
  }
}

export default FileStore
