import { rootStore } from '../stores/StoreContext'
import { IUser } from '../stores/UserStore'
import cmsStrings from '../utils/cmsStrings'

interface INavItem {
  id: string
  title: string
  items?: { id: string; title: string }[]
}

export function getNavItems(currentUser: IUser | undefined): INavItem[] {
  // init configurator items
  let configuratorItems

  if (process.env.REACT_APP_BUILD && process.env.REACT_APP_BUILD.toLowerCase() === 'eh') {
    configuratorItems = [
      {
        id: 'cisettings',
        title: 'CI Einstellungen'
      },
      {
        id: 'ehwelcomesettings',
        title: 'Willkommensseite'
      },
      {
        id: 'ehsettings',
        title: 'Energiehaus Einstellungen'
      },
      {
        id: 'ehcontents',
        title: 'Energiehaus Produkteinstellungen'
      },
      {
        id: 'ehemailsettings',
        title: 'Email Template'
      },
      {
        id: 'teaser',
        title: 'Teaser Vorschau'
      }
    ]
  }

  if (process.env.REACT_APP_BUILD && process.env.REACT_APP_BUILD.toLowerCase() === 'wc') {
    configuratorItems = [
      {
        id: 'cisettings',
        title: 'CI Einstellungen'
      },
      {
        id: 'wtsettings',
        title: 'Einstellungen WärmeCheck'
      },
      {
        id: 'questions',
        title: 'Zusatzfragen WärmeCheck'
      },
      {
        id: 'notifiersettings',
        title: 'Einstellung für E-Mail'
      },
      {
        id: 'wcemailsettings',
        title: 'Email Template'
      },
      {
        id: 'teaser',
        title: 'Teaser Vorschau'
      }
    ]
  }

  if (process.env.REACT_APP_BUILD && process.env.REACT_APP_BUILD.toLowerCase() === 'pv') {
    configuratorItems = [
      {
        id: 'cisettings',
        title: 'CI Einstellungen'
      },
      {
        id: 'pvsettings',
        title: 'Einstellungen Rechner'
      },
      {
        id: 'pvwelcomesettings',
        title: 'Willkommensseite'
      },
      {
        id: 'pvnotifiersettings',
        title: 'Einstellung für E-Mail & PDF'
      },
      {
        id: 'pvemailsettings',
        title: 'Email Template'
      },
      {
        id: 'teaser',
        title: 'Teaser Vorschau'
      }
    ]
  }

  // init cms items if neccessary
  let cms: INavItem | undefined
  if (process.env.REACT_APP_BUILD && process.env.REACT_APP_BUILD.toLowerCase() === 'eh') {
    cms = {
      id: 'cms',
      title: 'CMS',
      items: []
    }

    rootStore.cmsStore?.templatesConfig?.cmsTemplatesConf?.forEach((template) => {
      if (cms && cms.items) {
        cms.items.push({
          id: `cmsPages/${template.meta.global.id}`,
          title: cmsStrings[template.meta.global.id].plural || template.meta.cms.name
        })
      }
    })
  }

  // init account items
  const user = {
    id: 'account',
    title: 'Account',
    items: [
      {
        id: 'userClient',
        title: 'Mein Zugang'
      },
      {
        id: 'logout',
        title: 'Logout',
        clickHandler: rootStore.userStore.logout
      }
    ]
  }

  // init support items
  let support
  if (currentUser?.isSupport) {
    support = {
      id: 'support',
      title: 'Support Area',
      items: [
        {
          id: 'hidden',
          title: 'Backups und Credentials'
        }
      ]
    }
  }

  // put it all together
  const navItems: INavItem[] = [
    {
      id: 'configurator',
      title: 'Konfigurator',
      items: configuratorItems
    }
  ]

  if (cms) {
    navItems.push(cms)
  }

  navItems.push(user)

  if (support) {
    navItems.push(support)
  }

  return navItems
}
