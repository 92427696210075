import React from 'react'
import { observer } from 'mobx-react-lite'
import { Grid } from '@material-ui/core'

import { ISubQuestion } from '../../../models/questionsInterfaces'
import TextFieldInnerLabel from '../../molecules/TextFieldInnerLabel'

export type TFieldType = 'value' | 'cost_absolute' | 'cost_procentual'

interface ISubQuestionProps {
  parentQuestionIndex: number
  ansNumber: number
  baseId: string
  subQuestion: ISubQuestion
  questionErrors?: { [errorkey: string]: string }
  changeCb: (subQuestionIndex: number, key: string, value: string | number | undefined) => void
}

/** observer-Component */
const SubQuestion: React.FC<ISubQuestionProps> = ({
  ansNumber,
  baseId,
  subQuestion,
  questionErrors,
  changeCb
}: ISubQuestionProps) => {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (changeCb) {
      changeCb(ansNumber - 1, event.target.name, event.target.value)
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <TextFieldInnerLabel
          type='text'
          name='value'
          id={`${baseId}-sq-${ansNumber}`}
          value={subQuestion.value || ''}
          label={`Antwort ${ansNumber}`}
          maxLength={2000}
          onChange={handleChange}
          error={!!questionErrors?.[`value_${ansNumber}`] ? 1 : 0}
          helperText={
            questionErrors?.[`value_${ansNumber}`] ? questionErrors[`value_${ansNumber}`] : ''
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldInnerLabel
          name='cost_absolute'
          type='text'
          numberFormatProps={{
            decimalScale: 2,
            decimalSeparator: ',',
            thousandSeparator: '.',
            defaultValue: '',
            isNumericString: true,
            allowEmptyFormatting: false,
            fixedDecimalScale: true,
            allowLeadingZeros: true
          }}
          id={`${baseId}-ape-${ansNumber}`}
          value={
            typeof subQuestion.cost_absolute !== 'undefined'
              ? subQuestion.cost_absolute + ''
              : undefined
          }
          label={'Aufpreis €/Monat'}
          maxLength={2000}
          onChange={handleChange}
          error={!!questionErrors?.[`cost_absolute_${ansNumber}`] ? 1 : 0}
          helperText={
            questionErrors?.[`cost_absolute_${ansNumber}`]
              ? questionErrors[`cost_absolute_${ansNumber}`]
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldInnerLabel
          name='cost_procentual'
          type='text'
          numberFormatProps={{
            decimalSeparator: ',',
            thousandSeparator: '.',
            isNumericString: true,
            allowEmptyFormatting: false,
            allowLeadingZeros: true
          }}
          id={`${baseId}-app-${ansNumber}`}
          value={
            typeof subQuestion.cost_procentual !== 'undefined'
              ? subQuestion.cost_procentual + ''
              : undefined
          }
          label={'Aufpreis %'}
          maxLength={2000}
          onChange={handleChange}
          error={!!questionErrors?.[`cost_procentual_${ansNumber}`] ? 1 : 0}
          helperText={
            questionErrors?.[`cost_procentual_${ansNumber}`]
              ? questionErrors[`cost_procentual_${ansNumber}`]
              : ''
          }
        />
      </Grid>
    </>
  )
}

export default observer(SubQuestion)
