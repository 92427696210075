import IconAbwasser from './IconAbwasser.svg'
import IconAlarmanlage from './IconAlarmanlage.svg'
import IconBath from './IconBath.svg'
import IconBikes from './IconBikes.svg'
import IconBikesharing from './IconBikesharing.svg'
import IconCarsharing from './IconCarsharing.svg'
import IconColdContracting from './IconColdContracting.svg'
import IconDefault10 from './IconDefault10.svg'
import IconDefault11 from './IconDefault11.svg'
import IconDefault12 from './IconDefault12.svg'
import IconDefault7 from './IconDefault7.svg'
import IconDefault8 from './IconDefault8.svg'
import IconDefault9 from './IconDefault9.svg'
import IconDistanceHeating from './IconDistanceHeating.svg'
import IconDrinkingWater from './IconDrinkingWater.svg'
import IconDrinkingWaterAnalysis from './IconDrinkingWaterAnalysis.svg'
import IconEBikes from './IconEBikes.svg'
import IconElectric from './IconElectric.svg'
import IconEmobility from './IconEmobility.svg'
import IconEnergieausweis from './IconEnergieausweis.svg'
import IconEnergiesparverordnung from './IconEnergiesparverordnung.svg'
import IconEnergyConsulting from './IconEnergyConsulting.svg'
import IconEnergySavingTip from './IconEnergySavingTip.svg'
import IconErklaervideos from './IconErklaervideos.svg'
import IconFoerderung from './IconFoerderung.svg'
import IconGas from './IconGas.svg'
import IconGlasfaser from './IconGlasfaser.svg'
import IconHeat from './IconHeat.svg'
import IconHouseConnection from './IconHouseConnection.svg'
import IconLadesaeule from './IconLadesaeule.svg'
import IconLight from './IconLight.svg'
import IconMoveHouse from './IconMoveHouse.svg'
import IconOekostrom from './IconOekostrom.svg'
import IconOnlineKundensupport from './IconOnlineKundensupport.svg'
import IconPublicTransport from './IconPublicTransport.svg'
import IconPV from './IconPV.svg'
import IconRasenmaeher from './IconRasenmaeher.svg'
import IconRegionalstrom from './IconRegionalstrom.svg'
import IconRentersElectricity from './IconRentersElectricity.svg'
import IconSmartHome from './IconSmartHome.svg'
import IconSolar from './IconSolar.svg'
import IconStart from './IconStart.svg'
import IconTelecommunication from './IconTelecommunication.svg'
import IconThermography from './IconThermography.svg'
import IconThermography02 from './IconThermography02.svg'
import IconThermography03 from './IconThermography03.svg'
import IconTrash from './IconTrash.svg'
import IconTreppenlift from './IconTreppenlift.svg'
import IconUsedWater from './IconUsedWater.svg'
import IconWaerme from './IconWaerme.svg'
import IconWallbox from './IconWallbox.svg'
import IconWanderwege from './IconWanderwege.svg'

import { Default1, Default2, Default3, Default4, Default5, Default6 } from './libs'

class Icons {
  public getIconIndex(name: string) {
    for (let index = 0; index < this.icons.length; index++) {
      if (this.icons[index].name === name) {
        return index
      }
    }
    return 0
  }

  public icons = [
    {
      name: 'Abfall',
      icon: IconTrash
    },
    {
      name: 'Abwasser',
      icon: IconAbwasser
    },
    {
      name: 'Alarmanlage',
      icon: IconAlarmanlage
    },
    {
      name: 'Bad',
      icon: IconBath
    },
    {
      name: 'Beleuchtung',
      icon: IconLight
    },
    {
      name: 'Bikes',
      icon: IconBikes
    },
    {
      name: 'Bikesharing',
      icon: IconBikesharing
    },
    {
      name: 'Carsharing',
      icon: IconCarsharing
    },
    {
      name: 'E-Bikes',
      icon: IconEBikes
    },
    {
      name: 'E-Mobilität',
      icon: IconEmobility
    },
    {
      name: 'Energieausweis',
      icon: IconEnergieausweis
    },
    {
      name: 'Energieberatung',
      icon: IconEnergyConsulting
    },
    {
      name: 'Energiespartipps',
      icon: IconEnergySavingTip
    },
    {
      name: 'Erklaervideos',
      icon: IconErklaervideos
    },
    {
      name: 'Fernwärme',
      icon: IconDistanceHeating
    },
    {
      name: 'Förderung',
      icon: IconFoerderung
    },
    {
      name: 'Gas',
      icon: IconGas
    },
    {
      name: 'Gesetzliche Anforderungen',
      icon: IconEnergiesparverordnung
    },
    {
      name: 'Glasfaser',
      icon: IconGlasfaser
    },
    {
      name: 'Hausanschluß',
      icon: IconHouseConnection
    },
    {
      name: 'Heizung',
      icon: IconHeat
    },
    {
      name: 'Klima-Contracting',
      icon: IconColdContracting
    },
    {
      name: 'Ladesaeule',
      icon: IconLadesaeule
    },
    {
      name: 'Mieterstrom',
      icon: IconRentersElectricity
    },
    {
      name: 'Online Kundensupport',
      icon: IconOnlineKundensupport
    },
    {
      name: 'ÖPNV',
      icon: IconPublicTransport
    },
    {
      name: 'Ökostrom',
      icon: IconOekostrom
    },
    {
      name: 'PV',
      icon: IconPV
    },
    {
      name: 'Rasenmäher',
      icon: IconRasenmaeher
    },
    {
      name: 'Regionalstrom',
      icon: IconRegionalstrom
    },
    {
      name: 'Smart Home',
      icon: IconSmartHome
    },
    {
      name: 'Solar',
      icon: IconSolar
    },
    {
      name: 'Strom',
      icon: IconElectric
    },
    {
      name: 'Start',
      icon: IconStart
    },
    {
      name: 'Telekomunikation',
      icon: IconTelecommunication
    },
    {
      name: 'Thermographie_1',
      icon: IconThermography
    },
    {
      name: 'Thermographie_2',
      icon: IconThermography02
    },
    {
      name: 'Thermographie_3',
      icon: IconThermography03
    },
    {
      name: 'Treppenlift',
      icon: IconTreppenlift
    },
    {
      name: 'Trinkwasser',
      icon: IconDrinkingWater
    },
    {
      name: 'Trinkwasseranalyse',
      icon: IconDrinkingWaterAnalysis
    },
    {
      name: 'Umzug',
      icon: IconMoveHouse
    },
    {
      name: 'Wanderwege',
      icon: IconWanderwege
    },
    {
      name: 'Wärme',
      icon: IconWaerme
    },
    {
      name: 'Wallbox',
      icon: IconWallbox
    },
    {
      name: 'Wasser',
      icon: IconUsedWater
    },
    {
      name: 'Default1',
      icon: Default1
    },
    {
      name: 'Default2',
      icon: Default2
    },
    {
      name: 'Default3',
      icon: Default3
    },
    {
      name: 'Default4',
      icon: Default4
    },
    {
      name: 'Default5',
      icon: Default5
    },
    {
      name: 'Default6',
      icon: Default6
    },
    {
      name: 'Default7',
      icon: IconDefault7
    },
    {
      name: 'Default8',
      icon: IconDefault8
    },
    {
      name: 'Default9',
      icon: IconDefault9
    },
    {
      name: 'Default10',
      icon: IconDefault10
    },
    {
      name: 'Default11',
      icon: IconDefault11
    },
    {
      name: 'Default12',
      icon: IconDefault12
    }
  ]
}

export default Icons
