import { observer } from 'mobx-react-lite'
import React, { ReactElement, ReactNode } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useStore } from '../../stores/StoreContext'
import CenteredSpinner from '../fallbacks/CenteredSpinner'

export type TPrivateRouteProps = RouteProps & {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
  // component: Component | FC | FC<ICMSPageProps> | ReactElement
}

const PrivateRoute: React.FC<TPrivateRouteProps> = ({
  // component: Component,
  children,
  ...restProps
}: TPrivateRouteProps) => {
  const { userStore } = useStore()

  return userStore.isLoading ? (
    <CenteredSpinner />
  ) : !userStore.isLoading && !userStore.isLoggedIn ? (
    <Redirect to='/loginClient' />
  ) : (
    //   <Route
    //     {...restProps}
    //     render={(matchProps) => <Component {...matchProps} />}
    // />
    <Route {...restProps}>{children}</Route>
  )
}

export default observer(PrivateRoute)
