import React, { FC, ReactElement, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { ErrorBoundary } from 'react-error-boundary'
import { Box, Container, Toolbar } from '@material-ui/core'

import { usePageStyles } from '../../styles/usePageStyles'
import TopBar from '../navigation/TopBar'
import Drawer from '../navigation/Drawer'
import BasicFallback from '../fallbacks/BasicFallback'

export interface ILayoutProps {
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

interface IMainLayoutProps extends ILayoutProps {
  children: FC | ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

const Fallback: React.FC = () => (
  <BasicFallback
    title='Etwas ist schief gelaufen...'
    message='Bitte versuchen Sie, die Seite neu zu laden.'
  />
)

const MainLayout: React.FC<IMainLayoutProps> = ({
  children,
  maxWidth = 'md'
}: IMainLayoutProps) => {
  const classes = usePageStyles()
  return (
    <Box className={classes.appContainer}>
      <TopBar />
      <Drawer />
      <Container component='main' maxWidth={maxWidth} className={classes.main}>
        {/* Empty Toolbar Component pushes main content below Top Bar */}
        <Toolbar />
        <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>
      </Container>
    </Box>
  )
}

export default observer(MainLayout)
