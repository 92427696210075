import { makeStyles, createStyles, Theme, Backdrop, CircularProgress } from '@material-ui/core'
import React from 'react'

interface ILoadingBackdropProps {
  open: boolean
}

const LoadingBackdrop: React.FC<ILoadingBackdropProps> = ({ open }: ILoadingBackdropProps) => {
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white
    }
  })
)

export default LoadingBackdrop
