import { createTheme } from '@material-ui/core/styles'
import { pink, grey, indigo } from '@material-ui/core/colors'

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    custom: { [key: string]: any }
  }
  interface ThemeOptions {
    custom?: { [key: string]: any }
  }
}

const theme = createTheme({
  palette: {
    secondary: pink
  },
  custom: {
    defaultBackgroundColor: grey[300],
    defaultDarkColor: grey[600],
    defaultBorderColor: grey[400],
    // defaultBorderColor: '#ced4da',
    // defaultBorderColor: 'rgba(118, 118, 118)',
    // defaultBorderColor: 'rgba(0, 0, 0, 0.12)'
    // defaultBorderColor: 'rgba(0, 0, 0, 0.87)'
    highlightBackgroundColor: indigo[50]
  }
})

/* --------- Typography ---------- */
theme.typography.h1 = {
  fontSize: '3rem',
  fontWeight: 400,
  [theme.breakpoints.up('sm')]: {
    fontSize: '4rem'
  }
}

theme.typography.h2 = {
  fontSize: '1.6rem',
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.0rem'
  }
}

theme.typography.h3 = {
  fontSize: '1.6rem',
  fontWeight: 400,
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.0rem'
  }
}

theme.typography.h4 = {
  fontSize: '1.4rem',
  fontWeight: 400,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.6rem'
  }
}

theme.typography.h5 = {
  fontSize: '1.2rem',
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem'
  }
}

theme.typography.h6 = {
  fontSize: '1rem',
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem'
  }
}

/* ------ CSS Overrides & Global CSS ------ */
theme.overrides = {
  MuiCssBaseline: {
    '@global': {
      code: {
        color: theme.palette.secondary.dark
      }
    }
  },
  MuiButton: {
    outlined: {
      padding: '5px 10px',
      [theme.breakpoints.up('sm')]: {
        padding: '5px 15px'
      }
    }
  },
  MuiTypography: {
    gutterBottom: {
      marginBottom: '0.5em'
    }
  },
  MuiInputLabel: {
    root: {
      fontSize: '1rem',
      fontWeight: 700,
      color: theme.palette.common.black
    }
  },
  MuiOutlinedInput: {
    root: {
      backgroundColor: theme.palette.common.white
    }
  }
}

export default theme
