import axios, { AxiosRequestConfig } from 'axios'

axios.defaults.timeout = 4000
axios.defaults.baseURL = process.env.REACT_APP_API_URL

export default class Api {
  public static async get(uri: string, otherProps?: AxiosRequestConfig) {
    try {
      const res = await axios.get(uri, {
        withCredentials: true,
        ...otherProps
      })
      return res
    } catch (error: any) {
      console.error(error.response?.data?.message || error.message || error)
      throw error
    }
  }

  public static async postJson(uri: string, data: unknown, otherProps?: AxiosRequestConfig) {
    try {
      const res = await axios.post(uri, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
        ...otherProps
      })
      return res
    } catch (error: any) {
      console.error(error.response?.data?.message || error.message || error)
      throw error
    }
  }

  public static async postMultipart(uri: string, data: FormData, otherProps?: AxiosRequestConfig) {
    try {
      const res = await axios.post(uri, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
        ...otherProps
      })
      return res
    } catch (error: any) {
      console.error(error.response?.data?.message || error.message || error)
      throw error
    }
  }

  public static async putJson(uri: string, data: unknown, otherProps?: AxiosRequestConfig) {
    try {
      const res = await axios.put(uri, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
        ...otherProps
      })
      return res
    } catch (error: any) {
      console.error(error.response?.data?.message || error.message || error)
      throw error
    }
  }

  public static async delete(uri: string, otherProps?: AxiosRequestConfig) {
    try {
      const res = await axios.delete(uri, {
        withCredentials: true,
        ...otherProps
      })
      return res
    } catch (error: any) {
      console.error(error.response?.data?.message || error.message || error)
      throw error
    }
  }
}
