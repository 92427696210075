import React, { ReactElement, ReactNode } from 'react'

import { createStyles, makeStyles, Theme, Paper } from '@material-ui/core'

interface ICodeBlockProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

const CodeBlock: React.FC<ICodeBlockProps> = ({ children }: ICodeBlockProps) => {
  const classes = useStyles()

  return <Paper className={classes.codeBlockCard}>{children}</Paper>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    codeBlockCard: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      backgroundColor: theme.custom.defaultBackgroundColor,
      overflowWrap: 'break-word'
    }
  })
)

export default CodeBlock
