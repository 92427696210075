import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import {
  IQuestion,
  ISubQuestion,
  TPresentation,
  TSubType
} from '../../../models/questionsInterfaces'
import SubQuestion from './SubQuestion'
import InputsContainer from '../InputsContainer'
import ButtonsRow from './ButtonsRow'
import MainQuestions from './MainQuestions'

/* ------ Types and Interfaces------ */
export interface IMultiSelectElementProps {
  questionData: IQuestion
  baseId: string
  maxNumSubQuestions: number
  updateData?: (id: string, field: string, value: any) => void
  setSettingError?: (id: string) => void
  clearSettingError?: (id: string) => void
  updateSubQuestion?: (
    questionId: string,
    subQuestionIndex: number,
    key: string,
    value: string | number | undefined
  ) => void
  onSubQuestionAdd?: (questionId: string) => void
  onSubQuestionDelete?: (questionId: string, subQuestionIndex: number) => void
}

/** observer-Component */
const MultiSelectElement: React.FC<IMultiSelectElementProps> = ({
  questionData,
  baseId,
  maxNumSubQuestions,
  updateData,
  updateSubQuestion,
  onSubQuestionAdd,
  onSubQuestionDelete
}: IMultiSelectElementProps) => {
  const moreSubQuestionsAllowed = questionData.subQuestionsArray
    ? questionData.subQuestionsArray?.length < maxNumSubQuestions
    : true

  /* ------ Updaters that are handed down to children------ */
  function handleQuestionChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (updateData) {
      updateData(questionData.id, 'question', event.target.value)
    }
  }

  function handleQuestionLongChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (updateData) {
      updateData(questionData.id, 'questionLong', event.target.value)
    }
  }

  function handleSelectSubTypeChange(event: React.MouseEvent<HTMLElement>, newSubType: TSubType) {
    if (updateData && newSubType) {
      updateData(questionData.id, 'selectSubType', newSubType)
    }
  }

  function handlePresentationChange(
    event: React.MouseEvent<HTMLElement>,
    newPresentation: TPresentation
  ) {
    if (updateData && newPresentation) {
      updateData(questionData.id, 'presentation', newPresentation)
    }
  }

  function handleSubQuestionChange(
    indexSubQuestion: number,
    key: string,
    value: string | number | undefined
  ) {
    if (updateSubQuestion) {
      updateSubQuestion(questionData.id, indexSubQuestion, key, value)
    }
  }

  function handleSubQuestionAdd() {
    if (moreSubQuestionsAllowed) {
      if (onSubQuestionAdd) {
        onSubQuestionAdd(questionData.id)

        if (updateData && questionData.subQuestionsArray) {
          updateData(questionData.id, 'maxSelect', questionData.subQuestionsArray.length)
        }
      }
    } else {
      console.error('Cannot add subquestion. No more subquestions allowed.')
    }
  }

  function handleSubQuestionDelete(subQuestionIndex: number) {
    if (onSubQuestionDelete) {
      onSubQuestionDelete(questionData.id, subQuestionIndex)
    }
  }

  return (
    <InputsContainer>
      <Grid container spacing={4}>
        <MainQuestions
          question={questionData}
          changeQuestion={handleQuestionChange}
          changeQuestionLong={handleQuestionLongChange}
          maxNumSubQuestions={maxNumSubQuestions}
          baseId={baseId}
        />

        <ButtonsRow
          question={questionData}
          changeSubType={handleSelectSubTypeChange}
          changePresentation={handlePresentationChange}
        />

        {questionData.subQuestionsArray?.map((subQuestion: ISubQuestion, index: number) => (
          <Grid item xs={12} key={`sq-${subQuestion.id || index + 1}`}>
            <Grid container spacing={1}>
              <SubQuestion
                parentQuestionIndex={questionData.order - 1}
                ansNumber={index + 1}
                baseId={baseId}
                subQuestion={subQuestion}
                changeCb={handleSubQuestionChange}
              />
              <Grid item xs={12} container justify='flex-start'>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={() => handleSubQuestionDelete(index)}>
                  Löschen
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
        {moreSubQuestionsAllowed && (
          <Grid item xs={12}>
            <Button
              variant='contained'
              color='primary'
              size='small'
              startIcon={<AddIcon />}
              onClick={handleSubQuestionAdd}>
              Antwort hinzufügen
            </Button>
          </Grid>
        )}
      </Grid>
    </InputsContainer>
  )
}

export default observer(MultiSelectElement)
